/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useEffect, useState } from 'react'
import {
  ContainerLoading,
  FiltersTicket,
  Pagination,
  ReturnBtn,
  TicketListContent
} from 'presentation/components'
import { useGetTicketDetails, useGetTickets } from 'presentation/hooks'
import { FiltersTicketType, IPartialTicket, IStatusColor } from 'domain/models'
import { GetTicket, GetTicketList } from 'domain/usecases'

import { WrapperContentPage, WrapperSectionContent } from 'presentation/styles/commonStyles'
import { ContainerTitle, WrapperHeaderTickets } from 'presentation/components/TicketList/styles'

type Props<T> = {
  getTicketList: GetTicketList
  statusColor: Array<IStatusColor<T>>
  getTicket: GetTicket
}

export function TicketList<T>({ getTicketList, statusColor, getTicket }: Props<T>) {
  const { ticketList, isLoadingTickets, getData, pagination } = useGetTickets({ getTicketList })
  const { isLoadingTicket, ticket, getTicketDetails } = useGetTicketDetails({ getTicket })
  const [filters, setFilters] = useState<Record<FiltersTicketType | 'id', string | null>>({
    status: null,
    id: null
  })

  const isLoading = isLoadingTickets || isLoadingTicket
  const list: IPartialTicket[] =
    !ticket && filters.id ? [] : ticket && filters.id ? [ticket] : ticketList

  useEffect(() => {
    getData({ page: 1 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapperContentPage>
      <ReturnBtn />
      <WrapperHeaderTickets>
        <ContainerTitle>
          {'CHAMADOS'}
          <span />
        </ContainerTitle>
      </WrapperHeaderTickets>
      {isLoading && (
        <div className="loading">
          <ContainerLoading />
        </div>
      )}

      {!isLoading && (
        <WrapperSectionContent>
          <FiltersTicket
            getTicketList={getData}
            setFilters={setFilters}
            filters={filters}
            getTicketDetails={getTicketDetails}
          />
          <TicketListContent<T> ticketList={list} statusColor={statusColor} />
          {list.length > 0 && (!filters.id || filters.status === '') && (
            <Pagination
              currentPage={pagination.page}
              onNextPage={() => {
                getData({ page: pagination.page + 1, filter: { status: filters.status ?? '' } })
              }}
              onPreviousPage={() => {
                getData({ page: pagination.page - 1, filter: { status: filters.status ?? '' } })
              }}
              hasNextPage={pagination.next}
              hasPreviousPage={pagination.prev}
            />
          )}
        </WrapperSectionContent>
      )}
    </WrapperContentPage>
  )
}
