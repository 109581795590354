import { AdmManagementContextProvider } from 'presentation/context'
import { AdminManagement } from 'presentation/pages'

const makeAdminManagement = () => {
  return (
    <AdmManagementContextProvider>
      <AdminManagement />
    </AdmManagementContextProvider>
  )
}

export default makeAdminManagement
