import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from 'presentation/styles/Breakpoints'
import { borderRadius, paddingX, paddingY } from 'presentation/styles/commonStyles'

// --------------------------------
// Shared styles
// --------------------------------
export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: fit-content;
  gap: 1.5rem;

  padding: ${paddingY} ${paddingX};
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${borderRadius};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.black};
  box-shadow: 0px 1px 4px ${({ theme }) => theme.colors.gray._800};
`

export const EmptyListMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 5rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray._600};
`

export const TitlePage = styled.label`
  font-size: 1.15rem;
  font-weight: bold;
  text-transform: uppercase;
`

// --------------------------------
// Admin Management styles
// --------------------------------
export const WrapperAdminList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .admin-list-table {
    width: 100%;
    border-collapse: collapse;
    text-transform: uppercase;

    thead {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray._700};
    }

    tbody {
      color: ${({ theme }) => theme.colors.gray._600};
    }
    tr {
      width: 100%;
      height: 3rem;
    }

    td,
    th {
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
      text-align: center;
    }

    td:first-child,
    th:first-child {
      width: 90%;
    }

    td:last-child,
    th:last-child {
      width: 10%;
      text-align: right;

      button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        margin-left: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }
      }

      button:first-child {
        &:hover {
          svg {
            color: ${({ theme }) => theme.colors.green._600};
          }
        }
      }
      button:last-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.red._700};
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(900, 560)} {
    .admin-list-table {
      td:first-child,
      th:first-child {
        width: 80%;
      }

      td:last-child,
      th:last-child {
        width: 20%;
      }
    }
  }

  ${breakQueriesMaxMin(560, SizeBreakpoint.xsm)} {
    .admin-list-table {
      td:first-child,
      th:first-child {
        width: 75%;
      }

      td:last-child,
      th:last-child {
        width: 25%;
      }
    }
  }
`

// --------------------------------
// News Admin styles
// --------------------------------
export const WrapperNewsList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  column-gap: 2.5rem;
  margin-top: 2rem;
  width: 100%;
  height: auto;

  .news-item {
    width: 20rem;
    max-width: 20rem;
    height: 18rem;
    max-height: 18rem;
    border: 2px solid ${({ theme }) => theme.colors.black};
    border-radius: 1rem;

    &:hover {
      cursor: grab;
    }
    .news-item__img {
      width: 100%;
      max-width: 100%;
      height: 9rem;
      border-radius: 1rem 1rem 0 0;
      border: none;
    }

    .text {
      padding: 0.5rem;
      text-align: justify;
      text-justify: inter-word;
    }

    .icons {
      position: relative;

      button {
        position: absolute;
        right: 0;
        border: none;
        border-radius: 0.25rem;
        background-color: ${({ theme }) => theme.colors.gray._100};
        cursor: pointer;
        margin-top: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }

        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.red._800};
          }
        }
      }

      button:first-child {
        margin-right: calc(0.5rem + 1.35rem + 0.5rem);
        &:hover {
          svg {
            color: ${({ theme }) => theme.colors.green._600};
          }
        }
      }

      button:last-child {
        margin-right: 0.75rem;
        &:hover {
          svg {
            color: ${({ theme }) => theme.colors.red._700};
          }
        }
      }
    }
  }
`

// --------------------------------
// Faq Admin styles
// --------------------------------
export const WrapperFaqList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .list-table {
    width: 100%;
    border-collapse: collapse;
    /* text-transform: uppercase; */

    thead {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray._700};
      text-transform: uppercase;
    }

    tbody {
      color: ${({ theme }) => theme.colors.gray._600};
    }
    tr {
      width: 100%;
      height: 3rem;
      min-height: 3rem;
      min-height: 3rem;
    }

    td,
    th {
      border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
    }

    td:first-child,
    th:first-child {
      width: 50% !important;
    }
    td:nth-child(2),
    th:nth-child(2) {
      width: 30%;
      padding-left: 1rem;
      text-align: left;
    }

    td:last-child,
    th:last-child {
      width: 20%;
      text-align: center;
    }

    td:first-child {
      padding-left: 1rem;
      text-transform: lowercase;
      padding: 0.5rem 0;
      &:first-letter {
        text-transform: uppercase;
      }
    }

    td:nth-child(2) {
      text-transform: lowercase;
      .row-form-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis ellipsis !important;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }

    td:last-child {
      button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        margin-left: 0.5rem;

        svg {
          width: 1.25rem;
          height: 1.25rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }
      }

      button:first-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.green._600};
          }
        }
      }
      button:last-child {
        &:hover {
          svg {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.red._700};
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(1200, 1024)} {
    td:nth-child(2) {
      width: 300px;
      max-width: 300px !important;
    }
  }

  ${breakQueriesMaxMin(1024, 900)} {
    td:nth-child(2) {
      width: 250px;
      max-width: 250px !important;
    }
  }

  ${breakQueriesMaxMin(900, 780)} {
    td:nth-child(2) {
      width: 200px;
      max-width: 200px !important;
    }
  }

  ${breakQueriesMaxMin(780, SizeBreakpoint.xsm)} {
    td:nth-child(2) {
      width: 150px;
      max-width: 150px !important;
    }
  }

  @media (max-width: 560px) {
    td:last-child {
      button {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }
`

// --------------------------------
// Card Admin styles
// --------------------------------
export const WrapperCardList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }
`

// --------------------------------
// Form Admin styles
// --------------------------------
export const WrapperFormList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;

  label {
    font-size: 0.875rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    box-sizing: border-box;

    thead {
      font-weight: bold;
      color: ${({ theme }) => theme.colors.gray._700};
      text-transform: uppercase;

      tr {
        width: 100%;
        height: 4rem;
      }

      th {
        text-align: center;
        border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
        height: 4rem;
        max-height: 4rem;
        min-height: 4rem;

        @media (max-width: 640px) {
          font-size: 0.875rem;
        }
      }
      .column-title {
        text-align: left;
      }
      .column-cardName {
        text-align: left;
        padding: 0 1rem;
        @media (max-width: 640px) {
          padding: 0 0.5rem;
        }
      }
    }

    tbody {
      color: ${({ theme }) => theme.colors.gray._600};

      tr {
        width: 100%;
        height: 5rem;

        td {
          border-bottom: 2px solid ${({ theme }) => theme.colors.gray._200};
          height: 100%;
          min-height: 5rem;
        }

        .column-color {
          justify-content: left;
          margin-right: 1rem;
          width: 3%;
          height: 4rem;

          @media (max-width: 640px) {
            width: 4%;
          }
        }

        .column-title {
          text-align: left;
          font-size: 0.95rem;
          font-weight: bold;
          line-height: 1.25rem;
          color: ${({ theme }) => theme.colors.black};
        }

        .column-cardName {
          /* text-align: center; */
          font-size: 0.875rem;
          width: 35%;
          padding: 0.5rem 1rem;
          text-transform: lowercase;

          p {
            &:first-letter {
              text-transform: uppercase;
            }
          }

          p::after {
            content: ' > ';
          }

          p:last-child {
            font-weight: bold;
            color: ${({ theme }) => theme.colors.black};
            text-transform: uppercase;

            &::after {
              content: '';
            }
          }

          @media (max-width: 640px) {
            padding: 0.5rem 0.5rem;
          }
        }

        .column-action {
          text-align: center;
          width: 10%;
          button {
            border: none;
            background-color: ${({ theme }) => theme.colors.white};
            margin-left: 0.75rem;
            cursor: pointer;

            svg {
              width: 1.25rem;
              height: 1.25rem;
              color: ${({ theme }) => theme.colors.gray._600};
            }
          }

          .action-edit {
            &:hover {
              svg {
                color: ${({ theme }) => theme.colors.green._600};
              }
            }
          }

          .action-remove {
            &:hover {
              svg {
                color: ${({ theme }) => theme.colors.red._700};
              }
            }
          }

          @media (max-width: 930px) {
            width: 12%;
            button {
              margin-top: 0.25rem;
              margin-bottom: 0.25rem;
            }
          }
        }

        .column-active {
          text-align: center;
          width: 7%;
          input {
            height: 1rem;
            width: 1rem;
            max-width: 1rem;
            min-width: 1rem;
          }
        }
      }
    }
  }
`

export const WrapperLoadingModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  p {
    margin-top: 1rem;
  }
`
