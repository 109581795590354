import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  UnexpectedError
} from 'domain/errors'
import { CustomerPostTicketModel, CustomerPostTicketModelResponse } from 'domain/models'
import { CustomerPostTicket } from 'domain/usecases'

export class RemoteCustomerPostTicket implements CustomerPostTicket {
  constructor (
    private readonly httpClient: HttpClient<CustomerPostTicketModelResponse>,
    private readonly url: string
  ) {}

  async create (dto: CustomerPostTicketModel): Promise<CustomerPostTicketModelResponse> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'POST',
      body: dto
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao abrir o chamado')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError(
          'Seu usuário ainda não foi replicado no sistema de chamado, por favor aguarde'
        )
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao abrir o chamado')
    }
  }
}
