import { AccessDeniedError, InvalidCredentialsError } from 'domain/errors'
import { toast } from 'react-toastify'
import { useLogout } from './useLogout'

export const useHandleError = () => {
  const { logout } = useLogout()
  const handleError = (error: any) => {
    toast.error(error?.message)
    if (error instanceof InvalidCredentialsError || error instanceof AccessDeniedError) {
      setTimeout(() => {
        logout()
      }, 2000)
    }
  }
  return { handleError }
}
