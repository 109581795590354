import OurPurpose from '../../assets/proposito 02.png'
import QRCode from '../../assets/qrCodeSeloGptw.png'
import SeloGPTW from '../../assets/seloGptw.png'

import { ContainerFooter } from './styles'

const Footer = () => {
  return (
    <ContainerFooter>
      <div className="responsive_container">
        <div className="info">
          <div className='puporse'>
              <img alt='puporse-img' src={OurPurpose} />
          </div>
          <div className="contacts-qrcode">
              <p>
                Saiba mais sobre o GPTW e a <br/>Certificação pelo Qr-code!
              </p>
              <div className='contacts-qrcode-images'>
                <img className="qrcode-gptw" src={QRCode} alt="qrcode" />
                <img className="selo-gptw" src={SeloGPTW} alt="selo_GPTW" />
              </div>
            </div>
          {/* <div className="info-text">
            <span />
            <Link to="https://www.mpba.mp.br/" target="_blank">
              Sobre o MPBA
            </Link>
            <Link to="https://privacidade.mpba.mp.br/" target="_blank">
              Políticas de Privacidade
            </Link>

          </div>
          <div className="info-icons">
            <Link
              to="https://www.facebook.com/share/qgRMgSdqgaqrXGZW/?mibextid=qi2Omg"
              target="_blank"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </Link>
            <Link to="https://www.instagram.com/mpdabahia?igsh=cG1yaDJ6emI5OG03" target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </Link>
            <Link to="https://youtube.com/@mpdabahia?si=SmsitgE_ORRINrV3" target="_blank">
              <FontAwesomeIcon icon={faYoutube} />
            </Link>
            <Link
              to="https://www.linkedin.com/company/ministerio-publico-do-estado-da-bahia/"
              target="_blank"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </Link>
          </div>
          <div className="info-phone">
            <div>
              <p>
                Se estiver precisando de outra ajuda ou outras informações, entre em contato: <br />
                <FontAwesomeIcon icon={faPhoneVolume} /> <span>(71) 3103-0640</span>
              </p>
            </div>
          </div> */}
        </div>
        <div className="description">
          GNC - Grupo New Chase - 2024. Todos os direitos reservados. Versão 1.0
        </div>
      </div>
    </ContainerFooter>
  )
}

export default Footer
