import styled from 'styled-components'

export const customSelectStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxSizing: 'border-box',
    height: 'fit-content',
    minHeight: '2.3rem',
    width: '100%',
    minWidth: 'fit-content',
    padding: '0 0.5rem',
    border: state.isFocused ? '1px solid #707070' : '1px solid #d2d2d2',
    boxShadow: state.isFocused ? 0 : 0,
    borderRadius: '0.5rem',
    backgroundColor: state.isFocused ? '#f1f1f1' : '#fff',
    color: '#707070',
    borderColor: state.isSelected && 'red'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    minHeight: '2.3rem'
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: 0,
    height: '2.3rem',
    minHeight: '2.3rem'
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: '0',
    height: '2.3rem',
    minHeight: '2.3rem'
  }),

  indicatorSeparator: () => ({
    display: 'none',
    minHeight: '2.3rem'
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0'
  }),

  menu: (provided: any) => ({
    ...provided,
    color: '#707070',
    width: '100%'
  }),
  //* *********************************** */
  multiValue: (styles: any) => {
    return {
      ...styles,
      backgroundColor: '#69c9fc',
      borderRadius: '0.625rem',
      padding: '0 0.25rem'
    }
  },
  multiValueLabel: (styles: any) => ({
    ...styles,
    color: '#232323' // 'white'
  }),
  multiValueRemove: (styles: any) => ({
    ...styles,
    color: '#232323', // 'white',
    ':hover': {
      color: 'white'
    }
  })
  //* *********************************** */
}

export const ErrorMessage = styled.span`
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size: 0.85rem;
  font-weight: 500;
`

export const WrapperFields = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: 1.55rem;
`

export const WrapperAdmFieldList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
`

export const WrapperAdmField = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  width: 100%;
`

export const AddBtnAdmField = styled.div`
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 0.5rem;

  button {
    padding: 0.65rem;
    color: ${({ theme }) => theme.colors.gray._500};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.gray._400};
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.blue._600};

    svg {
      width: 1.25rem;
      height: 1.25rem;
    }

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.blue._600};
      border: 1px solid ${({ theme }) => theme.colors.blue._600};
    }
  }

  p {
    font-weight: bold;
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.blue._600};
  }
`

export const RemoveBtnAdmField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-radius: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.gray._300};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._800};

  button {
    background-color: transparent;
    border: none;
    padding: 0.35rem;
    color: ${({ theme }) => theme.colors.gray._500};
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.gray._500};

    svg {
      width: 1.15rem;
      height: 1.15rem;
    }

    &:hover {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.red._700};
      border: 1px solid ${({ theme }) => theme.colors.red._700};
    }
  }
`

export const ContainerField = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;
  height: fit-content;
  min-height: 10rem;
  font-size: 1rem;

  border-radius: 0.5rem;
  border: 2px solid ${({ theme }) => theme.colors.gray._300};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._800};
  color: ${({ theme }) => theme.colors.gray._500};

  padding: 0.75rem 1.5rem 1rem;
  margin-right: 1rem;

  &:focus-within,
  &::-moz-focus-inner {
    border-left: 6px solid ${({ theme }) => theme.colors.blue._400};
  }

  .btn-move {
    text-align: center;
    width: 100%;
    height: fit-content;

    button {
      background-color: transparent;
      border: none;
      svg {
        width: 1.5rem;
        height: 1rem;
        color: ${({ theme }) => theme.colors.gray._500};
      }

      &:hover {
        cursor: move;
        svg {
          color: ${({ theme }) => theme.colors.gray._700};
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    width: 100%;
    gap: 1rem;
    margin-top: auto;
    padding-top: 1rem;

    .section-checkbox {
      display: flex;
      /* align-items: start; */
      gap: 0.5rem;
      input {
        height: 1.15rem;
        width: 1.15rem;
        max-width: 1.15rem;
        min-width: 1.15rem;
      }
    }
    .section-switch {
      display: flex;
      align-items: start;
      gap: 0.5rem;
    }

    .section-number {
      display: flex;
      /* align-items: start; */
      gap: 0.5rem;
      input {
        height: 1.5rem;
        width: 4rem;
        padding: 0 0.5rem;
        max-width: 4rem;
        min-width: 4rem;
      }
    }

    @media (max-width: 640px) {
      align-items: flex-start;
    }
    @media (max-width: 580px) {
      display: flex;
      justify-content: flex-start;
    }
  }

  .header {
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    padding: 0.5rem 0;

    .section-question {
      width: 70%;
      input {
        font-size: 1.25rem;
        width: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray._400};

        &::placeholder {
          font-size: 1rem;
          color: ${({ theme }) => theme.colors.gray._600};
        }
      }
    }

    .section-type {
      width: 30%;
    }

    @media (max-width: 640px) {
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 1rem;
      margin-top: 1rem;

      .section-question {
        width: 100%;
      }

      .section-type {
        width: 60%;
      }
    }
  }

  .content {
    width: 100%;
    padding: 1.25rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray._200};

    .adm-file-field,
    .adm-text-field {
      display: flex;
      flex-direction: column;
      label {
        margin-bottom: 0.5rem;
      }
    }

    .adm-text-field {
      input {
        width: 14rem;
      }
    }
  }
`

export const WrapperSelectField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
  gap: 0.5rem;

  .btn-add-option {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.gray._400};
    margin-top: 1rem;
    // MoveIcon 1rem, margins 0.1rem and 0.25rem
    margin-left: 1.3rem;
    p {
      text-align: left;
      font-style: italic;
      font-size: 1rem;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .section-option {
    display: flex;
    align-items: center !important;
    justify-content: space-between;
    width: 100%;

    .values-opt {
      display: flex;
      align-items: center;

      input {
        border: none;
        padding: 0 0.5rem;
        border-radius: 0;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray._400};
        min-width: 10rem;
        width: 25rem;
        font-size: 1rem;
        margin-left: 0.1rem;
      }
    }

    .btn-remove-opt {
      background-color: transparent;
      border: none;
      padding: 0.5rem;
      border-radius: 50%;

      svg {
        width: 1rem;
        height: 1rem;
      }

      &:hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.colors.gray._100};
        transition: background-color 0.2s;
      }
    }

    .btn-move-opt {
      background-color: transparent;
      border: none;
      margin-right: 0.25rem;
      svg {
        width: 1rem;
        height: 1rem;
        color: ${({ theme }) => theme.colors.gray._400};
      }

      &:hover {
        cursor: move;
        svg {
          color: ${({ theme }) => theme.colors.gray._500};
        }
      }
    }
  }
`
