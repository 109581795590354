/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { FormProvider, useForm } from 'react-hook-form'
import { InputsToRender } from './InputsToRender'
import { useHandleError, useSetExtraQuestions } from 'presentation/hooks'
import { Button } from '..'
import {
  formatDescriptionField,
  getCategoryInfoFromSelectedOption,
  getExtraQuestions,
  getUserField,
  setFileField,
  updateUserAnswer,
  setPropertyId
} from './utils'
import { AttributeModel, FormI, IAttachments, IPostTicket } from 'domain/models'
import { getVipUserFlagAdapter } from 'main/adapters'
import { DeletePrivateFileProps } from 'domain/usecases'
import { useTicketFormContext } from 'presentation/context'

import { Form } from './styles'

type Props = {
  form: FormI
}

const FormGeneric = ({ form }: Props) => {
  const methods = useForm()
  const { saveFile, postTicket, deletePrivateFile, saveAllPrivateFile } = useTicketFormContext()
  const navigate = useNavigate()
  const { handleError } = useHandleError()
  const isVip = getVipUserFlagAdapter() === 'true'

  const [isLoading, setIsLoading] = useState(false)
  const [userName, setUserName] = useState('')
  const [extraQuestions, setExtraQuestions] = useState<AttributeModel[] | null>(null)

  const { optionListSelected, handleCheckboxExtraQuestions, handleSelectExtraQuestions } =
    useSetExtraQuestions({ methods })
  const [equipments, setEquipment] = useState<Record<string, number>>({})

  const onSubmitForm = async (data: Record<string, string | File>) => {
    setIsLoading(true)
    let attachments: IAttachments[] = []
    try {
      attachments = await setFileField(data, saveFile, saveAllPrivateFile)
      const categoryData = getCategoryInfoFromSelectedOption(optionListSelected)
      const { question, answer } = getUserField(data, form)
      const newDataForDescription = updateUserAnswer(data, question, userName)

      const description = formatDescriptionField({
        data: newDataForDescription,
        attributes: form.attributes
      })

      const propertiesID = setPropertyId({
        data: newDataForDescription,
        attributes: form.attributes,
        extraQuestions
      })

      const type = (categoryData?.type ?? form.category?.type) as boolean
      const dto: IPostTicket = {
        userLogin: answer,
        status: (categoryData?.status ?? form.category?.status) as string,
        category: (categoryData?.category_id ?? form.category?.category_id) as string,
        group: (categoryData?.group ?? form.category?.group) as string,
        description,
        summary: categoryData?.title ?? form.title,
        type,
        attachments,
        properties: { ...propertiesID, ...equipments },
        entityId: (categoryData?.entityId ?? form.category?.entityId)
      }

      const { ticketId } = await postTicket.create(dto)

      navigate(`/ticket/success/${ticketId}`, {
        state: { user: dto.userLogin }
      })
    } catch (error: any) {
      if (attachments && attachments.length > 0) {
        const urlList: DeletePrivateFileProps[] = []
        attachments?.forEach((el) => urlList.push({ name: el.url }))
        await deletePrivateFile.delete(urlList)
      }
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (form?.attributes?.length > 0) {
      const sortAttributeForm = form.attributes.sort(
        (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
      )
      sortAttributeForm.forEach((attribute) => {
        if (!(attribute.isHiddenToVip && isVip)) {
          methods.unregister(attribute.name)
        }
      })
      setExtraQuestions(getExtraQuestions(form.attributes))
    }
  }, [form])

  const handleEquipmentSelection = (list: Record<string, number>) => {
    setEquipment(list)
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmitForm)} $color={form.color}>
        <h2>Preencha os campos abaixo:</h2>

        <div className="form-inputs">
          <InputsToRender
            attributes={form.attributes}
            isVip={isVip}
            setUserName={setUserName}
            handleCheckboxExtraQuestions={handleCheckboxExtraQuestions}
            handleSelectExtraQuestions={handleSelectExtraQuestions}
            optionListSelected={optionListSelected}
            handleEquipmentSelection={handleEquipmentSelection}
          />
        </div>

        <div className="form-button">
          <Button type="submit" isLoading={isLoading} text="Enviar" />
        </div>
      </Form>
    </FormProvider>
  )
}

export default FormGeneric
