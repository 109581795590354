import { useEffect, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import {
  AddAdminModal,
  AddButton,
  DeleteAdminModal,
  EditAdminModal,
  Spinner
} from 'presentation/components'
import { UserAdminModel } from 'domain/models'
import { useAdmManagementContext } from 'presentation/context'
import { useGetAdminList } from 'presentation/hooks'

import { EmptyListMessage, TitlePage, WrapperAdminList, WrapperContent } from '../styles'
import { ModalWrapper } from 'presentation/components/Modal/styles'

export const AdminManagement = () => {
  const { getAdminList } = useAdmManagementContext()
  const { adminList, isLoadingAdminList, getAdminData } = useGetAdminList({ getAdminList })

  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [adminSelected, setAdminSelected] = useState<UserAdminModel | null>(null)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
    }
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenEditModal && !isOpenDeleteModal) {
      getAdminData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <WrapperContent>
        <TitlePage>Administrador</TitlePage>
        <AddButton text="Adicionar novo administrador" onClick={toggleAddModal} />
        <WrapperAdminList>
          <label>Administradores:</label>

          {isLoadingAdminList && <Spinner />}
          {!isLoadingAdminList && adminList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhum administrador encontrado!</p>
            </EmptyListMessage>
          )}
          {!isLoadingAdminList && adminList.length > 0 && (
            <table className="admin-list-table">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {adminList.map((admin) => (
                  <tr key={admin.id}>
                    <td>{admin.name}</td>
                    <td>
                      <button
                        onClick={() => {
                          setAdminSelected(admin)
                          toggleEditModal()
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        onClick={() => {
                          setAdminSelected(admin)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperAdminList>
      </WrapperContent>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && (
          <AddAdminModal
            toggleModal={toggleAddModal}
            updateData={() => {
              getAdminData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && adminSelected && (
          <EditAdminModal
            toggleModal={toggleEditModal}
            adminSelected={adminSelected}
            updateData={() => {
              getAdminData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && adminSelected && (
          <DeleteAdminModal
            toggleModal={toggleDeleteModal}
            adminSelected={adminSelected}
            getAdmins={() => {
              getAdminData()
            }}
          />
        )}
      </ModalWrapper>
    </>
  )
}
