import { useState } from 'react'
import { FormModel } from 'domain/models'
import { GetFormList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getFormList: GetFormList
}

export const useGetFormList = ({ getFormList }: Props) => {
  const [formList, setFormList] = useState<FormModel[]>([])
  const [isLoadingFormList, setIsLoadingFormList] = useState(false)
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const { handleError } = useHandleError()

  const getFormListData = async (page = pagination.page) => {
    try {
      setIsLoadingFormList(true)
      const response = await getFormList.get({ page, limit: 5, order: 'DESC' })
      setFormList(response.data)
      setPagination({
        page,
        next: response.paginate.next,
        prev: response.paginate.prev
      })
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoadingFormList(false)
    }
  }
  return {
    getFormListData,
    pagination,
    isLoadingFormList,
    formList
  }
}
