import { IPartialTicket, ITicketListWithPagination } from 'domain/models'
import {
  GetTicketList,
  GetTicketsDto,
  CustomerGetTicketListWithoutPagination
} from 'domain/usecases'

export class CustomerGetTicketListAdapterWithoutPagination implements GetTicketList {
  constructor (private readonly customerGetTicketList: CustomerGetTicketListWithoutPagination) {}

  async get (dto: GetTicketsDto): Promise<ITicketListWithPagination> {
    const response = await this.customerGetTicketList.get(dto)

    if (response) {
      const newList: IPartialTicket[] = []

      if (response.tickets && response.tickets.length > 0) {
        response.tickets.forEach((ticket) =>
          newList.push({
            id: ticket.ticket_id,
            status: ticket.ticket_status,
            title: ticket.ticket_title,
            createdAt: ticket.ticket_date,
            category: ticket.ticket_category
          })
        )
      }

      return {
        tickets: newList,
        pagination: {
          prev: response?.pagination?.prev ?? false,
          next: response?.pagination?.next ?? false,
          count: response?.pagination?.count ?? 0
        }
      }
    }
    return response
  }
}
