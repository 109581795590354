import { CustomerStatusColorEnum, CustomerStatusType } from 'domain/models'
import { statusColorAdapter } from 'main/adapters'
import { makeGetTicket, makeGetTicketListWithoutPagination } from 'main/factories/usecases'
import { TicketList } from 'presentation/pages'

const makeTicketList = () => {
  return (
    <TicketList<CustomerStatusType>
      getTicketList={makeGetTicketListWithoutPagination()}
      // getTicketList={makeGetTicketList()}
      statusColor={statusColorAdapter<CustomerStatusType>(CustomerStatusColorEnum)}
      getTicket={makeGetTicket()}
    />
  )
}

export default makeTicketList
