export const validations = {
  pattern: {
    value: /^^.{1,200}$/,
    message: '* Máximo 180 caracteres'
  },
  required: {
    value: true,
    message: '* Campo obrigatório'
  }
}

export const reactQuillContainerParams = [[{ header: [false] }], ['bold', 'italic', 'underline']]
