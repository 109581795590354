import axios from 'axios'
import { HttpClientAll } from 'data/protocols'
import { DeletePublicFile } from 'domain/usecases'

export class AxiosAllDeletePublicFiles implements HttpClientAll {
  constructor (private readonly deleteFile: DeletePublicFile) {}
  async all (urls: string[]): Promise<any> {
    if (urls.length > 0) {
      return axios.all(
        urls.map(async (url: string) => {
          await this.deleteFile.delete(url)
        })
      )
    }
  }
}
