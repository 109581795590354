import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from 'presentation/styles/Breakpoints'
import BackgroundGnc from '../../assets/Background_GNC.png'
import { btnSize } from 'presentation/components/Home/styles'

export const maxWidthInBigScreen = {
  lg: '80%',
  xl: '70%'
} // apply for minWidth 2560px

const heightHeader = '7rem'
const heightFooter = {
  lg: '9rem',
  xl: '12rem',
  xxl: '15rem'
}

//* ************************* */
// Styles: Page component
//* ************************* */
export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray._150};
  overflow-x: hidden !important;
`

//* ************************* */
// Styles: Header component
//* ************************* */
export const ContainerHeader = styled.header<{ $isPhoto: boolean }>`
  top: 0;
  left: 0;
  right: 0;
  height: ${heightHeader};
  min-height: ${heightHeader};
  max-height: ${heightHeader};
  width: 100%;
  background-image: url(${BackgroundGnc});
  /* background-color: ${({ theme }) => theme.colors.white}; */
  color: ${({ theme }) => theme.colors.white};
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray._800};
  box-shadow: 0px 3px 3px ${({ theme }) => theme.colors.gray._900};

  .responsive_container {
    width: 100%;
    height: 100%;
    padding: 1rem ${btnSize.lg};

    display: grid;
    grid-template-columns: repeat(3, 1fr);

    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
    }
  }

  .logo {
    display: flex;
    align-content: center;
    align-items: center;
    gap: 1rem;

    img {
      width: 12.8rem;
      height: 4.8rem;
    }
    p {
      text-transform: uppercase;
      font-size: 0.875rem;
      // opacity: 0.7;
      color: ${({ theme }) => theme.colors.white};
      font-weight: bold;
      margin-top: 0rem;
    }
  }

  .label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.25rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.white};
  }

  .user_photo {
    width: 4.5rem;
    height: 4.5rem;
    border: 2px solid ${({ theme }) => theme.colors.gray._400};
    border-radius: 50%;
    background-color: ${(props) =>
    props.$isPhoto ? props.theme.colors.gray._100 : props.theme.colors.gray._150};

    img {
      width: 85%;
      height: 85%;
      background-color: ${({ theme }) => theme.colors.white};
    }
    svg {
      font-size: 1.75rem;
      color: ${({ theme }) => theme.colors.gray._300};
    }
  }

  .utilities {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    /* float: right; */
    gap: 1.5rem;

    .img-notify {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }

    .notification {
      width: 2.5rem !important;
      height: 2.5rem !important;
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.gray._400};
      cursor: pointer !important;
      transition: all 0.2s;
      &:hover {
        opacity: 0.8;
        svg {
          width: 1.65rem;
          height: 1.65rem;
        }
      }

      svg {
        width: 1.35rem;
        height: 1.35rem;
        color: ${({ theme }) => theme.colors.black};
        cursor: pointer;
      }

      span {
        width: 0.875rem;
        height: 0.875rem;
        margin-top: -0.75rem;
        margin-left: 1rem;
        background-color: ${({ theme }) => theme.colors.yellow._100};
      }
    }
  }

  @media (min-width: 2048px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.lg};
    }
  }

  @media (min-width: 2560px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }
`

//* ************************* */
// Styles: Page component (Body)
//* ************************* */
export const WrapperContent = styled.div`
  width: 100%;
  flex: 1 0 auto;

  .responsive_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 100%;
    height: 100%;
    min-height: calc(100vh - ${heightHeader} - ${heightFooter.lg});
    margin: 0 auto;

    @media (min-width: 1440px) {
      min-height: calc(100vh - ${heightHeader} - ${heightFooter.lg});
    }
  }

  .wrapper-content {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 3rem;
    width: 100%;
  }

  @media (min-width: 2048px) {
    .responsive_container {
      width: ${maxWidthInBigScreen.lg};
    }
  }

  @media (min-width: 2560px) {
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }
`

export const Sidebar = styled.nav`
  float: left;
  display: flex;
  flex-direction: column;
  width: 9rem;
  min-width: 9rem;
  max-width: 9rem;
  padding: 1.25rem 0rem;
  background-color: ${({ theme }) => theme.colors.gray._100};
  font-size: 0.75rem;
  box-shadow: 0px 10px 20px ${({ theme }) => theme.colors.gray._900};

  a {
    text-decoration: none;
    text-transform: uppercase;
    padding: 0.875rem 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};

    svg {
      width: 1.65rem;
      height: 1.65rem;
    }

    .link-label {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 0.75rem;
    }

    &:hover {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.gray._600};
    }
  }

  a.active {
    background-color: ${({ theme }) => theme.colors.gray._300};
    border-left: 6px solid ${({ theme }) => theme.colors.black};
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.xsm)} {
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
  }

  @media (min-width: 2048px) {
    background-color: transparent;
    box-shadow: 0px 6px 10px transparent;

    a.active {
      background-color: ${({ theme }) => theme.colors.gray._150};
      border: none;
    }

    a.link-bottom {
      margin-top: 0;
    }
  }
`

//* ************************* */
// Styles: Page component
//* ************************* */
export const ContainerFooter = styled.footer`
  width: 100%;
  flex-shrink: 0;
  height:  ${heightFooter.lg};
  margin-top: 0 auto;
  background: ${({ theme }) => theme.colors.black} 0% 0% no-repeat padding-box;
  box-shadow: 3px 3px 20px ${({ theme }) => theme.colors.gray._900};
  color: ${({ theme }) => theme.colors.white};

  .responsive_container {
    padding: 0rem 3rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black} !important;
    @media (max-width: 900px) {
      padding: 0rem 2rem;
    }
  }

  .info {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.6rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray._300};
    gap: 1rem;

    .puporse {
      display: flex;

      img {
        width: 42rem;
      }

      @media (max-width: 600px) {
        img{
          width: 32rem;
          }
      }

      @media (min-width: 601px) and (max-width: 768px){
          img{
            width: 38rem;
          }
      }
    }


    .contacts-qrcode {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      // margin-left: 8rem;

      .qrcode-gptw{
        width: 5rem;
      }
      .selo-gptw{
        width: 3.3rem;
      }

      p{
        font-size: 0.8rem;
      }

      .contacts-qrcode-images{
        display: flex;
        gap: 1rem;
      }
    }

    .info-text {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      line-height: 1.5rem;
      text-align: left;
      span {
        width: 2rem;
        height: 4px;
        background-color: ${({ theme }) => theme.colors.white};
        margin-bottom: 0.5rem;
      }

      a {
        color: ${({ theme }) => theme.colors.white};
        text-decoration: none;
        font-size: 0.9rem;
      }

      a:hover {
          text-decoration: underline;
        }

      @media (max-width: 900px) {
        width: fit-content;

        a {
          font-size: 0.9rem;
        }
      }
    }

    .info-icons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      gap: 1rem;

      svg {
        padding: 0.5rem;
        width: 1.25rem;
        height: 1.25rem;
        background-color: ${({ theme }) => theme.colors.gray._200};
        color: ${({ theme }) => theme.colors.red._400};
      }
      @media (max-width: 900px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 0.5rem;
        column-gap: 0.5rem;
        width: fit-content;
        height: fit-content;
        margin-left: 3rem;
      }
    }

    .info-phone {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 0.85rem;
      width: 100%;
      // margin-left: 20rem;
      div {
        width:60%;
        @media (max-width: 900px) {
          width: 100%;
        }
      }

      span {
        font-weight: bold;
      }

      .phones {
        display: flex;
        align-items: flex-start;
        font-weight: bold;
        text-align: left;
        color: ${({ theme }) => theme.colors.white};
        gap: 1rem;

        svg {
          width: 1rem;
          height: 1rem;
          margin-right: 0.5rem;
        }

        a {
          display: flex;
          color: ${({ theme }) => theme.colors.white};
          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }
    }
  }

  .description {
    font-size: 0.8rem;
    color: ${({ theme }) => theme.colors.white};
    padding-top: 0.4rem;
    padding-bottom: 0.8rem;
    text-align: center;
  }

  @media (min-width: 420px) and (max-width: 600px) {
    height:  ${heightFooter.xxl};
    .puporse-img{
      img{

        width: 10rem;
      }
      }
    }
    @media (min-width: 0px) and (max-width: 420px) {
    height:  ${heightFooter.xxl};
    }

    @media (max-width: 980px) {
      height:  ${heightFooter.xxl};
      .info{
        flex-direction: column-reverse;
        justify-content: center;
        gap: 0.5rem;
        padding-top: 1rem;
      }

    }

  @media (min-width: 2048px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }

  @media (min-width: 2560px) {
    display: flex;
    justify-content: center;
    .responsive_container {
      width: ${maxWidthInBigScreen.xl};
    }
  }
`
