
import { AttributeModel, OptionModel } from 'domain/models'
import { FormInputDownload } from './styles'

type Props = {
  attribute: AttributeModel
}

const DownloadField = ({ attribute }: Props) => {
  return (
    <div>
      <FormInputDownload>
        <div className="wrapper-checkbox">
          <label htmlFor={attribute.name}>{attribute.question}</label>
          <div className="download-list">
            {attribute.optionsSelectElement.map((option: OptionModel) => (
              <div key={option.id}>
                <div className="link">
                  <a target="_blank" href={option.value} rel="noreferrer">
                    <p>{option.title}</p>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </FormInputDownload>
    </div>
  )
}

export default DownloadField
