import { useState } from 'react'
import { ModalContent } from 'presentation/components'
import { CardModel } from 'domain/models'
import { useHandleError } from 'presentation/hooks'
import { useAdmCardContext } from 'presentation/context'

import { ModalBodyConfirm } from 'presentation/components/Modal/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  cardListSort: CardModel[]
}

const SortCardsModal = ({ toggleModal, updateData, cardListSort }: Props) => {
  const { updateCardsOrder } = useAdmCardContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await updateCardsOrder.put(cardListSort)

      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Alterar a ordem dos cards"
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
    >
      <ModalBodyConfirm>
        <p className="message">Deseja salvar a nova ordem de visualização dos cards? </p>
      </ModalBodyConfirm>
    </ModalContent>
  )
}

export default SortCardsModal
