import { useState } from 'react'
import { CardModel } from 'domain/models'
import { GetCardList } from 'domain/usecases'
import { useHandleError } from '.'

type Props = {
  getCardList: GetCardList
}
export const useGetCardList = ({ getCardList }: Props) => {
  const [isLoadingCardList, setIsLoadingCardList] = useState(false)
  const [cardList, setCardList] = useState<CardModel[] | []>([])
  const { handleError } = useHandleError()

  async function getCardsData () {
    try {
      setIsLoadingCardList(true)
      const response = await getCardList.get()
      setCardList(response)
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoadingCardList(false)
    }
  }

  return {
    isLoadingCardList,
    cardList,
    getCardsData
  }
}
