import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import ReactSwitch from 'react-switch'
import { SingleValue } from 'react-select'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrip } from '@fortawesome/free-solid-svg-icons'

import { Select } from 'presentation/components'
import { OptionSelect } from 'presentation/components/Select/Select'
import { IAttributeProps } from './AdmFieldList'
import { IFormInput } from '../AddFormModal'
import { renderContent, typeFieldOptions } from './utils'
import { InputType } from 'domain/models'

import { ContainerField, ErrorMessage, customSelectStyles } from './styles'

type Props = {
  attribute: IAttributeProps
  index: number
  id: string
}

const AdmField = ({ attribute, index, id }: Props) => {
  const {
    register,
    formState: { errors },
    control,
    getValues
  } = useFormContext<IFormInput>()

  const [fieldType, setFieldType] = useState<InputType>(attribute.type)
  const defaultOptionType = typeFieldOptions.find((opt) => opt.value === attribute.type)
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <ContainerField ref={setNodeRef} style={style}>
      <div className="btn-move">
        <button type="button" title="Mover" {...attributes} {...listeners}>
          <FontAwesomeIcon icon={faGrip} />
        </button>
      </div>
      <div className="header">
        <div className="section-question">
          <input
            type="text"
            placeholder="Digite aqui a pergunta"
            {...register(`attributes.${index}.question`, { required: '* Campo obrigatório' })}
          />
          {errors?.attributes?.[index]?.question && (
            <ErrorMessage>
              {errors?.attributes?.[index]?.question?.message?.toString()}
            </ErrorMessage>
          )}
        </div>

        <Controller
          name={`attributes.${index}.type`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <div className="section-type">
              <Select
                defaultValue={defaultOptionType}
                optionValues={typeFieldOptions}
                onChange={(e) => {
                  setFieldType((e as SingleValue<OptionSelect>)?.value as InputType)
                  onChange((e as SingleValue<OptionSelect>)?.value)
                }}
                styles={customSelectStyles}
              />
            </div>
          )}
        />
      </div>

      <div className="content">{renderContent(fieldType, index)}</div>

      <div className="footer">
        <div className="section-number">
          <input type="number" {...register(`attributes.${index}.positionOrder`)} />
          <label htmlFor="positionOrder">Ordem de exibição</label>
        </div>

        <div className="section-checkbox">
          <input type="checkbox" {...register(`attributes.${index}.isHiddenToVip`)} />
          <label htmlFor="isHiddenToVip">Oculto para usuário Vip</label>
        </div>

        <Controller
          name={`attributes.${index}.isVisibleToDescription`}
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <div className="section-checkbox">
              <input
                type="checkbox"
                onChange={(e) => {
                  onChange(!e.target.checked)
                }}
              />
              <label htmlFor="isVisibleToDescription">Ocultar na descrição</label>
            </div>
          )}
        />

        {/* <div className="section-checkbox">
          <input type="checkbox" {...register(`attributes.${index}.isVisibleToDescription`)} />
          <label htmlFor="isVisibleToDescription">Ocultar na descrição</label>
        </div> */}

        <Controller
          name={`attributes.${index}.required`}
          control={control}
          rules={{ required: false }}
          render={({ field: { onChange } }) => (
            <div className="section-switch">
              <ReactSwitch
                onChange={(e) => {
                  onChange(e)
                }}
                checked={getValues().attributes[index].required ?? true}
                onColor="#86d3ff"
                onHandleColor="#2693e6"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={16}
                width={48}
                className="react-switch"
                id="material-switch"
              />
              <label htmlFor="required">Campo obrigatório</label>
            </div>
          )}
        />
      </div>
    </ContainerField>
  )
}

export default AdmField
