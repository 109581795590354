import { UpdateTicketBtnFeatures, UpdateTicketBtnFeaturesReturn } from 'domain/usecases'

export class CustomerUpdateTicketBtnAdapter implements UpdateTicketBtnFeatures {
  getFeatures = (status: string): UpdateTicketBtnFeaturesReturn => {
    const noShowBtn = status.toUpperCase() === 'FECHADO'

    const labelBtn = status.toUpperCase() === 'SOLUCIONADO' ? 'REABRIR' : 'ADICIONAR COMENTÁRIO'

    return {
      isShowBtn: !noShowBtn,
      labelBtn
    }
  }
}
