import { HttpClient, HttpStatusCode } from 'data/protocols'
import { BadRequestError, InvalidCredentialsError, UnexpectedError } from 'domain/errors'
import { INews, NewsModel } from 'domain/models'
import { EditNews } from 'domain/usecases'

export class RemoteEditNews implements EditNews {
  constructor (
    private readonly httpClient: HttpClient<NewsModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async put (dto: INews): Promise<NewsModel> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${dto.id}`,
      headers: { Authorization: this.token },
      method: 'PUT',
      body: {
        description: dto.description,
        url_picture: dto.imageUrl,
        weigh: dto.weight
      }
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro  erro ao editar os destaques')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao editar os destaques')
    }
  }
}
