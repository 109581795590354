import { AdmFaqContextProvider } from 'presentation/context'
import { FaqAdmin } from 'presentation/pages'

const makeAdminFaq = () => {
  return (
    <AdmFaqContextProvider>
      <FaqAdmin />
    </AdmFaqContextProvider>
  )
}

export default makeAdminFaq
