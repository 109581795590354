import { IAttributeProps } from '../AdmFields/AdmFieldList'
import { AttOptionsProps } from '../AdmFields/Select'

export const removeOptionsId = (list: IAttributeProps[]) => {
  const _attributes: IAttributeProps[] = []

  list.forEach((att) => {
    const _options: AttOptionsProps[] = []
    if (att.optionsSelectElement.length > 0) {
      att.optionsSelectElement.forEach((opt) => {
        _options.push({
          name: opt.name,
          title: opt.title,
          value: opt.value,
          category: opt.category,
          attributes: opt.attributes,
          weight: opt.weight,
          propertyId: opt.propertyId
        })
      })
      _attributes.push({ ...att, optionsSelectElement: _options })
    } else {
      _attributes.push({ ...att })
    }
  })

  return _attributes
}
