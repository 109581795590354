import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  UnexpectedError
} from 'domain/errors'
import { UserAdminModel } from 'domain/models'
import { CreateNewAdmin } from 'domain/usecases'

export class RemoteCreateNewAdmin implements CreateNewAdmin {
  constructor (
    private readonly httpClient: HttpClient<UserAdminModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async post (userName: string, userLogin: string): Promise<UserAdminModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'POST',
      headers: {
        Authorization: this.token
      },
      body: {
        name: userName,
        userLogin
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao adicionar um novo administrador')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao adicionar um novo administrador')
    }
  }
}
