/* eslint-disable @typescript-eslint/space-before-function-paren */
import { HttpClient, HttpStatusCode } from 'data/protocols'
import { AccessDeniedError, InvalidParamsError, UnexpectedError } from 'domain/errors'
import { CustomerSearchLocationResponse, CustomerSearchLocation } from 'domain/usecases'

export class RemoteCustomerSearchLocationByName implements CustomerSearchLocation {
  constructor(
    private readonly httpClient: HttpClient<CustomerSearchLocationResponse>,
    private readonly url: string
  ) { }

  async get(value: string): Promise<CustomerSearchLocationResponse> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params: { name: value }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao procurar a localização.')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao procurar o localização.')
    }
  }
}
