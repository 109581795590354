import { FaqModel } from 'domain/models'

type Props = {
  content: string
  urlList: string[]
  faqSelected: FaqModel
}

type PropsResponse = {
  newList: string[]
  filesToRemove: string[]
}

export const buildImageList = ({ content, urlList, faqSelected }: Props): PropsResponse => {
  const newList: string[] = []
  const filesToRemove: string[] = []
  if (faqSelected.files && faqSelected.files?.length > 0) {
    faqSelected.files?.forEach((img) => {
      if (content.includes(img)) {
        newList.push(img)
      } else {
        filesToRemove.push(img)
      }
    })
  }

  if (urlList.length > 0) {
    urlList.forEach((url) => newList.push(url))
  }
  return { newList, filesToRemove }
}
