import { useFieldArray, useFormContext } from 'react-hook-form'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import SelectItem from './SelectItem'
import { IFormInput } from '../AddFormModal'

import { WrapperSelectField } from './styles'

export type AttOptionsProps = {
  name: string
  title: string | null
  value: string
  category: null
  attributes: []
  weight: number | null
  propertyId: string | null | number
}

type Props = {
  index: number
}

const SelectAdmField = ({ index }: Props) => {
  const { control, setValue } = useFormContext<IFormInput>()
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `attributes.${index}.optionsSelectElement`
  })
  const currentOptionsLength = 110

  const updateOptionPosition = (options: AttOptionsProps[]) => {
    const _options: AttOptionsProps[] = []
    options.forEach((opt, idx) => {
      _options.push({ ...opt, weight: idx + 1 })
    })
    return _options
  }

  const reorderList = (e: DragEndEvent) => {
    if (!e.over) return

    if (e.active.id !== e.over.id) {
      const oldIdx = fields.findIndex((el: any) => el.id === e.active.id)
      const newIdx = fields.findIndex((el: any) => el.id === e.over?.id)

      const _fields = arrayMove(fields, oldIdx, newIdx)

      const _newFields = updateOptionPosition(_fields)
      setValue(`attributes.${index}.optionsSelectElement`, _newFields)
    }
  }

  return (
    <DndContext onDragEnd={reorderList} autoScroll={false}>
      <SortableContext items={fields}>
        <WrapperSelectField>
          {fields.length > 0 &&
            fields.map((option, idx) => (
              <SelectItem
                key={option.id}
                attIndex={index}
                optIndex={idx}
                remove={remove}
                id={option.id}
                option={option}
                update={update}
              />
            ))}
          <button
            type="button"
            className="btn-add-option"
            onClick={() => {
              append({
                name: `option${currentOptionsLength + fields.length + 1}`,
                title: `Option ${fields.length + 1}`,
                value: `Option ${fields.length + 1}`,
                category: null,
                attributes: [],
                weight: fields[fields.length - 1]?.weight ? (fields[fields.length - 1]?.weight as number) + 1 : 1,
                propertyId: null
              })
            }}
            title="Nova opção"
          >
            <FontAwesomeIcon icon={faCircle} />
            <p>Adicione uma opção</p>
          </button>
        </WrapperSelectField>
      </SortableContext>
    </DndContext>
  )
}

export default SelectAdmField
