import { IStatusColor } from 'domain/models'

export function statusColorAdapter<T> (data: Record<string, string>): Array<IStatusColor<T>> {
  const statusColor: Array<IStatusColor<T>> = []
  for (const key in data) {
    statusColor.push({ status: (key.replace('_', ' ') as T), color: data[key] })
  }

  return statusColor
}
