import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamation, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'presentation/components'
import { FormModel } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyWarning } from 'presentation/components/Modal/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  formSelected: FormModel
}

const EnableFormModal = ({ toggleModal, formSelected, updateData }: Props) => {
  const { editForm } = useAdmFormContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (): Promise<void> => {
    try {
      setIsLoading(true)
      const dto: FormModel = {
        ...formSelected,
        isEnable: !formSelected?.isEnable
      }
      await editForm.put(formSelected.id, dto)

      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title={formSelected?.isEnable ? 'Desativar formulário' : 'Ativar formulário'}
      handleCloseModal={toggleModal}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor='#ea580c'
    >
      <ModalBodyWarning>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faExclamation} />
          </span>
          {formSelected?.isEnable ? (
            <p>Deseja desativar o formulário: </p>
          ) : (
            <p>Deseja ativar o formulário: </p>
          )}
        </div>
        <p className="name">{formSelected?.title}</p>
        {formSelected?.isEnable ? (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>
              Ao desativar este formulário não poderá ser visualizado!
            </p>
          </span>
        ) : (
          <span className="warning-msg">
            <FontAwesomeIcon icon={faTriangleExclamation} />
            <p>Ao ativar este formulário será visualizado!</p>
          </span>
        )}
      </ModalBodyWarning>
    </ModalContent>
  )
}

export default EnableFormModal
