import { useFieldArray, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import { AdmField } from '.'
import { AttOptionsProps } from './Select'
import { IFormInput } from '../AddFormModal'
import { InputType } from 'domain/models'

import {
  AddBtnAdmField,
  RemoveBtnAdmField,
  WrapperAdmField,
  WrapperAdmFieldList,
  WrapperFields
} from './styles'

export interface IAttributeProps {
  name: string
  question: string
  optionsSelectElement: AttOptionsProps[] | []
  required: boolean
  isHiddenToVip: boolean
  type: InputType
  positionOrder: number

  placeholder: string | null
  isMulti: boolean | null
  columnsLabel: string[] | null
  isVisibleToDescription: boolean | null
  maxLength: number | null
  fileFormats: string[] | null
}

type Props = {
  defaultValue: IAttributeProps
  currentAttLength: number
}

const AdmFieldList = ({ defaultValue, currentAttLength }: Props) => {
  const { control, setValue, getValues } = useFormContext<IFormInput>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'attributes'
  })

  const updateAttPosition = (attributes: IAttributeProps[]) => {
    const _attributes: IAttributeProps[] = []
    attributes.forEach((att, idx) => {
      _attributes.push({ ...att, positionOrder: idx + 1 })
    })
    return _attributes
  }

  const reorderList = (e: DragEndEvent) => {
    if (!e.over) return

    if (e.active.id !== e.over.id) {
      const oldIdx = fields.findIndex((el) => el.id === e.active.id)
      const newIdx = fields.findIndex((el) => el.id === e.over?.id)
      const _fields = arrayMove(getValues('attributes'), oldIdx, newIdx)
      const _newFields = updateAttPosition(_fields)
      setValue('attributes', _newFields)
    }
  }

  return (
    <WrapperFields>
      <label htmlFor="attributes">Crie novas perguntas:</label>
      <DndContext onDragEnd={reorderList} autoScroll={false}>
        <SortableContext items={fields}>
          <WrapperAdmFieldList>
            {fields.map((field, index) => (
              <WrapperAdmField key={field.id}>
                <AdmField attribute={field} index={index} id={field.id} />
                <RemoveBtnAdmField>
                  <button
                    type="button"
                    onClick={() => {
                      remove(index)
                    }}
                    title="Remover campo"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                </RemoveBtnAdmField>
              </WrapperAdmField>
            ))}
          </WrapperAdmFieldList>
        </SortableContext>
      </DndContext>
      <AddBtnAdmField>
        <button
          type="button"
          onClick={() => {
            append({
              ...defaultValue,
              name: `question${currentAttLength + fields.length + 1}`,
              positionOrder: fields.length + 1
            })
          }}
          title="Adicionar novo campo"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
        <p>Adicionar pergunta</p>
      </AddBtnAdmField>
    </WrapperFields>
  )
}

export default AdmFieldList
