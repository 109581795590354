import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }

  @media(max-width: 1024px){
    html {
      font-size: 93.75%;
    }
  }

  @media(max-width: 768px){
    html {
      font-size: 87.5%;
    }
  }

  body {
    background-color: ${({ theme }) => theme.colors.gray._70};
    color: ${({ theme }) => theme.colors.gray._500};
    margin: 0;
  }

  a {
    text-decoration: none;
  }


  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-scrollbar-thumb {
    background:  ${({ theme }) => theme.colors.red._800};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background:${({ theme }) => theme.colors.gray._500};
  }
  ::-webkit-scrollbar-track {
    background:  ${({ theme }) => theme.colors.white};
    border-radius: 10px;
  }

  input[type="checkbox"]{
    border-radius: 0;
  }

  input[type="file"] {
  display: none;
  }

  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"],
  input[type="search"] {
    display: block;
    height: 2.3rem;
    padding: 0rem 1rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.gray._300};

    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray._500};
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.gray._500};
      background-color: ${({ theme }) => theme.colors.gray._100};
      appearance: none;
      outline: 0;
    }
    &::placeholder{
      color:  ${({ theme }) => theme.colors.gray._350};
      opacity: 0.5;
      font-size: .85rem;
      font-style: italic;
    }
  }

/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

  textarea{
    appearance: none;
    display: block;
    resize: vertical;
    height: 6rem;
    color: ${({ theme }) => theme.colors.gray._500};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray._300};
    border-radius: 0.5rem;
    padding: 0.6rem 1rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.gray._500};
      background-color: ${({ theme }) => theme.colors.gray._100};
      appearance: none;
      outline: 0;
    }
    &::placeholder{
      color:  ${({ theme }) => theme.colors.gray._350};
      opacity: 0.5;
      font-size: .85rem;
      font-style: italic;
    }
  }



  input[type="time"], input[type="date"]{
    display: block;
    max-width: 15rem;
    height: 2.3rem;
    padding: 0rem 1rem;
    outline: none;
    color: ${({ theme }) => theme.colors.gray._500};
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.gray._300};
    border-radius: 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.gray._500};
      background-color: ${({ theme }) => theme.colors.gray._100};
    }
    &::placeholder{
      color:  ${({ theme }) => theme.colors.gray._350};
      opacity: 0.5;
      font-size: .85rem;
      font-style: italic;
    }
  }

  input[type="date"] {
    min-width: 12rem;
  }

  dialog {
    border: none;
    border-radius: 1.25rem;
    background-color: ${({ theme }) => theme.colors.white};;
    width: 35rem;
    margin: auto;
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.22));


    &::backdrop{
      backdrop-filter:  brightness(45%) blur(2px);
    }

    &:focus-visible{
      border: none;
    }
  }
`
