import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  BadRequestError,
  ConflictError,
  InvalidCredentialsError,
  UnexpectedError
} from 'domain/errors'
import { FaqModel } from 'domain/models'
import { EditFaq, EditFaqDto } from 'domain/usecases'

export class RemoteEditFaq implements EditFaq {
  constructor (
    private readonly httpClient: HttpClient<FaqModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async put (data: EditFaqDto): Promise<FaqModel> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${data.id}`,
      headers: { Authorization: this.token },
      method: 'PUT',
      body: data.dto
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro  erro ao editar a FAQ')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      case HttpStatusCode.conflict:
        throw new ConflictError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao editar a FAQ')
    }
  }
}
