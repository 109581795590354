import { AttributeModel } from 'domain/models'

type Props = {
  data: Record<string, string | File>
  attributes: AttributeModel[]
  extraQuestions?: AttributeModel[] | null
}
export const setPropertyId = ({ data, attributes, extraQuestions = null }: Props) => {
  let newData: Record<string, string | number> = {}

  const setNewData = (list: AttributeModel[]) => {
    list.forEach((attribute) => {
      for (const [key, value] of Object.entries(data)) {
        if (key === attribute.name && attribute.propertyId) {
          const keyField = attribute.propertyId
          const field: Record<string, string> = {}
          if (value instanceof File) {
            field[keyField] = value.name
          } else {
            field[keyField] = value
          }
          newData = { ...newData, ...field }
        }
      }
    })
  }

  setNewData(attributes)

  if (extraQuestions && extraQuestions?.length > 0) {
    setNewData(extraQuestions)
  }

  return newData
}
