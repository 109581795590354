import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  UnexpectedError
} from 'domain/errors'
import { NewsModel } from 'domain/models'
import { GetNewsList } from 'domain/usecases'

export class RemoteGetNewsList implements GetNewsList {
  constructor (
    private readonly httpClient: HttpClient<NewsModel[]>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async get (): Promise<NewsModel[]> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'GET',
      headers: {
        Authorization: this.token
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao buscar os destaques.')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao buscar os destaques.')
    }
  }
}
