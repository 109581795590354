import { useState } from 'react'
import { FaqModel, FormModel } from 'domain/models'
import { OptionSelect } from 'presentation/components/Select/Select'
import { GetFormList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getFormList: GetFormList
}

export const useGetFormOptions = ({ getFormList }: Props) => {
  const [isLoadingFormOptions, setIsLoadingFormOptions] = useState(false)
  const [formOptions, setFormOptions] = useState<OptionSelect[]>([])
  const [formOptionsSelected, setFormOptionsSelected] = useState<OptionSelect[]>([])
  const { handleError } = useHandleError()

  const getFormListOptions = async (faqSelected: FaqModel | null = null) => {
    try {
      setIsLoadingFormOptions(true)
      const response = await getFormList.get({ limit: 500, page: 0, order: 'DESC' })

      if (response.data.length > 0) {
        const forms: OptionSelect[] = []
        response.data.forEach((el: FormModel) => {
          const label = `${el.title}`

          forms.push({
            value: { id: el.id as number, title: el.title },
            label
          })
        })
        if (faqSelected) {
          setFormOptionsSelected(getFormsAssociated({ options: forms, faqSelected }))
        }
        setFormOptions(forms)
      }
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoadingFormOptions(false)
    }
  }
  return {
    isLoadingFormOptions,
    formOptions,
    getFormListOptions,
    formOptionsSelected
  }
}

type FormsAssociatedProps = {
  options: OptionSelect[]
  faqSelected: FaqModel
}

const getFormsAssociated = ({ options, faqSelected }: FormsAssociatedProps): OptionSelect[] => {
  const list: OptionSelect[] = []
  faqSelected?.formsAssociated?.forEach((el) => {
    const formSelected = options.find((opt: any) => opt.value.id === el.id)
    if (formSelected) {
      list.push(formSelected)
    }
  })
  return list
}
