import { createContext, ReactNode, useContext } from 'react'
import { CreateCard, DeleteCard, EditCard, GetCardList, UpdateCardsOrder } from 'domain/usecases'
import {
  makeCreateCard,
  makeDeleteCard,
  makeEditCard,
  makeGetCardList,
  makeUpdateCardsOrder
} from 'main/factories/usecases'

type PropsContext = {
  getCardList: GetCardList
  createCard: CreateCard
  editCard: EditCard
  updateCardsOrder: UpdateCardsOrder
  deleteCard: DeleteCard
}

export const AdmCardContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const AdmCardContextProvider = ({ children }: Props): any => {
  return (
    <AdmCardContext.Provider
      value={{
        getCardList: makeGetCardList(),
        createCard: makeCreateCard(),
        editCard: makeEditCard(),
        updateCardsOrder: makeUpdateCardsOrder(),
        deleteCard: makeDeleteCard()
      }}
    >
      {children}
    </AdmCardContext.Provider>
  )
}

export const useAdmCardContext = () => {
  return useContext(AdmCardContext)
}
