import { ChangeEvent, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { AttributeModel, OptionModel } from 'domain/models'

export interface OptionListI extends OptionModel {
  attName: string
}

type Props = {
  methods: UseFormReturn<any, any>
}

export const useSetExtraQuestions = ({ methods }: Props) => {
  const [optionListSelected, setOptionListSelected] = useState<OptionListI[] | any>([])

  const handleCheckboxExtraQuestions = (
    e: ChangeEvent<HTMLInputElement>,
    option: OptionModel,
    attName: string
  ) => {
    const newOptionListSelected = setExtraQuestionsFromCheckboxField({
      optSelected: option,
      isChecked: e.target.checked,
      attName,
      optionListSelected,
      methods
    })
    setOptionListSelected(newOptionListSelected)
  }

  const handleSelectExtraQuestions = (optSelected: string, attribute: AttributeModel) => {
    const newOptionListSelected = setExtraQuestionsFromSelectField({
      optSelected,
      attribute,
      optionListSelected,
      methods
    })
    setOptionListSelected(newOptionListSelected)
  }

  return {
    optionListSelected,
    setOptionListSelected,
    handleCheckboxExtraQuestions,
    handleSelectExtraQuestions
  }
}

type SelectProps = {
  optSelected: string
  attribute: AttributeModel
  optionListSelected: OptionListI[]
  methods: UseFormReturn<FieldValues, any>
}

export const setExtraQuestionsFromSelectField = ({
  optSelected,
  attribute,
  optionListSelected,
  methods
}: SelectProps): OptionListI[] => {
  let newOptionListSelected = optionListSelected

  if (optionListSelected.length > 0) {
    // Unregister all the attributes from de same question
    const selectedOption = optionListSelected.find((opt) => opt.attName === attribute.name)

    if (selectedOption && selectedOption.attributes.length > 0) {
      selectedOption.attributes.forEach((el) => {
        methods.unregister(el.name)
      })
    }
    // Filter the options list to no repeat the same question
    newOptionListSelected = optionListSelected.filter((opt) => opt.attName !== attribute.name)
  }
  // Get the data from the option selected
  const attSelected = attribute.optionsSelectElement.find((option) => option.value === optSelected)

  if (attSelected) {
    const newOptionSelect = { ...attSelected, attName: attribute.name }
    return [...newOptionListSelected, newOptionSelect]
  }
  return newOptionListSelected
}

type CheckboxProps = {
  optSelected: OptionModel
  isChecked: boolean
  optionListSelected: OptionListI[]
  methods: UseFormReturn<FieldValues, any>
  attName: string
}

export const setExtraQuestionsFromCheckboxField = ({
  optSelected,
  isChecked,
  attName,
  optionListSelected,
  methods
}: CheckboxProps): OptionListI[] => {
  let newOptionListSelected = optionListSelected

  if (isChecked) {
    const newOptionSelect = { ...optSelected, attName }
    return [...newOptionListSelected, newOptionSelect]
  } else {
    newOptionListSelected = newOptionListSelected.filter((op) => op.value !== optSelected.value)
    if (optSelected.attributes.length > 0) {
      optSelected.attributes?.forEach((att) => {
        methods.unregister(att.name)
      })
    }
    return newOptionListSelected
  }
}
