import { LegacyRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { FaqList } from '..'
import { FaqModel } from 'domain/models'

import { ContainerModalFaq } from './styles'

type Props = {
  refModal: LegacyRef<HTMLDialogElement>
  isModalOpen: boolean
  toggleModal: () => void
  title: string
  faqList: FaqModel[]
}

const FaqModal = ({
  refModal,
  isModalOpen,
  toggleModal,
  title,
  faqList
}: Props) => {
  const navigate = useNavigate()
  return (
    <ContainerModalFaq ref={refModal} className="dialog-faq">
      {isModalOpen && (
        <>
          <div className="modal-header">
            <p>Você escolheu a opção:&nbsp;</p>
            <b>{title}</b>
          </div>
          <div>
            <p>
              Esses artigos podem te ajudar a entender melhor sobre essa opção:
            </p>
            <FaqList faqList={faqList} />
          </div>
          <div className="modal-footer">
            <p>Ainda restou alguma dúvida?</p>
            <div className='btn-action'>
              <button
                type='button'
                onClick={() => {
                  navigate('/')
                }}
                className='btn-left'
              >
                Não tenho mais dúvidas
              </button>
              <button type='button' onClick={toggleModal} className='btn-right'>
                Sim, gostaria de abrir um chamado
              </button>
            </div>
          </div>
        </>
      )}
    </ContainerModalFaq>
  )
}

export default FaqModal
