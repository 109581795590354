import { AdmCardContextProvider } from 'presentation/context'
import { CardAdmin } from 'presentation/pages'

const makeAdminCard = () => {
  return (
    <AdmCardContextProvider>
      <CardAdmin />
    </AdmCardContextProvider>
  )
}

export default makeAdminCard
