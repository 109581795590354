/* eslint-disable @typescript-eslint/space-before-function-paren */
import { Navigate, Route, Routes } from 'react-router-dom'
import { AdmLayout, Layout } from 'presentation/components'
import { getCurrentAccountAdapter, getTokenAdapter } from 'main/adapters'
import {
  MakeAdminCard,
  MakeAdminFaq,
  MakeAdminForm,
  MakeAdminManagement,
  MakeAdminNews,
  MakeHome,
  MakeSuccess,
  MakeTicketDetails,
  MakeTicketForm,
  MakeTicketList
} from 'main/pages'

const PrivateRoutes = () => {
  if (!getTokenAdapter()) {
    return <Navigate to="/login" replace />
  }
  return (
    <>
      <CommonRoutes />
      <ProtectedRoutes />
    </>
  )
}

export default PrivateRoutes

function CommonRoutes() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout title="Auto Atendimento">
            {' '}
            <MakeHome />{' '}
          </Layout>
        }
      />
      <Route
        path="/ticket/list"
        element={
          <Layout title="Consulta de chamados">
            <MakeTicketList />
          </Layout>
        }
      />
      <Route
        path="/ticket/detail/:ticketID"
        element={
          <Layout title="Detalhes do Chamado">
            <MakeTicketDetails />
          </Layout>
        }
      />
      <Route path="/ticket/new/:formId" element={<MakeTicketForm />} />

      <Route
        path="/ticket/success/:ticketId"
        element={
          <Layout title="Chamado criado com sucesso">
            <MakeSuccess />
          </Layout>
        }
      />
    </Routes>
  )
}

function ProtectedRoutes() {
  const user = getCurrentAccountAdapter()
  return (
    <Routes>
      <Route
        path="/admin"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de administradores">
              <MakeAdminManagement />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
      <Route
        path="/admin/news"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de destaques">
              {' '}
              <MakeAdminNews />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
      <Route
        path="/admin/faq"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de FAQs">
              {' '}
              <MakeAdminFaq />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
      <Route
        path="/admin/card"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de Cards">
              {' '}
              <MakeAdminCard />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
      <Route
        path="/admin/card/:cardId"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de Cards">
              {' '}
              <MakeAdminCard />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
      <Route
        path="/admin/card/:cardIdL1/:cardId"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de Cards">
              <MakeAdminCard />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
      <Route
        path="/admin/card/:cardIdL1/:cardIdL2/:cardId"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de Cards">
              <MakeAdminCard />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />

      <Route
        path="/admin/forms"
        element={
          user?.role === 'ADMIN' ? (
            <AdmLayout title="Administração de Forms">
              <MakeAdminForm />
            </AdmLayout>
          ) : (
            <Navigate to={'/'} />
          )
        }
      />
    </Routes>
  )
}
