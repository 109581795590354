import { createContext, ReactNode, useContext } from 'react'
import { CreateNewAdmin, DeleteAdmin, EditAdmin, GetAdminList } from 'domain/usecases'
import {
  makeCreateNewAdmin,
  makeDeleteAdmin,
  makeEditAdmin,
  makeGetAdminList
} from 'main/factories/usecases'

type PropsContext = {
  createNewAdmin: CreateNewAdmin
  getAdminList: GetAdminList
  editAdmin: EditAdmin
  deleteAdmin: DeleteAdmin
}

export const AdmManagementContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const AdmManagementContextProvider = ({ children }: Props): any => {
  return (
    <AdmManagementContext.Provider
      value={{
        createNewAdmin: makeCreateNewAdmin(),
        getAdminList: makeGetAdminList(),
        editAdmin: makeEditAdmin(),
        deleteAdmin: makeDeleteAdmin()
      }}
    >
      {children}
    </AdmManagementContext.Provider>
  )
}

export const useAdmManagementContext = () => {
  return useContext(AdmManagementContext)
}
