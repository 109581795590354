/* eslint-disable @typescript-eslint/space-before-function-paren */
import {
  IPostTicket,
  IPostTicketResponse,
  CustomerAttachmentsModel,
  CustomerPostTicketModel
} from 'domain/models'
import { PostTicket, CustomerPostTicket } from 'domain/usecases'
import { getCurrentAccountAdapter } from '../current-account-adapter'

export class CustomerPostTicketAdapter implements PostTicket {
  constructor(private readonly customerPostTicket: CustomerPostTicket) {}

  async create(dto: IPostTicket): Promise<IPostTicketResponse> {
    const currentAccount = getCurrentAccountAdapter().login
    const files: CustomerAttachmentsModel[] = []

    dto.attachments.forEach((file) =>
      files.push({
        file_url: file.url,
        file_name: file.name ?? '',
        file_token: process.env.REACT_APP_TOKEN_RECIVED_FILE_MANAGER ?? ''
      })
    )

    const newDto: CustomerPostTicketModel = {
      user_login: dto.userLogin,
      user_called: currentAccount === dto.userLogin ? null : currentAccount,
      status: '1' as const,
      ticket_category_id: dto.category,
      group: dto.group,
      ticket_content: dto.description,
      ticket_name: dto.summary,
      ticket_type: dto.type ? ('1' as const) : ('2' as const),
      attachments_glpi: files,
      created_via: '3',
      entities_id: dto.entityId ?? null
    }

    const response = await this.customerPostTicket.create(newDto)

    if (response) {
      return {
        ticketId: response.ticket_id.toString()
      }
    }

    return response
  }
}
