import { getCurrentAccountAdapter } from 'main/adapters'
import { ContainerPhoto } from './styles'

type Props = {
  urlImg?: string | null
  className?: string
}

const UserPhoto = ({ urlImg, className = '' }: Props) => {
  const user = getCurrentAccountAdapter()
  const fullName = user?.name?.trim().split(' ')
  let primaryWord = ''
  let secondaryWord = ''

  if (Array.isArray(fullName)) {
    primaryWord = fullName[0]?.charAt(0)
    secondaryWord = fullName.length >= 2 ? fullName[fullName.length - 1].charAt(0) : ''
  }

  return (
    <ContainerPhoto className={className}>
      <span>
        {primaryWord.toUpperCase()}
        {secondaryWord?.toUpperCase()}
      </span>
    </ContainerPhoto>
  )
}

export default UserPhoto
