import {
  Controller,
  FieldArrayWithId,
  UseFieldArrayRemove,
  UseFieldArrayUpdate,
  useFormContext
} from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGrip, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { IFormInput } from '../AddFormModal'

type Props = {
  attIndex: number
  optIndex: number
  remove: UseFieldArrayRemove
  id: string | number
  option?: FieldArrayWithId<IFormInput, `attributes.${number}.optionsSelectElement`, 'id'> | null
  update: UseFieldArrayUpdate<IFormInput, `attributes.${number}.optionsSelectElement`>
}

const SelectItem = ({ attIndex, optIndex, remove, id, option, update }: Props) => {
  const { control, setValue } = useFormContext<IFormInput>()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <div className="section-option" ref={setNodeRef} style={style}>
      <div className="values-opt">
        <button type="button" className="btn-move-opt" title="Mover" {...attributes} {...listeners}>
          <FontAwesomeIcon icon={faGrip} rotation={90} />
        </button>
        <FontAwesomeIcon icon={faCircle} />

        <Controller
          name={`attributes.${attIndex}.optionsSelectElement.${optIndex}.value`}
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange } }) => (
            <div className="section-type">
              <input
                type="text"
                placeholder="Opção"
                defaultValue={option?.value}
                onChange={(e: any) => {
                  setValue(
                    `attributes.${attIndex}.optionsSelectElement.${optIndex}.value`,
                    e.target.value
                  )
                }}
                onBlur={(e) => {
                  update(optIndex, {
                    name: option?.name as string,
                    title: e.target.value,
                    value: e.target.value,
                    category: option?.category as null,
                    attributes: option?.attributes as [],
                    weight: option?.weight as number,
                    propertyId: option?.propertyId as null
                  })
                }}
              />
            </div>
          )}
        />
      </div>
      <button
        type="button"
        className="btn-remove-opt"
        onClick={() => {
          remove(optIndex)
        }}
        title="Remover opção"
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>
    </div>
  )
}

export default SelectItem
