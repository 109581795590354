import { ToastContainer } from 'react-toastify'
import AdminModal from 'presentation/components/Modal/ModalContent'
import FollowupItem from './FollowupItem'
import { IFollowup, ITicket } from 'domain/models'

import { WrapperFollowupList } from './styles'

type Props = {
  ticket: ITicket
  toggleModal: () => void
}

const FollowupListModal = ({ ticket, toggleModal }: Props) => {
  const handleCloseModal = () => {
    toggleModal()
  }

  return (
    <AdminModal
      title="Acompanhamento do chamado"
      handleCloseModal={handleCloseModal}
      handleSubmit={null}
      isLoading={false}
    >
      <WrapperFollowupList>
        {ticket?.followups?.allFollowups.map((data: IFollowup, index: number) => (
          <FollowupItem key={index} followup={data} />
        ))}
      </WrapperFollowupList>
      <ToastContainer />
    </AdminModal>
  )
}

export default FollowupListModal
