import { RemoteCustomerSearchLocationByName } from 'data/usecases/customer/user/remote-search-location'
import { CustomerSearchLocation } from 'domain/usecases'
import { SearchLocation } from 'domain/usecases/user/search-location'
import { CustomerSearchLocationAdapter } from 'main/adapters/customer/search-location-adapter'
import { makeAxiosHttpClient, makeConnectorApiUrl } from 'main/factories/http'

export const makeRemoteCustomerSearchLocation = (): CustomerSearchLocation =>
  new RemoteCustomerSearchLocationByName(makeAxiosHttpClient(), makeConnectorApiUrl('/location/show'))

export const makeSearchLocation = (): SearchLocation =>
  new CustomerSearchLocationAdapter(makeRemoteCustomerSearchLocation())
