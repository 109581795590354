import { ClipLoader } from 'react-spinners'
import { WrapperSpinner } from './styles'

export type FlexDirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse'

type Props = {
  size?: number
  marginTop?: string
  text?: string | null
  flexDirection?: FlexDirectionType | null
}

const Spinner = ({ size = 45, marginTop = '3rem', text = null, flexDirection = null }: Props) => {
  return (
    <WrapperSpinner $marginTop={marginTop} $direction={flexDirection as string}>
      <ClipLoader color="#000000" size={size} aria-label="Loading Spinner" data-testid="loader" />
      {text && <span>{ text}</span>}
    </WrapperSpinner>
  )
}

export default Spinner
