import { useState, useRef, MouseEvent, Fragment, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Card } from '..'
import { useWindowDimensions } from 'presentation/hooks'
import { CardModel } from 'domain/models'

import { ContainerCarousel } from './styles'

const getScrollValueX = (): number => {
  const currentSizeScreen = window.innerWidth
  if (currentSizeScreen >= 1280) {
    return 8
  }
  if (currentSizeScreen < 1280 && currentSizeScreen >= 1024) {
    return 3
  }
  if (currentSizeScreen < 1024 && currentSizeScreen >= 768) {
    return 2
  }
  if (currentSizeScreen < 768) {
    return 1
  }
  return 1
}

type Props = {
  isOneRow: boolean
  cardList: CardModel[]
  handleCardSelected: (value: CardModel) => void
}

function Carousel ({ isOneRow, cardList, handleCardSelected }: Props) {
  const { width } = useWindowDimensions()
  const [isMouseDown, setIsMouseDown] = useState(false)
  const [startPositionX, setStartPositionX] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const [isShowBtn, setIsShowBtn] = useState(false)

  const refContainer = useRef<HTMLDivElement>(null)
  const refInner = useRef<HTMLDivElement>(null)

  const handelMouseDown = (e: MouseEvent<HTMLElement>) => {
    setIsMouseDown(true)
    setStartPositionX(e.nativeEvent.screenX)

    if (refContainer.current) {
      refContainer.current.style.cursor = 'grabbing'
    }
  }

  const handleMouseUp = () => {
    setIsMouseDown(false)
    if (refContainer.current) {
      refContainer.current.style.cursor = 'grab'
    }
  }

  const handleMouseMove = (e: MouseEvent<HTMLElement>) => {
    if (!isMouseDown) return
    e.preventDefault()
    if (refContainer.current) {
      // Move to right direction
      const scrollValue = getScrollValueX()
      if (e.nativeEvent.screenX < startPositionX) {
        refContainer.current?.scrollBy(scrollValue, 0)
        return
      }
      // Move to left direction
      if (e.nativeEvent.screenX > startPositionX) {
        refContainer.current?.scrollBy(-scrollValue, 0)
      }
    }
  }

  const handleOnclickBtn = (direction: string) => {
    const coordX = 200
    const scrollValue = direction === 'right' ? coordX : -coordX
    refContainer.current?.scrollBy(scrollValue, 0)
    setScrollX(refContainer.current?.scrollLeft ?? 0)
  }

  useEffect(() => {
    if (refContainer.current?.scrollWidth && refContainer.current?.clientWidth) {
      setIsShowBtn(refContainer.current?.scrollWidth !== refContainer.current?.clientWidth)
    }
  }, [width])

  return (
    <ContainerCarousel $isOneRow={isOneRow} $btnLeftActive={scrollX > 0}>
      {isShowBtn && (
        <button
          className="btn-carousel btn-carousel-left"
          onClick={() => {
            handleOnclickBtn('left')
          }}
        >
          <FontAwesomeIcon icon={faAngleLeft} />
        </button>
      )}
      <div
        ref={refContainer}
        className="carousel-box--outer"
        onMouseDown={handelMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div ref={refInner} className="carousel-box--inner">
          {cardList.map((card: CardModel) => (
            <Fragment key={card.id}>
              {card.isEnable ? (
                <Card
                  key={card.id}
                  card={card}
                  onClick={() => {
                    handleCardSelected(card)
                  }}
                />
              ) : null}
            </Fragment>
          ))}
        </div>
      </div>
      {isShowBtn && (
        <button
          className="btn-carousel btn-carousel-right"
          onClick={() => {
            handleOnclickBtn('right')
          }}
        >
          <FontAwesomeIcon icon={faAngleRight} />
        </button>
      )}
    </ContainerCarousel>
  )
}

export default Carousel
