import { createContext, ReactNode, useContext } from 'react'
import { HttpClientAll } from 'data/protocols'
import { makeAxiosAllDeletePublicFiles, makeAxiosAllSavePublicFiles } from 'main/factories/http'
import {
  makeCreateFaq,
  makeDeleteFaq,
  makeEditFaq,
  makeGetFaqList,
  makeGetFormList
} from 'main/factories/usecases'
import { CreateFaq, DeleteFaq, EditFaq, GetFaqList, GetFormList } from 'domain/usecases'

type PropsContext = {
  saveMultipleFiles: HttpClientAll
  deleteMultipleFiles: HttpClientAll
  getFaqList: GetFaqList
  getFormList: GetFormList
  createFaq: CreateFaq
  editFaq: EditFaq
  deleteFaq: DeleteFaq
}

export const AdmFaqContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const AdmFaqContextProvider = ({ children }: Props): any => {
  return (
    <AdmFaqContext.Provider
      value={{
        saveMultipleFiles: makeAxiosAllSavePublicFiles(),
        deleteMultipleFiles: makeAxiosAllDeletePublicFiles(),
        getFaqList: makeGetFaqList(),
        getFormList: makeGetFormList(),
        createFaq: makeCreateFaq(),
        editFaq: makeEditFaq(),
        deleteFaq: makeDeleteFaq()
      }}
    >
      {children}
    </AdmFaqContext.Provider>
  )
}

export const useAdmFaqContext = () => {
  return useContext(AdmFaqContext)
}
