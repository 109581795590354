import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  NotFoundError,
  UnexpectedError
} from 'domain/errors'
import { CustomerTicketListPaginationModel } from 'domain/models'
import { GetTicketsDto, CustomerGetTicketListWithoutPagination } from 'domain/usecases'

export class RemoteCustomerGetTicketListWithoutPagination
implements CustomerGetTicketListWithoutPagination {
  constructor (
    private readonly httpClient: HttpClient<CustomerTicketListPaginationModel>,
    private readonly url: string
  ) {}

  async get (params: GetTicketsDto): Promise<CustomerTicketListPaginationModel> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${params.userLogin}`,
      method: 'get',
      params: {
        limit: params.limit,
        page: params.page,
        status: params.filter?.status
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao recuperar os chamados.')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      case HttpStatusCode.notFound:
        throw new NotFoundError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao recuperar os chamados.')
    }
  }
}
