import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'presentation/components'
import { CardModel } from 'domain/models'
import { useAdmCardContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyDelete } from 'presentation/components/Modal/styles'
import { ErrorMessage } from './styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  cardSelected: CardModel
}

const DeleteCardModal = ({ toggleModal, cardSelected, updateData }: Props) => {
  const { deleteCard } = useAdmCardContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleDeleteAdmin = async (): Promise<void> => {
    if (!isConfirm) {
      setErrorMessage('* Campo obrigatório')
      return
    }
    try {
      setIsLoading(true)
      await deleteCard.delete(cardSelected.id.toString())
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover card"
      handleCloseModal={toggleModal}
      handleSubmit={handleDeleteAdmin}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#000000"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este card?</p>
        </div>
        <p className="admName">{cardSelected?.name}</p>
        <span className="warning-msg">
          <p>Ao remover este card todos os subcards dependentes serão removidos!</p>
        </span>
        <div className="confirm-action">
          <span className="confirm-msg">
            <input
              type="checkbox"
              onChange={() => {
                setIsConfirm(!isConfirm)
                setErrorMessage('')
              }}
            />
            <p>Confirmo que li e estou ciente da remoção do card.</p>
          </span>
          {errorMessage && <ErrorMessage $fontSize={'0.75rem'}>{errorMessage}</ErrorMessage>}
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteCardModal
