/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { ContainerLoading } from '../../components'
import { useGetTicketDetails } from '../../hooks'
import { GetTicket } from 'domain/usecases'

import successImg from '../../assets/success.svg'
import { ContainerSuccess } from './styles'

type Props = {
  getTicket: GetTicket
}
export function Success({ getTicket }: Props) {
  const { ticketId } = useParams()
  const { isLoadingTicket, ticket, getTicketDetails } = useGetTicketDetails({ getTicket })

  useEffect(() => {
    if (ticketId) {
      getTicketDetails(ticketId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId])

  return (
    <ContainerSuccess>
      {isLoadingTicket ? (
        <ContainerLoading />
      ) : (
        <div className="success-content">
          <img src={successImg} alt="success" />
          <h2>Seu chamado foi registrado!</h2>
          <div className="success-content-info">
            <span>
              Para consultar o andamento do chamado utilize o ID abaixo. Você pode consultar o
              chamado através da Central de serviços de TI.
            </span>
            <h4>
              Grupo Solucionador:{' '}
              {ticket?.group ? ticket?.group.split('_')[0] : 'Chamado sem categoria'}
            </h4>
            <h4>Prazo para atendimento: {ticket?.dueAt}</h4>
            <h4>Chamado ID {ticketId} registrado com sucesso</h4>
            <div className="success-btn">
              <Link to="/">
                <button>Voltar para a tela inicial</button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </ContainerSuccess>
  )
}
