export type CustomerSearchUserModel = {
  user_login: string
  user_full_name: string
  user_id: string | number
  user_email: string | null
  registration: string
}

export type CustomerSearchLocationModel = {
  location: string
  location_id: string | number
}

export type CustomerUserAuthenticationModel = {
  user_name: string
  user_email: string
  user_full_name: string
}

export type CustomerUserProfileModel = {
  network_login: string
  user_profile: string
}

export enum CustomerUserVipEnum {
  MAGISTRADO = 'MAGISTRADO/ALTA GESTÃO',
  VIP = 'VIP',
  CHEFE = 'CHEFE',
}

export type CustomerUserVipType = `${CustomerUserVipEnum}`
