/* eslint-disable @typescript-eslint/space-before-function-paren */
import { CustomerSearchLocationModel } from 'domain/models'
import { IGetLocationInfo } from 'domain/models/locationInfo/location'
import { CustomerSearchLocation } from 'domain/usecases'
import { SearchLocation } from 'domain/usecases/user/search-location'

export class CustomerSearchLocationAdapter implements SearchLocation {
  constructor(private readonly customerSearchLocation: CustomerSearchLocation) { }

  async get(value: string): Promise<IGetLocationInfo[]> {
    const response = await this.customerSearchLocation.get(value)

    if (response) {
      const newList: IGetLocationInfo[] = []
      if (response.Locations.length > 0) {
        response.Locations.forEach((location: CustomerSearchLocationModel) =>
          newList.push({
            locationId: location.location_id,
            locationName: location.location
          })
        )
      }

      return newList
    }

    return response
  }
}
