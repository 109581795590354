import { CardModel } from 'domain/models'
import { OptionSelect } from 'presentation/components/Select/Select'
import { WrapperIconCard } from '../styles'
import { CardIcon, CardLabelColor } from 'presentation/components/Card/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export const buildOptions = (cards: CardModel[], parentHierarchy: number) => {
  const cardOptions: OptionSelect[] = []

  // .filter((card) => card.isEnable)
  cards
    .forEach((card) => {
      const label = (
        <WrapperIconCard>
          <CardLabelColor $colorLabel={card.color} $height="90% !important" />
          <CardIcon $padding="0.35rem">
            <FontAwesomeIcon icon={(card.icon as IconProp) ?? 'fa-solid fa-gear'} />
          </CardIcon>
          {card.name}
        </WrapperIconCard>
      )

      const value = {
        ...card,
        hierarchy: parentHierarchy + 1,
        attName: `cardHierarchyLevel${parentHierarchy + 1}`
      }

      cardOptions.push({ label, value })
    })
  return cardOptions
}
