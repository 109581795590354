import { FaqModel } from 'domain/models'
import { IFormInput } from '../AddFaqModal'

type Props = {
  data: IFormInput
  faqSelected: FaqModel
}

export const checkDataChanges = ({ data, faqSelected }: Props): boolean => {
  const isEqualTitle = data.title === faqSelected.title
  const isEqualContent = JSON.stringify(data.content) === JSON.stringify(faqSelected.content)
  const isEqualFormIds =
    JSON.stringify(data.formsAssociated) === JSON.stringify(faqSelected.formsAssociated)

  return !(isEqualContent && isEqualFormIds && isEqualTitle)
}
