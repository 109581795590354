import { HttpClient, HttpStatusCode } from 'data/protocols'
import { BadRequestError, InvalidCredentialsError, UnexpectedError } from 'domain/errors'
import { FormModel } from 'domain/models'
import { CreateForm, CreateFormProps } from 'domain/usecases'

export class RemoteCreateForm implements CreateForm {
  constructor (
    private readonly httpClient: HttpClient<FormModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async post (dto: CreateFormProps): Promise<FormModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      headers: { Authorization: this.token },
      method: 'post',
      body: dto
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao criar um novo formulário')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao adicionar um novo formulário')
    }
  }
}
