import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'presentation/components'
import { useHandleError } from 'presentation/hooks'
import { UserAdminModel } from 'domain/models'
import { getCurrentAccountAdapter } from 'main/adapters'
import { useAdmManagementContext } from 'presentation/context'
import { InvalidCredentialsError } from 'domain/errors'

import { ModalBodyDelete } from 'presentation/components/Modal/styles'

type Props = {
  toggleModal: () => void
  adminSelected: UserAdminModel
  getAdmins: () => void
}

const DeleteAdminModal = ({ toggleModal, adminSelected, getAdmins }: Props) => {
  const { deleteAdmin } = useAdmManagementContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteAdmin = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await deleteAdmin.delete(adminSelected.id.toString())

      const user = getCurrentAccountAdapter()
      getAdmins()
      if (user.login.toLowerCase() === adminSelected?.userLogin.toLowerCase()) {
        throw new InvalidCredentialsError('Ops 😥, você não é mais administrador, por favor fazer login novamente')
      }
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover administrador"
      handleCloseModal={toggleModal}
      handleSubmit={handleDeleteAdmin}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#000000"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este administrador?</p>
        </div>
        <p className="admName">{adminSelected?.name}</p>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteAdminModal
