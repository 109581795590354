import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'presentation/components'
import { INews } from 'domain/models'
import { useAdmNewsContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyDelete } from 'presentation/components/Modal/styles'

type Props = {
  toggleModal: () => void
  newsSelected: INews
  updateData: () => void
}

const DeleteNewsModal = ({ toggleModal, newsSelected, updateData }: Props) => {
  const { deleteFile, deleteNews } = useAdmNewsContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await deleteNews.delete(newsSelected.id.toString())
      await deleteFile.delete(newsSelected.imageUrl)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover destaque"
      handleCloseModal={toggleModal}
      handleSubmit={handleDelete}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#000000"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este destaque?</p>
        </div>
        <div className="text" dangerouslySetInnerHTML={{ __html: newsSelected.description }} />
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteNewsModal
