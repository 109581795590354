import { EditorImageProps } from 'presentation/components/Fields/EditorContentField'

type Props = {
  images: EditorImageProps[]
  content: string
  urlList: Array<{ name: string, url: string }>
}

export const replaceBase64ByUrl = ({ images, content, urlList }: Props): string => {
  let newValue = ''

  if (images.length > 0) {
    let auxNewValue = content
    images.forEach(({ fileName, base64 }) => {
      const newUrl = urlList.find((url: any) => url.name === fileName)
      if (newUrl) {
        newValue = auxNewValue
        newValue = newValue.replace(base64, newUrl?.url)
        auxNewValue = newValue
      }
    })
  }
  return newValue
}
