import { HttpClient, HttpStatusCode } from 'data/protocols'
import { BadRequestError, InvalidCredentialsError, UnexpectedError } from 'domain/errors'
import { NewsModel } from 'domain/models'
import { UpdateOrderNews, UpdateOrderNewsProps } from 'domain/usecases'

export class RemoteUpdateOrderNews implements UpdateOrderNews {
  constructor (
    private readonly httpClient: HttpClient<NewsModel[]>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async put (data: UpdateOrderNewsProps[]): Promise<NewsModel[]> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      headers: { Authorization: this.token },
      method: 'post',
      body: data
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao reordenar os destques')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao reordenar os destques')
    }
  }
}
