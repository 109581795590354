/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'presentation/components'
import { SelectField, TextField } from 'presentation/components/Fields'
import {
  useGetAttributes,
  useGetCardList,
  useGetCategories,
  useHandleError
} from 'presentation/hooks'
import { AdmFieldList } from './AdmFields'
import PreviewFormModal from './PreviewFormModal'
import { IFormInput } from './AddFormModal'
import {
  buildCardOptions,
  findAttributeOptions,
  findCategoryOpt,
  getDefaultValues,
  removeOptionsId,
  setAttributeToShow
} from './utils'
import { OptionSelect } from 'presentation/components/Select/Select'
import { FormI } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'
import { CreateFormProps } from 'domain/usecases'

import { ModalBodyForm, ModalWrapper } from 'presentation/components/Modal/styles'
import { customFormSelectStyles } from '../Select/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  formSelected: FormI
}

const EditFormModal = ({ toggleModal, updateData, formSelected }: Props) => {
  const currentAttLength = 140
  const { getCardList, getCategoryList, getAttributeList, editForm } = useAdmFormContext()
  const { isLoadingCategories, categoryOptions } = useGetCategories({ getCategoryList })
  const { isLoadingAttributes, attributeOptions, attributeOptionsAll } = useGetAttributes({
    getAttributeList
  })
  const { isLoadingCardList, cardList, getCardsData } = useGetCardList({ getCardList })

  const methods = useForm<IFormInput>({ defaultValues: getDefaultValues({ formSelected }) })
  const { handleError } = useHandleError()

  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<FormI>({} as FormI)
  const [cardOptions, setCardOptions] = useState<OptionSelect[]>([])

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: methods.getValues('attributes'),
        attrsReused: methods.getValues('attReused')
      })

      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        isEnable: true,
        category: methods.getValues('categories'),
        attributes: [...(await result)] ?? [],
        color: '',
        parentName: '',
        articles: [],
        parentCard: ''
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const checkDataChanges = (dto: CreateFormProps): boolean => {
    const originalData = {
      title: formSelected.title,
      isEnable: formSelected.isEnable,
      categories: formSelected.category,
      associatedCard: formSelected.associatedCard,
      attributes: formSelected.attributes
    }

    return JSON.stringify(originalData) === JSON.stringify(dto)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    try {
      setIsLoading(true)
      const _attributes = removeOptionsId(data.attributes)
      const dto: CreateFormProps = {
        title: data.title,
        isEnable: formSelected.isEnable,
        categories: data.categories ? [data.categories] : null,
        associatedCard: data.associatedCard || null,
        attributes: [...data.attReused, ..._attributes]
      }

      const isEqual = checkDataChanges(dto)
      if (isEqual) {
        toast.warn('Nada a ser editado!')
        toggleModal()
        return
      }

      await editForm.put(formSelected.id, dto)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (!isLoadingCardList && cardList.length > 0) {
      const result = buildCardOptions(cardList)
      setCardOptions(result)
    }
  }, [isLoadingCardList, cardList])

  useEffect(() => {
    getCardsData()
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar fomulário"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 1,
              name: 'title',
              question: 'Digite o título do formulário:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <SelectField
            attribute={{
              id: 2,
              name: 'categories',
              question: 'Selecione a categoria do formulário:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={categoryOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingCategories}
            defaultValue={[findCategoryOpt(formSelected?.category?.id ?? '', categoryOptions)]}
          />

          <SelectField
            attribute={{
              id: 3,
              name: 'associatedCard',
              question: 'Selecione o card ao qual estará associado o formulário:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={cardOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingCardList}
            defaultValue={
              formSelected.associatedCard ? [
                {
                  label: formSelected?.ancestorsCardTreeName ?? '',
                  value: {
                    id: formSelected.associatedCard?.id as number,
                    title: formSelected.associatedCard?.name ?? ''
                  }
                }
              ] : undefined
            }
          />

          <SelectField
            attribute={{
              id: 4,
              name: 'attReused',
              question: 'Selecione as perguntas deseja reaproveitar/remover:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={attributeOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingAttributes}
            isMulti={true}
            defaultValue={findAttributeOptions(formSelected.attributes, attributeOptionsAll)}
          />

          <AdmFieldList
            defaultValue={{
              name: `question${currentAttLength + 1}`,
              question: '',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              type: 'text',
              positionOrder: 1,
              placeholder: null,
              isMulti: null,
              columnsLabel: null,
              isVisibleToDescription: true,
              maxLength: 25000,
              fileFormats: null
            }}
            currentAttLength={currentAttLength}
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && (
          <PreviewFormModal toggleModal={togglePreviewModal} form={previewContent} />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default EditFormModal
