import { useEffect, useState } from 'react'
import { OptionSelect } from 'presentation/components/Select/Select'
import { CategoryModel } from 'domain/models'
import { GetCategoryList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getCategoryList: GetCategoryList
}

export const useGetCategories = ({ getCategoryList }: Props) => {
  const [isLoadingCategories, setIsLoadingCategories] = useState(false)
  const [categories, setCategories] = useState<CategoryModel[] | []>([])
  const [categoryOptions, setCategoryOptions] = useState<OptionSelect[]>([])
  const { handleError } = useHandleError()

  const formattedCategoryOptions = (list: CategoryModel[]) => {
    const _categoryOptions: OptionSelect[] = []
    list.forEach((category) => {
      _categoryOptions.push({
        label: category.name,
        value: { id: category.id as number, title: category.name }
      })
    })
    return _categoryOptions
  }

  async function getCategoriesData () {
    try {
      setIsLoadingCategories(true)
      const response = await getCategoryList.get()

      if (response.length > 0) {
        const _categoryOptions = formattedCategoryOptions(response)
        setCategoryOptions(_categoryOptions)
      }
      setCategories(response)
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoadingCategories(false)
    }
  }

  useEffect(() => {
    getCategoriesData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoadingCategories,
    categories,
    categoryOptions
  }
}
