import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  UnexpectedError
} from 'domain/errors'
import { DeleteAdmin } from 'domain/usecases'

export class RemoteDeleteAdmin implements DeleteAdmin {
  constructor (
    private readonly httpClient: HttpClient<void>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async delete (id: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'DELETE',
      headers: {
        Authorization: this.token
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        return
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao remover os dados do administrador')
    }
  }
}
