import { useEffect } from 'react'
import Carousel from 'nuka-carousel'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGetNews, useWindowDimensions } from 'presentation/hooks'
import { getShowNewsFlagAdapter, setShowNewsFlagAdapter } from 'main/adapters'
import { GetNewsList } from 'domain/usecases'

import { ContainerNews } from './styles'

type Props = {
  getNews: GetNewsList
}

const News = ({ getNews }: Props) => {
  const { news, isLoading, getNewsData } = useGetNews({ getNews })
  const { width } = useWindowDimensions()
  const isShowNews = getShowNewsFlagAdapter() === 'true'

  useEffect(() => {
    if (isShowNews) {
      getNewsData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {!isLoading && news.length > 0 && isShowNews && (
        <ContainerNews>
          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setShowNewsFlagAdapter('false')
              window.location.reload()
            }}
          >
            <FontAwesomeIcon icon={faMinus} />
          </button>
          <div className="wrapper-news-content">
            <h2 className="title">
              Avisos <span>importantes!</span>
            </h2>
            <Carousel
              renderCenterLeftControls={null}
              renderCenterRightControls={null}
              autoplay={true}
              autoplayInterval={10000}
              wrapAround={true}
            >
              {news.map((item) => (
                <div key={item.id} className="carousel-item">
                  <div className="text" dangerouslySetInnerHTML={{ __html: item.description }} />
                  {width > 768 &&
                    (
                      <div className="news-item__img">
                        <img src={item.imageUrlData} alt="preview da imagen" loading="lazy" />
                      </div>
                    )}
                </div>
              ))}
            </Carousel>
          </div>
        </ContainerNews>
      )}
    </>
  )
}

export default News
