import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ModalContent } from 'presentation/components'
import { EditorContentField, SelectField, TextField } from 'presentation/components/Fields'
import { FormAssociatedModel } from 'domain/models'
import { getUrlsImage, replaceBase64ByUrl } from './utils'
import { useAdmFaqContext } from 'presentation/context'
import { EditorImageProps } from '../Fields/EditorContentField'
import { useGetFormOptions, useHandleError } from 'presentation/hooks'
import { CreateFaqDto } from 'domain/usecases'

import { ModalBodyForm } from 'presentation/components/Modal/styles'
import { customFormSelectStyles } from '../Select/styles'

export interface IFormInput {
  formsAssociated: FormAssociatedModel[]
  content: string
  title: string
}

type Props = {
  toggleModal: () => void
  updateData: () => void
}

const AddFaqModal = ({ toggleModal, updateData }: Props) => {
  const methods = useForm<IFormInput>()
  const { saveMultipleFiles, deleteMultipleFiles, getFormList, createFaq } = useAdmFaqContext()
  const { isLoadingFormOptions, formOptions, getFormListOptions } = useGetFormOptions({
    getFormList
  })
  const { handleError } = useHandleError()

  const [isLoading, setIsLoading] = useState(false)
  const [images, setImages] = useState<EditorImageProps[]>([])
  const [files, setFiles] = useState<File[]>([])

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    setIsLoading(true)
    let urlList = []
    const list: string[] = []

    try {
      urlList = await getUrlsImage({ files, saveMultipleFiles })
      urlList.forEach((el) => list.push(el.url))
      const newContent = replaceBase64ByUrl({ images, content: data.content, urlList })

      const dto: CreateFaqDto = {
        title: data.title,
        content: newContent || data.content,
        files: list,
        formsAssociated: data.formsAssociated
      }

      await createFaq.post(dto)
      updateData()
    } catch (error: any) {
      if (urlList.length > 0) {
        await deleteMultipleFiles.all(list)
      }
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    getFormListOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Nova FAQ"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <SelectField
            attribute={{
              id: 0,
              name: 'formsAssociated',
              question: 'Qual formulário esta Faq estará relacionada?',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...'
            }}
            options={formOptions}
            isLoadingData={isLoadingFormOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isMulti={true}
          />

          <TextField
            attribute={{
              id: 1,
              name: 'title',
              question: 'Adicione o título:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: 'Adicione o título...'
            }}
          />
          <EditorContentField
            fieldName="content"
            validations={{
              required: {
                value: true,
                message: '* Campo obrigatório'
              }
            }}
            images={images}
            setImages={setImages}
            files={files}
            setFiles={setFiles}
            label="Escreva o conteúdo da faq"
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default AddFaqModal
