import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  BadRequestError,
  ConflictError,
  InvalidCredentialsError,
  UnexpectedError
} from 'domain/errors'
import { DeleteFaq } from 'domain/usecases'

export class RemoteDeleteFaq implements DeleteFaq {
  constructor (
    private readonly httpClient: HttpClient<void>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async delete (id: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      headers: { Authorization: this.token },
      method: 'DELETE'
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      case HttpStatusCode.conflict:
        throw new ConflictError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao remover a FAQ')
    }
  }
}
