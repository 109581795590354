import { RemoteCustomerUserAuthentication } from 'data/usecases'
import { Authentication, CustomerUserAuthentication } from 'domain/usecases'
import { CustomerAuthenticationAdapter } from 'main/adapters'
import { makeAuthApiUrl, makeAxiosHttpClient } from 'main/factories/http'

export const makeRemoteCustomerAuthentication = (): CustomerUserAuthentication =>
  new RemoteCustomerUserAuthentication(makeAxiosHttpClient(), makeAuthApiUrl('/user/authenticate'))

export const makeUserAuthentication = (): Authentication =>
  new CustomerAuthenticationAdapter(makeRemoteCustomerAuthentication())
