import { ButtonHTMLAttributes } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CardIcon, CardItem, CardLabelColor } from './styles'
import { CardModel } from 'domain/models'

interface Props extends ButtonHTMLAttributes<HTMLElement> {
  card: CardModel
}

const Card = ({ card, ...props }: Props) => {
  return (
    <CardItem $colorLabel={card.color} {...props}>
      <CardLabelColor $colorLabel={card.color} />
      <div className="card-information">
        <CardIcon>
          <FontAwesomeIcon icon={(card.icon as IconProp) ?? 'fa-solid fa-gear'} />
        </CardIcon>
        <p className="card-title">{card.name}</p>
      </div>
    </CardItem>
  )
}

export default Card
