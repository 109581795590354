import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  NotFoundError,
  UnexpectedError
} from 'domain/errors'
import { CustomerTicketModel } from 'domain/models'
import { GetTicketDto, CustomerGetTicket } from 'domain/usecases'

export class RemoteCustomerGetTicket implements CustomerGetTicket {
  constructor (
    private readonly httpClient: HttpClient<CustomerTicketModel>,
    private readonly url: string
  ) {}

  async get (dto: GetTicketDto): Promise<CustomerTicketModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'POST',
      body: {
        called_id: dto.ticketId
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body?.ticket_id) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao recuperar o chamado')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError('Usuário não é o solitante do chamado')
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      case HttpStatusCode.notFound:
        throw new NotFoundError('Chamado não encontrado')
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao recuperar o chamado.')
    }
  }
}
