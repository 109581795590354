import { RemoteCustomerSearchUserByName } from 'data/usecases'
import { SearchUserByName, CustomerSearchUserByName } from 'domain/usecases'
import { CustomerSearchUserByNameAdapter } from 'main/adapters'
import { makeAxiosHttpClient, makeConnectorApiUrl } from 'main/factories/http'

export const makeRemoteCustomerSearchUserByName = (): CustomerSearchUserByName =>
  new RemoteCustomerSearchUserByName(makeAxiosHttpClient(), makeConnectorApiUrl('/user/show-by-name'))

export const makeSearchUserByName = (): SearchUserByName =>
  new CustomerSearchUserByNameAdapter(makeRemoteCustomerSearchUserByName())
