import styled from 'styled-components'
import { breakQueriesMax } from '../../styles/Breakpoints'

export const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;
  gap: 0.65rem;
`

export const WrapperFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;

  /* input[type='text'] {
    border-radius: 0px;
    padding: 0 0.5rem;
    font-weight: bold;
    font-size: 0.875rem !important;
    &::placeholder {
      font-weight: bold;
      font-style: normal;
      color: ${({ theme }) => theme.colors.black};
      opacity: 1;
      font-size: 0.75rem;
    }
  }

  input[type='date'] {
    border-radius: 0px;
    padding: 0 0.5rem;
    font-weight: bold;
    font-size: 0.875rem;
  } */

  .search_ticket {
    display: flex;
    justify-content: center;
    align-items: center;
    /* position: relative; */
    input[type='search'] {
      min-width: 15rem;
      border-radius: 0px;
      padding: 0 0.5rem;
      font-weight: bold;
      font-size: 0.875rem;
      &::placeholder {
        font-weight: bold;
        font-style: normal;
        color: ${({ theme }) => theme.colors.black};
        opacity: 1;
        font-size: 0.75rem;
      }
    }

    button {
      background-color: inherit;
      border: none;
      width: 1.35rem;
      height: 1.35rem;
      margin-left: -1.65rem;
      cursor: pointer;
      svg {
        width: 1rem;
        height: 1rem;
        color: ${({ theme }) => theme.colors.black};

        &:hover {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }
`

const labelStatusWidth = '8rem'
const labelStatusHeight = '2.35rem'

export const WrapperTicket = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 0;
  height: ${labelStatusWidth};
  min-height: ${labelStatusWidth};
  max-height: ${labelStatusWidth};

  width: 100%;
  gap: 1rem;

  border: 1px solid ${({ theme }) => theme.colors.gray._300};
  border-left: none;

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 0;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      min-width: ${labelStatusWidth};
      max-width: ${labelStatusWidth};
      width: ${labelStatusWidth};

      height: ${labelStatusHeight};
      max-height: ${labelStatusHeight};
      min-height: ${labelStatusHeight};

      font-size: 0.875rem;
      color: ${({ theme }) => theme.colors.white};
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin: 0;

      margin-left: ${labelStatusHeight};

      transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
    }
  }

  .wrapper-info-ticket {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
    gap: 1rem !important;
    margin-left: 1.5rem ;

    @media (max-width: 768px) {
      padding: 0 1rem 0 ${labelStatusHeight};
    }



    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      width: 100%;

      .ticket_id {
        width: fit-content;
        height: fit-content;
        background-color: ${({ theme }) => theme.colors.gray._400};
        color: ${({ theme }) => theme.colors.white};
        font-weight: bold;
        font-size: 0.875rem;
        padding: 0.25rem 1rem;
        border-radius: 0.875rem;
        margin-left: -0.75rem;
      }

      .ticket_summary {
        display: flex;
        align-items: start;
        /* justify-content: space-between; */
        gap: 1rem;
        width: 100%;

        label {
          font-size: 0.875rem;
          color: ${({ theme }) => theme.colors.gray._500};
          ${breakQueriesMax(900)} {
            font-size: 0.75rem !important;
          }
    }
        }

        p {
          font-weight: bold;
          color: ${({ theme }) => theme.colors.black};
          ${breakQueriesMax(900)} {
            font-size: 0.75rem !important;
          }
        }

        .summary_text {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
        }

        /* .category {
          width: 30%;
        } */

        .summary {
          width:70%;
        }

        .date {
          text-align: center;
          width: 30%;
        }
      }
    }

    .btn-show-ticket {
      width: fit-content;
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.75rem;
        height: 1.75rem;
        background-color: ${({ theme }) => theme.colors.black};
        /* padding: 0.25rem 0.35rem; */
        border: none;
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
        transition: all 0.2s ease;
        cursor: pointer;

        svg {
          font-size: 1.25rem;
        }

        &:hover {
          opacity: 0.8;
          svg {
            font-size: 1.5rem;
          }
        }
      }
    }

`

export const WrapperHeaderTickets = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
`

export const ContainerTitle = styled.div<{ $fontSize?: string, $fontColor?: string }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.$fontColor ?? props.theme.colors.gray._700};
  font-weight: bold;
  font-size: ${(props) => props.$fontSize ?? '0.9rem'};

  span {
    background-color: ${(props) => props.$fontColor ?? props.theme.colors.gray._700};
    height: 2px;
    height: ${(props) => (props.$fontSize ? '2px' : '3px')};
    width: 2.5rem;
    width: ${(props) => (props.$fontSize ? '2.5rem' : '3rem')};
  }
`
