import { IGetUserInfo, CustomerSearchUserModel } from 'domain/models'
import { SearchUserByName, CustomerSearchUserByName } from 'domain/usecases'

export class CustomerSearchUserByNameAdapter implements SearchUserByName {
  constructor (private readonly customerSearchUserByName: CustomerSearchUserByName) {}

  async get (value: string): Promise<IGetUserInfo[]> {
    const response = await this.customerSearchUserByName.get(value)

    if (response) {
      const newList: IGetUserInfo[] = []
      if (response.users.length > 0) {
        response.users.forEach((user: CustomerSearchUserModel) =>
          newList.push({
            id: user.user_id.toString(),
            fullName: user.user_full_name,
            login: user.user_login
          })
        )
      }

      return newList
    }

    return response
  }
}
