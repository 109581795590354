import { createContext, ReactNode, useContext } from 'react'
import {
  DeletePrivateFile,
  GetForm,
  PostTicket,
  SaveFile,
  SearchUserByName
} from 'domain/usecases'
import {
  makeDeletePrivateFile,
  makeGetForm,
  makePostPrivateFile,
  makePostTicket,
  makeSearchUserByName
} from 'main/factories/usecases'
import { HttpClientAll } from 'data/protocols'
import { makeAxiosAllSavePrivateFiles } from 'main/factories/http'
import { makeSearchLocation } from 'main/factories/usecases/user/search-location'
import { SearchLocation } from 'domain/usecases/user/search-location'

type PropsContext = {
  getForm: GetForm
  postTicket: PostTicket
  saveFile: SaveFile
  deletePrivateFile: DeletePrivateFile
  searchUserByName: SearchUserByName
  searchLocation: SearchLocation
  saveAllPrivateFile: HttpClientAll
}

export const TicketFormContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const TicketFormContextProvider = ({ children }: Props): any => {
  return (
    <TicketFormContext.Provider
      value={{
        getForm: makeGetForm(),
        postTicket: makePostTicket(),
        saveFile: makePostPrivateFile(),
        deletePrivateFile: makeDeletePrivateFile(),
        searchUserByName: makeSearchUserByName(),
        searchLocation: makeSearchLocation(),
        saveAllPrivateFile: makeAxiosAllSavePrivateFiles()
      }}
    >
      {children}
    </TicketFormContext.Provider>
  )
}

export const useTicketFormContext = () => {
  return useContext(TicketFormContext)
}
