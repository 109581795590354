import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'presentation/components'
import { FaqModel } from 'domain/models'
import { useAdmFaqContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyDelete } from 'presentation/components/Modal/styles'

type Props = {
  toggleModal: () => void
  faqSelected: FaqModel
  updateData: () => void
}

const DeleteFaqModal = ({ toggleModal, faqSelected, updateData }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const { deleteMultipleFiles, deleteFaq } = useAdmFaqContext()
  const { handleError } = useHandleError()

  const handleDelete = async (): Promise<void> => {
    setIsLoading(true)
    try {
      await deleteFaq.delete(faqSelected.id.toString())

      if (faqSelected.files && faqSelected?.files?.length > 0) {
        await deleteMultipleFiles.all(faqSelected.files)
      }
      updateData()
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover Faq"
      handleCloseModal={toggleModal}
      handleSubmit={handleDelete}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#000000"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover esta FAQ?</p>
        </div>
        <div className="text">
          <p>{faqSelected.title}</p>
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteFaqModal
