import { OptionSelect } from 'presentation/components/Select/Select'
import SelectAdmField from './Select'
import { InputType } from 'domain/models'

export const typeFieldOptions: OptionSelect[] = [
  { label: 'Texto curto', value: 'text', icon: 'fa-solid fa-grip-lines' },
  { label: 'Paragrafo', value: 'textarea', icon: 'fa-solid fa-align-justify' },
  { label: 'Seletor', value: 'select', icon: 'fa-regular fa-hand-pointer' },
  { label: 'Checkboxes', value: 'checkbox', icon: 'fa-regular fa-square-check' },
  { label: 'Arquivo', value: 'file', icon: 'fa-regular fa-file' },
  { label: 'Telefone', value: 'phone', icon: 'fa-solid fa-phone' },
  { label: 'E-mail', value: 'email', icon: 'fa-regular fa-envelope' },
  { label: 'CPF', value: 'CPF', icon: 'fa-regular fa-address-card' }
]

export function renderContent (type: InputType, index: number) {
  switch (type) {
    // case 'text':
    //   return <TextAdmField index={index} />
    // case 'textarea':
    //   return <TextAdmField index={index} />
    // case 'file':
    //   return <FileAdmField index={index} />
    case 'select':
      return <SelectAdmField index={index} />
    case 'checkbox':
      return <SelectAdmField index={index} />
    default:
      return null
  }
}
