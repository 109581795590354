import styled from 'styled-components'

export const paddingX = '3rem'
export const borderRadius = '2rem'
export const paddingY = '2rem'

export const WrapperSectionContent = styled.section<{ $minHeight?: string, $paddingX?: string }>`
  width:100%;
  min-height: ${(props) => props.$minHeight ?? '10rem'};
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${borderRadius};
  padding-top: ${paddingY};
  padding-bottom: ${paddingY};
  padding-left: ${(props) => props.$paddingX ?? `${paddingX}`};
  padding-right: ${(props) => props.$paddingX ?? `${paddingX}`};
  box-shadow: 0px 1px 4px ${({ theme }) => theme.colors.gray._800};
`

export const WrapperContentPage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .loading, .message {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
