import axios, { AxiosResponse } from 'axios'
import { HttpClient, HttpRequest, HttpResponse } from 'data/protocols'

export class AxiosHttpClient implements HttpClient {
  async request (dto: HttpRequest): Promise<HttpResponse> {
    let httpResponse: AxiosResponse
    try {
      httpResponse = await axios.request({
        url: dto.url,
        method: dto.method,
        headers: dto.headers,
        params: dto.params,
        data: dto.body,
        withCredentials: dto.withCredentials
      })
    } catch (error: any) {
      httpResponse = error?.response
    }
    return {
      statusCode: httpResponse?.status,
      body: httpResponse?.data
    }
  }
}
