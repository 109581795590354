import { TicketFormContextProvider } from 'presentation/context'
import { TicketForm } from 'presentation/pages'

const makeTicketForm = () => {
  return (
    <TicketFormContextProvider>
      <TicketForm />
    </TicketFormContextProvider>
  )
}

export default makeTicketForm
