import {
  Authentication,
  AuthenticationDto,
  CustomerUserAuthentication,
  IAuthenticationResult
} from 'domain/usecases'

export class CustomerAuthenticationAdapter implements Authentication {
  constructor (private readonly customerAuthentication: CustomerUserAuthentication) {}

  async auth (dto: AuthenticationDto): Promise<IAuthenticationResult> {
    const response = await this.customerAuthentication.auth(dto)
    if (response) {
      return {
        name: response.user_full_name,
        login: response.user_name,
        email: response.user_email
      }
    }
    return response
  }
}
