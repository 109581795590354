import { HttpClient, HttpStatusCode } from 'data/protocols'
import { AccessDeniedError, InvalidParamsError, UnexpectedError } from 'domain/errors'
import { Base64, GetFileBase64 } from 'domain/usecases'

export class RemoteGetFileBase64 implements GetFileBase64 {
  constructor (
    private readonly httpClient: HttpClient<Base64>,
    private readonly url: string
  ) {}

  async get (value: string): Promise<Base64> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${value}`,
      method: 'GET'
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao fazer download')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao fazer download')
    }
  }
}
