/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ModalContent } from 'presentation/components'
import { SelectField, TextField } from 'presentation/components/Fields'
import {
  useGetAttributes,
  useGetCardList,
  useGetCategories,
  useHandleError
} from 'presentation/hooks'
import { IAttributeProps } from './AdmFields/AdmFieldList'
import { AdmFieldList } from './AdmFields'
import PreviewFormModal from './PreviewFormModal'
import { buildCardOptions, removeOptionsId, setAttributeToShow, getDefaultValues } from './utils'
import { OptionSelect } from 'presentation/components/Select/Select'
import { FormI } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'

import { ModalBodyForm, ModalWrapper } from 'presentation/components/Modal/styles'
import { customFormSelectStyles } from '../Select/styles'
import { CreateFormProps } from 'domain/usecases'

export interface IFormInput {
  title: string
  categories: any
  associatedCard: any
  attributes: IAttributeProps[] | []
  attReused: any[] | []
}

type Props = {
  toggleModal: () => void
  updateData: () => void
}

const AddFormModal = ({ toggleModal, updateData }: Props) => {
  const currentAttLength = 140

  const methods = useForm<IFormInput>({ defaultValues: getDefaultValues({ currentAttLength }) })
  const { getCardList, getCategoryList, getAttributeList, createForm } = useAdmFormContext()
  const { isLoadingCategories, categoryOptions } = useGetCategories({ getCategoryList })
  const { isLoadingAttributes, attributeOptions } = useGetAttributes({ getAttributeList })
  const { isLoadingCardList, cardList, getCardsData } = useGetCardList({ getCardList })
  const { handleError } = useHandleError()

  const refPreviewModal = useRef<HTMLDialogElement>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false)
  const [previewContent, setPreviewContent] = useState<FormI>({} as FormI)
  const [cardOptions, setCardOptions] = useState<OptionSelect[]>([])

  const togglePreviewModal = async () => {
    if (refPreviewModal.current) {
      if (isOpenPreviewModal) {
        setIsOpenPreviewModal(false)
        refPreviewModal?.current.close()
        return
      }

      const result = setAttributeToShow({
        attrsCreated: methods.getValues('attributes'),
        attrsReused: methods.getValues('attReused')
      })

      setPreviewContent({
        id: 1,
        title: methods.getValues('title'),
        isEnable: true,
        category: methods.getValues('categories'),
        attributes: [...(await result)] ?? [],
        color: '',
        parentName: '',
        articles: [],
        parentCard: ''
      })
      setIsOpenPreviewModal(true)
      refPreviewModal?.current.showModal()
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    try {
      setIsLoading(true)
      const _attributes = removeOptionsId(data.attributes)
      const dto: CreateFormProps = {
        title: data.title,
        isEnable: true,
        categories: data.categories ? [data.categories] : null,
        associatedCard: data.associatedCard || null,
        attributes: [...data.attReused, ..._attributes]
      }

      await createForm.post(dto)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  useEffect(() => {
    if (!isLoadingCardList && cardList.length > 0) {
      const result = buildCardOptions(cardList)
      setCardOptions(result)
    }
  }, [isLoadingCardList, cardList])

  useEffect(() => {
    getCardsData()
  }, [])

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Novo fomulário"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        handleOpenModal={togglePreviewModal}
        btn2Text="Visualizar"
      >
        <ModalBodyForm>
          <TextField
            attribute={{
              id: 1,
              name: 'title',
              question: 'Digite o título do formulário:',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false
            }}
          />
          <SelectField
            attribute={{
              id: 2,
              name: 'categories',
              question: 'Selecione a categoria do formulário:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={categoryOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingCategories}
          />
          <SelectField
            attribute={{
              id: 3,
              name: 'associatedCard',
              question: 'Selecione o card ao qual estará associado o formulário:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={cardOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingCardList}
          />

          <SelectField
            attribute={{
              id: 4,
              name: 'attReused',
              question: 'Selecione as perguntas que deseja reaproveitar:',
              optionsSelectElement: [],
              required: false,
              isHiddenToVip: false
            }}
            options={attributeOptions}
            menuPlacement={'bottom'}
            customSelectStyles={customFormSelectStyles}
            isLoadingData={isLoadingAttributes}
            isMulti={true}
          />

          <AdmFieldList
            defaultValue={getDefaultValues({ currentAttLength }).attributes[0]}
            currentAttLength={currentAttLength}
          />
        </ModalBodyForm>
      </ModalContent>
      <ModalWrapper ref={refPreviewModal}>
        {isOpenPreviewModal && (
          <PreviewFormModal toggleModal={togglePreviewModal} form={previewContent} />
        )}
      </ModalWrapper>
    </FormProvider>
  )
}

export default AddFormModal
