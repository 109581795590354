import styled from 'styled-components'
import { borderRadius, paddingX, paddingY } from 'presentation/styles/commonStyles'

export const TicketStatus = styled.span<{ $statusColor: string }>`
  font-size: 0.75rem;
  font-weight: bold;
  color: ${(props) => props.$statusColor ?? props.theme.colors.gray._500};
`

export const WrapperTicketContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  margin-bottom: 1rem;

  .bottom_info {
    display: flex;
    flex-direction: column;
    .text {
      text-align: left;
      font: normal normal normal 1rem Montserrat;
      padding-top: 1rem;
    }

    @media (max-width: 1280px) {
      .text {
        font: normal normal normal 0.875rem Montserrat;
      }
    }
  }
`

export const WrapperTopContent = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: ${borderRadius};
  padding-top: ${paddingY};
  padding-bottom: ${paddingY};
  padding-left: ${paddingX};
  min-height: fit-content;
  width: 100%;
  gap: 1rem;
  box-shadow: 0px 1px 4px ${({ theme }) => theme.colors.gray._800};

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.5rem;
    .summary_technician {
      align-self: flex-end;
    }
  }

  .wrapper_ticket_info {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .category {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .category_name {
        text-align: left;
        font: normal normal bold 0.875rem Montserrat;
        color: ${({ theme }) => theme.colors.gray._500};
      }
    }
  }

  .summary_technician {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    width: fit-content;
    height: fit-content;
    padding: 1rem 2rem;
    /* padding: 1rem 0.75rem; */
    background: ${({ theme }) => theme.colors.gray._300} 0% 0% no-repeat padding-box;
    border-radius: 56px 0px 0px 56px;
    opacity: 1;

    @media (max-width: 768px) {
      padding: 0.35rem 0.75rem 0.35rem 1.5rem;
    }

    .technician_photo {
      background-color: ${({ theme }) => theme.colors.white};

      img {
        background-color: ${({ theme }) => theme.colors.white};
      }

      svg {
        color: ${({ theme }) => theme.colors.gray._300};
      }
    }
    .technician_info {
      display: flex;
      flex-direction: column;
      gap: 0.15rem;

      p {
        font-size: 0.870rem;
        color: ${({ theme }) => theme.colors.black};
        font-weight: bold;
      }

      label {
        font-size: 0.75rem;
      }

      span {
        font-size: 1rem;
        font-weight: bold;
      }

      .technician_ca{
        font-size: 0.8rem;
        color: ${({ theme }) => theme.colors.blue._400};
        font-weight: bold;
      }
    }
  }
`

export const Information = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;

  @media (max-width: 640px) {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 0.5rem;
  }

  .wrapper {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;

    .line {
      display: flex;
      width: 2px;
      height: 3rem;
      border: 2px solid ${({ theme }) => theme.colors.black};
      opacity: 1;
    }
  }

  .status,
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;

    label {
      text-align: left;
      font: normal normal normal 0.875rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._500};
    }

    span {
      text-align: left;
      font: normal normal bold 1rem Montserrat;
      text-transform: uppercase;
    }
  }

  .info {
    span:nth-child(2) {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  @media (max-width: 1536px) {
    .line {
      height: 3rem;
    }
  }

  @media (max-width: 1380px) {
    .line {
      height: 2.8rem;
    }
  }

  @media (max-width: 1280px) {
    .line {
      height: 2.5rem;
    }
    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.75rem Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 0.875rem Montserrat;
      }
    }
  }

  @media (max-width: 1024px) {
    .line {
      height: 2.375rem;
    }
    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.75rem Montserrat;
      }
      span:nth-child(2) {
        font: normal normal bold 0.75rem Montserrat;
      }
    }
  }

  @media (max-width: 768px) {
    .line {
      height: 2.2rem;
    }
    .status,
    .info {
      span:nth-child(1) {
        font: normal normal normal 0.75rem Montserrat;
      }
    }
  }
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  margin-top: 1rem;

  .response {
    text-align: left;
    font: normal normal normal 1rem Montserrat;
    letter-spacing: 0px;
    opacity: 1;
    white-space: pre-wrap;
  }

  @media (max-width: 1280px) {
    .response {
      font: normal normal normal 0.875rem Montserrat;
    }
  }
`

export const LabelContent = styled.div`
  text-align: left;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.black};
  letter-spacing: 0px;
  opacity: 1;
  font: normal normal bold 1.125rem Montserrat;

  @media (max-width: 1280px) {
    .text {
      font: normal normal normal 1rem Montserrat;
    }
  }
`

export const WrapperFollowup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-top: 1rem;
  width: 100%;
  height: fit-content;

  .followup-info {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .followup-info__date,
    .followup-info__name {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    svg {
      font-size: 0.5rem;
      margin-right: 0.5rem;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .create-info {
      text-align: left;
      font: normal normal bold 1rem Montserrat;
      color: ${({ theme }) => theme.colors.gray._600};
    }

    .data-followup {
      font: normal normal bold 1rem Montserrat;
      color: ${({ theme }) => theme.colors.black};
    }
  }

  .followup-description {
    margin-left: 1.5rem;
    margin-top: 0.5rem;

    .followup-description__text {
      font: normal normal normal 1rem Montserrat;
      white-space: pre-wrap;
    }

    .followup-description__file {
      display: flex;
      span {
        font: normal normal normal 1rem Montserrat;
      }

      .file {
        display: flex;
        gap: 1rem;
        button {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: ${({ theme }) => theme.colors.blue._400};
          font: normal normal normal 1rem Montserrat;
          cursor: pointer;
          margin-left: 1rem;

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .followup-info,
    .followup-description {
      .create-info,
      .data-followup {
        font: normal normal bold 0.875rem Montserrat;
      }
      span {
        font: normal normal normal 0.875rem Montserrat;
      }
    }
  }

  @media (max-width: 550px) {
    .followup-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .followup-info__name {
        margin-left: 1.5rem;
      }
    }
  }
`

export const BtnViewMore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;

  button {
    background-color: transparent;
    border: none;
    color: ${({ theme }) => theme.colors.blue._300};
    transition: 0.2s;
    cursor: pointer;

    span {
      margin-right: 0.25rem;
      border: 2px solid ${({ theme }) => theme.colors.blue._300};
      padding: 0.15rem 0.25rem;
      border-radius: 50%;

      svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    &:hover {
      opacity: 0.9;
      font-weight: bold;
    }
  }
`

export const WrapperFollowupList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 25rem;
  width: 100%;
  padding-right: 0.5rem;
  overflow-y: scroll;
`

export const FormUpdateTicket = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.gray._700};
  gap: 1rem;

  label {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
`
