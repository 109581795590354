import { useState } from 'react'
import { INews } from 'domain/models'
import { GetNewsList, UpdateOrderNews, UpdateOrderNewsProps } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getNews: GetNewsList
  updateOrderNews?: UpdateOrderNews
}

export const useGetNews = ({ getNews, updateOrderNews }: Props) => {
  const [news, setNews] = useState<INews[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { handleError } = useHandleError()

  const setOrderNewsList = async (newListReordered: INews[]) => {
    if (newListReordered.length > 0 && updateOrderNews) {
      setIsLoading(true)
      const newList: UpdateOrderNewsProps[] = []
      newListReordered.forEach((item, index) => newList.push({ id: item.id, weight: index }))
      try {
        const response = await updateOrderNews.put(newList)
        if (!response) {
          throw new Error()
        }
        getNewsData()
      } catch (error) {
        handleError(error)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const getNewsData = async () => {
    try {
      setIsLoading(true)
      // const response = mock
      const response = await getNews.get()
      if (response.length > 0) {
        const newsListContent: INews[] = []
        response.forEach((news) => {
          newsListContent.push({
            id: news.id,
            weight: news.weight,
            description: news.description,
            imageUrl: news.url_picture,
            imageUrlData: news.url_picture ?? ''
          })
        })
        setNews(newsListContent)
      }
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    news,
    isLoading,
    setOrderNewsList,
    getNewsData
  }
}
