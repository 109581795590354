import { useNavigate } from 'react-router-dom'

export const useLogout = () => {
  const navigate = useNavigate()
  const logout = () => {
    localStorage.clear()
    sessionStorage.clear()
    navigate('/login')
  }
  return {
    logout
  }
}
