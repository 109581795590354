import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  AccessDeniedError,
  InvalidCredentialsError,
  InvalidParamsError,
  UnexpectedError
} from 'domain/errors'
import { UserAdminModel } from 'domain/models'
import { EditAdmin, EditAdminProps } from 'domain/usecases'

export class RemoteEditAdmin implements EditAdmin {
  constructor (
    private readonly httpClient: HttpClient<UserAdminModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async put (values: EditAdminProps): Promise<UserAdminModel> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${values.id}`,
      method: 'put',
      headers: {
        Authorization: this.token
      },
      body: values.dto
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao editar os dados do administrador')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao editar os dados do administrador')
    }
  }
}
