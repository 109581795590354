import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ModalContent } from 'presentation/components'
import { FileManagerModel, IAttachments, ITicket, IUpdateTicket } from 'domain/models'
import { getCurrentAccountAdapter } from 'main/adapters'
import { useTicketDetailsContext } from 'presentation/context'
import { DeletePrivateFileProps } from 'domain/usecases'
import { FileField, TextareaField } from 'presentation/components/Fields'
import { useHandleError } from 'presentation/hooks'

import { FormUpdateTicket } from './styles'

type Props = {
  ticket: ITicket
  toggleModal: () => void
  updateData: () => void
}

const UpdateTicketModal = ({ ticket, toggleModal, updateData }: Props) => {
  const methods = useForm()
  const { updateTicketShowField, updateTicket, deletePrivateFile, saveAllPrivateFile } =
    useTicketDetailsContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const getUrlFile = async (files: File[]) => {
    const attachments: IAttachments[] = []
    if (files) {
      const response = await saveAllPrivateFile.all(files)
      response.forEach((file: FileManagerModel[]) => {
        attachments.push({
          url: file[0].name,
          name: file[0].originalname,
          token: file[0].tokenFile ?? ''
        })
      })
    }
    return attachments
  }

  const onSubmit = async (data: Record<string, string | File[]>) => {
    setIsLoading(true)
    let attachments: IAttachments[] = []
    try {
      attachments = await getUrlFile(data.file as File[])

      const dto: IUpdateTicket = {
        userLogin: getCurrentAccountAdapter()?.login ?? '',
        description: data.description as string,
        attachments,
        ticketId: ticket.id.toString(),
        ticketStatus: ticket.status,
        entities_id: ticket.entities_id ?? 0
      }

      await updateTicket.update(dto)
      updateData()
    } catch (error: any) {
      if (attachments && attachments.length > 0) {
        const urlList: DeletePrivateFileProps[] = []
        attachments?.forEach((el) => urlList.push({ name: el.url }))
        await deletePrivateFile.delete(urlList)
      }
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Atualizar chamado"
      handleCloseModal={toggleModal}
      handleSubmit={methods.handleSubmit(onSubmit)}
      btnText="Salvar"
      isLoading={isLoading}
      btnColor="#000000"
    >
      <FormProvider {...methods}>
        <FormUpdateTicket>
          <TextareaField
            attribute={{
              id: 2,
              name: 'description',
              question: 'Qual seu comentário para o chamado?',
              optionsSelectElement: [],
              required: true,
              isHiddenToVip: false,
              placeholder: ''
            }}
          />
          {updateTicketShowField?.show(ticket.status) && (
            <FileField
              attribute={{
                id: 1,
                name: 'file',
                question: 'Você deseja anexar arquivo?',
                optionsSelectElement: [],
                required: false,
                isHiddenToVip: false,
                placeholder: '',
                maxLimitFile: 3,
                isMulti: true
              }}
            />
          )}
        </FormUpdateTicket>
      </FormProvider>
    </ModalContent>
  )
}

export default UpdateTicketModal
