import { CustomerStatusColorEnum, CustomerStatusType } from 'domain/models'
import { statusColorAdapter } from 'main/adapters'
import {
  makeGetCardList,
  makeGetNewsList,
  makeGetTicket,
  makeGetTicketListWithoutPagination
} from 'main/factories/usecases'
import { Home } from 'presentation/pages'

const makeHome = () => {
  return (
    <Home<CustomerStatusType>
      getTicketList={makeGetTicketListWithoutPagination()}
      // getTicketList={makeGetTicketList()}
      getCardList={makeGetCardList()}
      statusColor={statusColorAdapter<CustomerStatusType>(CustomerStatusColorEnum)}
      getTicket={makeGetTicket()}
      getNews={makeGetNewsList()}
    />
  )
}

export default makeHome
