import { createContext, ReactNode, useContext } from 'react'
import {
  CreateNews,
  DeleteNews,
  DeletePublicFile,
  EditNews,
  GetNewsList,
  SaveFile,
  UpdateOrderNews
} from 'domain/usecases'
import {
  makeCreateNews,
  makeDeleteNews,
  makeDeletePublicFile,
  makeEditNews,
  makeGetNewsList,
  makePostPublicFile,
  makeUpdateOrderNews
} from 'main/factories/usecases'

type PropsContext = {
  getNews: GetNewsList
  saveFile: SaveFile
  deleteFile: DeletePublicFile
  createNews: CreateNews
  editNews: EditNews
  deleteNews: DeleteNews
  updateOrderNews: UpdateOrderNews
}

export const AdmNewsContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const AdmNewsContextProvider = ({ children }: Props): any => {
  return (
    <AdmNewsContext.Provider
      value={{
        getNews: makeGetNewsList(),
        saveFile: makePostPublicFile(),
        deleteFile: makeDeletePublicFile(),
        createNews: makeCreateNews(),
        editNews: makeEditNews(),
        deleteNews: makeDeleteNews(),
        updateOrderNews: makeUpdateOrderNews()
      }}
    >
      {children}
    </AdmNewsContext.Provider>
  )
}

export const useAdmNewsContext = () => {
  return useContext(AdmNewsContext)
}
