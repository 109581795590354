import axios from 'axios'
import { HttpClientAll } from 'data/protocols'
import { SaveFile } from 'domain/usecases'

export class AxiosAllSaveFiles implements HttpClientAll {
  constructor (private readonly saveFile: SaveFile) {}
  async all (data: File[]): Promise<any> {
    if (data.length > 0) {
      return axios.all(
        data.map(async (el: File) => {
          return await this.saveFile.post(el)
        })
      )
    }
  }
}
