import {
  RemoteCreateNews,
  RemoteDeleteNews,
  RemoteEditNews,
  RemoteGetNewsList,
  RemoteUpdateOrderNews
} from 'data/usecases'
import { CreateNews, DeleteNews, EditNews, GetNewsList, UpdateOrderNews } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeBackendApiUrl, makeAxiosHttpClient } from 'main/factories/http'

export const makeGetNewsList = (): GetNewsList =>
  new RemoteGetNewsList(makeAxiosHttpClient(), makeBackendApiUrl('/news'), getTokenAdapter())

export const makeCreateNews = (): CreateNews =>
  new RemoteCreateNews(makeAxiosHttpClient(), makeBackendApiUrl('/news'), getTokenAdapter())

export const makeEditNews = (): EditNews =>
  new RemoteEditNews(makeAxiosHttpClient(), makeBackendApiUrl('/news'), getTokenAdapter())

export const makeDeleteNews = (): DeleteNews =>
  new RemoteDeleteNews(makeAxiosHttpClient(), makeBackendApiUrl('/news'), getTokenAdapter())

export const makeUpdateOrderNews = (): UpdateOrderNews =>
  new RemoteUpdateOrderNews(makeAxiosHttpClient(), makeBackendApiUrl('/news/weight'), getTokenAdapter())
