import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ContainerLoading,
  Notification,
  ReturnBtn,
  Button,
  TicketInfo,
  UpdateTicketModal
} from '../../components'
import { useGetTicketDetails } from '../../hooks/useGetTicketDetails'
import { useTicketDetailsContext } from 'presentation/context'

import { WrapperContentPage } from '../../styles/commonStyles'
import { ContainerTitle, WrapperHeaderTickets } from 'presentation/components/TicketList/styles'
import { ModalWrapper } from 'presentation/components/Modal/styles'

export function TicketDetails () {
  const { ticketID } = useParams()
  const { getTicket, updateTicketBtn } = useTicketDetailsContext()
  const { ticket, isLoadingTicket, getTicketDetails } = useGetTicketDetails({ getTicket })
  const refUpdateTicketModal = useRef<HTMLDialogElement>(null)
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false)

  const toggleUpdateModal = () => {
    if (refUpdateTicketModal.current) {
      if (isOpenUpdateModal) {
        setIsOpenUpdateModal(false)
        refUpdateTicketModal?.current.close()
        return
      }
      setIsOpenUpdateModal(true)
      refUpdateTicketModal?.current.showModal()
    }
  }

  useEffect(() => {
    if (ticketID) {
      getTicketDetails(ticketID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WrapperContentPage>
      <>
        <ReturnBtn />
        <WrapperHeaderTickets>
          <ContainerTitle>
            {'VISUALIZAR CHAMADO'}
            <span />
          </ContainerTitle>
          {!isLoadingTicket && ticket && updateTicketBtn.getFeatures(ticket?.status)?.isShowBtn && (
            <Button
              text={updateTicketBtn.getFeatures(ticket?.status)?.labelBtn}
              onClick={toggleUpdateModal}
            />
          )}
        </WrapperHeaderTickets>
      </>

      {isLoadingTicket && (
        <div className="loading">
          <ContainerLoading />
        </div>
      )}

      {!isLoadingTicket && !ticket && (
        <div className="message">
          <Notification message="No momento não foi possível carregar os destalhes do chamado" />
        </div>
      )}

      {!isLoadingTicket && ticket && <TicketInfo ticket={ticket} />}

      <ModalWrapper ref={refUpdateTicketModal}>
        {isOpenUpdateModal && ticket && (
          <UpdateTicketModal
            ticket={ticket}
            toggleModal={toggleUpdateModal}
            updateData={() => {
              getTicketDetails(ticketID as string)
            }}
          />
        )}
      </ModalWrapper>
    </WrapperContentPage>
  )
}
