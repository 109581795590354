export type HttpRequest = {
  url: string
  method: HttpMethod
  headers?: any
  params?: Record<string, unknown>
  body?: Record<string, unknown> | FormData | any
  withCredentials?: boolean
}

export type HttpMethod = 'post' | 'get' | 'put' | 'delete' | 'POST' | 'GET' | 'PUT' | 'DELETE'

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode
  body?: T
}

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  notFound = 404,
  conflict = 409,
  serverError = 500
}

export interface HttpClient<R = any> {
  request: (dto: HttpRequest) => Promise<HttpResponse<R>>
}

export interface HttpClientAll<R = any> {
  all: (data: R[]) => Promise<any>
}
