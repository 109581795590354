import { memo, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, FiltersTicket, Spinner, TicketListContent } from 'presentation/components'
import { useGetTicketDetails, useGetTickets } from '../../hooks'
import { FiltersTicketType, IPartialTicket, IStatusColor } from 'domain/models'
import { GetTicket, GetTicketList } from 'domain/usecases'

import { ContainerTitle, WrapperHeaderTickets } from 'presentation/components/TicketList/styles'

type Props<T> = {
  getTicketList: GetTicketList
  statusColor: Array<IStatusColor<T>>
  getTicket: GetTicket

}

function TicketList<T> ({ getTicketList, statusColor, getTicket }: Props<T>) {
  const navigate = useNavigate()
  const { ticketList, isLoadingTickets, getData } = useGetTickets({ getTicketList })
  const { isLoadingTicket, ticket, getTicketDetails } = useGetTicketDetails({ getTicket })
  const [filters, setFilters] = useState<Record<FiltersTicketType | 'id', string | null>>({
    status: null,
    id: null
  })

  const isLoading = isLoadingTickets || isLoadingTicket
  const list: IPartialTicket[] =
    !ticket && filters.id ? [] : ticket && filters.id ? [ticket] : ticketList

  useEffect(() => {
    getData({ page: 1, limit: 5 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <WrapperHeaderTickets>
        <ContainerTitle $fontSize="1.25rem" $fontColor='#000000'>
          {'CHAMADOS'}
          <span />
        </ContainerTitle>
        {ticketList.length > 0 && !isLoading && (
          <Button
            style={{ fontSize: '0.75rem' }}
            text="Visualizar todos"
            onClick={() => {
              navigate('/ticket/list')
            }}
          />
        )}
      </WrapperHeaderTickets>
      {isLoading && (
        <Spinner text={'Aguarde, já estamos carregando os chamados'} marginTop="2rem" />
      )}

      {!isLoading && (
        <>
          <FiltersTicket
            getTicketList={getData}
            setFilters={setFilters}
            filters={filters}
            getTicketDetails={getTicketDetails}
            limit={5}
          />{' '}
          <TicketListContent ticketList={list} statusColor={ statusColor} />
        </>
      )}
    </>
  )
}

export default memo(TicketList)
