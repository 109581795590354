import { getCurrentAccountAdapter } from 'main/adapters'
import { CardList, News, TicketList } from '../../components'
import { GetCardList, GetNewsList, GetTicket, GetTicketList } from 'domain/usecases'
import { IStatusColor } from 'domain/models'

import { ContainerHome } from './styles'
import { WrapperSectionContent } from 'presentation/styles/commonStyles'

type Props<T> = {
  getTicketList: GetTicketList
  statusColor: Array<IStatusColor<T>>
  getTicket: GetTicket
  getCardList: GetCardList
  getNews: GetNewsList
}

export function Home<T> ({ getTicketList, statusColor, getTicket, getCardList, getNews }: Props<T>) {
  const user = getCurrentAccountAdapter()
  const firstName = user?.name?.split(' ')[0] ?? ''
  const title = `Olá, ${firstName}!`

  return (
    <ContainerHome>
      <div className="wrapper-info">
        <h1>{title}</h1>
        <p>Como podemos lhe ajudar?</p>
      </div>

      <News getNews={getNews} />
      <WrapperSectionContent $minHeight="8rem" $paddingX="0">
        <CardList getCardList={getCardList} />
      </WrapperSectionContent>
      <WrapperSectionContent $minHeight="14rem">
        <TicketList getTicketList={getTicketList} statusColor={statusColor} getTicket={getTicket} />
      </WrapperSectionContent>
    </ContainerHome>
  )
}
