import { BtnNotificationBell, UserPhoto } from 'presentation/components'

import { getShowNewsFlagAdapter, setShowNewsFlagAdapter } from 'main/adapters'
import { useWindowDimensions } from 'presentation/hooks'
import logo from '../../assets/LOGO_PLATAFORMA_BRANCA.png'
import { ContainerHeader } from './styles'

const userData = {
  imageUrl: logo,
  isNotification: true
}

const Header = () => {
  const { width } = useWindowDimensions()
  const isShowNews = getShowNewsFlagAdapter() === 'true'

  return (
    <ContainerHeader $isPhoto={!!userData?.imageUrl}>
      <div className="responsive_container">
        <div className="logo">
          <img src={logo} alt="logo" />
        </div>
        {width > 768 && <div className="label">Plataforma Unificada de Suporte</div>}
        <div className="utilities">
          {/* <Search /> */}
          <div className="img-notify">
            {!isShowNews && (
              <BtnNotificationBell
                existNotification={userData.isNotification}
                className="notification"
                onClick={() => {
                  setShowNewsFlagAdapter('true')
                  window.location.reload()
                }}
              />
            )}
            <UserPhoto urlImg={userData?.imageUrl} className="user_photo" />
          </div>
        </div>
      </div>
    </ContainerHeader>
  )
}

export default Header
