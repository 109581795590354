import { HttpClient, HttpClientAll } from 'data/protocols'
import { AxiosAllDeletePublicFiles, AxiosAllSaveFiles, AxiosHttpClient } from 'infra'
import { makeDeletePublicFile, makePostPrivateFile, makePostPublicFile } from '../usecases'

export const makeAxiosHttpClient = (): HttpClient => new AxiosHttpClient()

export const makeAxiosAllSavePrivateFiles = (): HttpClientAll =>
  new AxiosAllSaveFiles(makePostPrivateFile())

export const makeAxiosAllSavePublicFiles = (): HttpClientAll =>
  new AxiosAllSaveFiles(makePostPublicFile())

export const makeAxiosAllDeletePublicFiles = (): HttpClientAll =>
  new AxiosAllDeletePublicFiles(makeDeletePublicFile())
