/* eslint-disable @typescript-eslint/space-before-function-paren */
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { IPartialTicket, IStatusColor } from 'domain/models'
import { getStatusColor } from 'presentation/utils'

import { WrapperContent, WrapperTicket } from './styles'

type Props<T> = {
  ticketList: IPartialTicket[]
  statusColor: Array<IStatusColor<T>>
}

export function TicketListContent<T>({ ticketList, statusColor }: Props<T>) {
  // const { width } = useWindowDimensions()
  const navigate = useNavigate()

  return (
    <WrapperContent>
      {ticketList.length === 0 && (
        <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '2rem' }}>
          Nenhum chamado encontrado!
        </p>
      )}
      {ticketList.length > 0 &&
        ticketList.map((ticket: IPartialTicket) => (
          <WrapperTicket key={ticket.id}>
            <div className="status">
              <span
                style={{
                  backgroundColor: getStatusColor<T>(statusColor, ticket.status.toUpperCase() as T)
                }}
              >
                {ticket.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? 'Em atendimento' : ticket.status }
              </span>
            </div>
            <div className="wrapper-info-ticket">
              <div className="info">
                <div className="ticket_id">{`N ${ticket.id}`}</div>
                <div className="ticket_summary">
                  <div className="summary_text summary">
                    <label>Assunto:</label>
                    <p>{ticket.title}</p>
                  </div>

                  {/* {width > 768 && ticket.category && (
                    <div className="summary_text category">
                      <label>Tipo:</label>
                      <p>{ticket.title}</p>
                    </div>
                  )} */}
                  <div className="summary_text date">
                    <label>Data de Abertura:</label>
                    <p>{ticket.createdAt?.replace(',', '')}</p>
                  </div>
                </div>
              </div>
              <div className="btn-show-ticket">
                <button
                  type="button"
                  title="Ver detalhes"
                  onClick={() => {
                    navigate(`/ticket/detail/${ticket.id}`)
                  }}
                >
                  <FontAwesomeIcon icon={faAngleRight} />
                </button>
              </div>
            </div>
          </WrapperTicket>
        ))}
    </WrapperContent>
  )
}
