/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  AddFaqModal,
  AddButton,
  DeleteFaqModal,
  EditFaqModal,
  Pagination,
  Spinner
} from 'presentation/components'
import { FaqModel } from 'domain/models'
import { useGetFaqList } from 'presentation/hooks'
import { useAdmFaqContext } from 'presentation/context'

import { EmptyListMessage, TitlePage, WrapperFaqList, WrapperContent } from '../styles'
import { ModalWrapper } from 'presentation/components/Modal/styles'

export const FaqAdmin = () => {
  const { getFaqList } = useAdmFaqContext()
  const { isLoading, faqList, getFaqData, pagination } = useGetFaqList({ getFaqList })

  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [faqSelected, setFaqSelected] = useState<FaqModel | null>(null)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
    }
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenEditModal && !isOpenDeleteModal) {
      getFaqData()
    }
  }, [])

  return (
    <>
      <WrapperContent>
        <TitlePage>Faq</TitlePage>
        <AddButton text="Criar Faq" onClick={toggleAddModal} />
        <WrapperFaqList>
          <label>Faq cadastradas:</label>

          {isLoading && <Spinner />}
          {!isLoading && faqList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhuma FAQ encontrada!</p>
            </EmptyListMessage>
          )}
          {!isLoading && faqList.length > 0 && (
            <table className="list-table">
              <thead>
                <tr>
                  <th>Título</th>
                  <th>Formulários associados</th>
                  <th>AÇÃO</th>
                </tr>
              </thead>
              <tbody>
                {faqList.map((faq) => (
                  <tr key={faq.id}>
                    <td>{faq.title}</td>
                    <td>
                      {faq.formsAssociated.map((form) => (
                        <div key={form.id} className="row-form-title">
                          {form.title}
                        </div>
                      ))}
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          setFaqSelected(faq)
                          toggleEditModal()
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        onClick={() => {
                          setFaqSelected(faq)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperFaqList>
        {!isLoading && faqList.length > 0 && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getFaqData(pagination.page + 1)
            }}
            onPreviousPage={() => {
              getFaqData(pagination.page - 1)
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )}
      </WrapperContent>
      <ModalWrapper ref={refAddModal}>
        {isOpenAddModal && (
          <AddFaqModal
            toggleModal={toggleAddModal}
            updateData={() => {
              getFaqData(0)
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal}>
        {isOpenEditModal && faqSelected && (
          <EditFaqModal
            toggleModal={toggleEditModal}
            faqSelected={faqSelected}
            updateData={() => {
              getFaqData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && faqSelected && (
          <DeleteFaqModal
            toggleModal={toggleDeleteModal}
            faqSelected={faqSelected}
            updateData={() => {
              getFaqData()
            }}
          />
        )}
      </ModalWrapper>
    </>
  )
}
