import { ModalContent } from '..'
import { ModalBodyInfo } from 'presentation/components/Modal/styles'
import { InputsToRender } from 'presentation/components/TicketForms/InputsToRender'
import { FormI } from 'domain/models'

type Props = {
  toggleModal: () => void
  form: FormI
}

const PreviewFormModal = ({ toggleModal, form }: Props) => {
  return (
    <ModalContent
      title="Visualização do formulário"
      handleCloseModal={toggleModal}
      handleSubmit={null}
      isLoading={false}
    >
      <ModalBodyInfo>
        <div className="form-inputs">
          <InputsToRender
            attributes={form.attributes}
            isVip={false}
            setUserName={() => { }}
            handleCheckboxExtraQuestions={() => { }}
            handleSelectExtraQuestions={() => { }}
            optionListSelected={[]}
            handleEquipmentSelection={() => { }}
          />
        </div>
      </ModalBodyInfo>
    </ModalContent>
  )
}

export default PreviewFormModal
