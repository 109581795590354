import { useState } from 'react'
import { UserAdminModel } from 'domain/models'
import { GetAdminList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getAdminList: GetAdminList
}
export const useGetAdminList = ({ getAdminList }: Props) => {
  const { handleError } = useHandleError()
  const [adminList, setAdminList] = useState<UserAdminModel[]>([])
  const [isLoadingAdminList, setIsLoading] = useState(false)

  const getAdminData = async () => {
    try {
      setIsLoading(true)
      const response = await getAdminList.get()
      setAdminList(response)
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    adminList,
    isLoadingAdminList,
    getAdminData
  }
}
