import { HttpClient, HttpStatusCode } from 'data/protocols'
import { AccessDeniedError, InvalidCredentialsError, InvalidParamsError, UnexpectedError } from 'domain/errors'
import { CustomerUserAuthenticationModel } from 'domain/models'
import { AuthenticationDto, CustomerUserAuthentication } from 'domain/usecases'

export class RemoteCustomerUserAuthentication implements CustomerUserAuthentication {
  constructor (
    private readonly httpClient: HttpClient<CustomerUserAuthenticationModel>,
    private readonly url: string
  ) {}

  async auth (dto: AuthenticationDto): Promise<CustomerUserAuthenticationModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: {
        credentials: {
          login: dto.username,
          password: dto.password
        }
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError()
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError('Usuário ou senha Incorretos!')
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError('Usuário ou senha incorretos!')
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      default:
        throw new UnexpectedError('Usuário não encontrado na base do ITSM. Por favor, entre em contato com a central de serviços.')
    }
  }
}
