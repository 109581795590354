import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Authentication, CreateSession, GetUserProfile } from 'domain/usecases'
import { UserRoleType } from 'domain/models'
import { setCurrentAccountAdapter, setShowNewsFlagAdapter, setTokenAdapter, setVipUserFlagAdapter } from 'main/adapters'

type Props = {
  authentication: Authentication
  session: CreateSession
  profile?: GetUserProfile
}

export const useDefaultLogin = ({ authentication, session, profile }: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const navigate = useNavigate()

  const signIn = async (username: string, password: string) => {
    try {
      setIsLoading(true)
      const { name, login } = await authentication.auth({ username, password })

      const { token, user } = await session.create({ name, username: login })

      let isUserVip: boolean = false

      if (profile) {
        const { isVip } = await profile.get(login)
        isUserVip = isVip
      }

      await saveDataInLocalStorage({
        token,
        name,
        login,
        role: user?.roles,
        isVip: isUserVip
      })

      navigate('/')
    } catch (error: any) {
      setErrorMessage(error?.message as string)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    signIn,
    isLoading,
    errorMessage
  }
}

type SaveInLocalStorageProps = {
  token: string
  name: string
  login: string
  role: UserRoleType
  isVip: boolean
}

const saveDataInLocalStorage = async (props: SaveInLocalStorageProps) => {
  const { token, name, login, role, isVip } = props
  setCurrentAccountAdapter({
    name,
    login,
    role
  })
  setTokenAdapter(token)
  setVipUserFlagAdapter(isVip.toString())
  setShowNewsFlagAdapter('true')
}
