import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const Form = styled.form<{ $color: string }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  font-size: 1rem;
  padding: 3rem 4.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 1rem;
  border-left: 1rem solid ${(props) => props.$color ?? props.theme.colors.gray._400};
  margin-bottom: 2rem;
  box-shadow: 0px 1px 4px ${({ theme }) => theme.colors.gray._800};

  .form-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    line-height: 1.5;
    margin-bottom: 2rem;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.blue._800};

    span:nth-child(1) {
      font-size: 0.875rem;
      font-weight: normal;
    }

    span:nth-child(2) {
      font-size: 1.5rem;
      font-weight: bold;
      color: ${(props) => props.$color ?? props.theme.colors.gray._700};
    }
  }

  h2 {
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.gray._700};
  }

  .form-inputs {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0;
    gap: 1.5rem;

    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
      color: ${({ theme }) => theme.colors.gray._600} !important;
    }
  }

  .form-button {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    width: 100%;
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    padding: 2rem;

    .form-label {
      span:nth-child(2) {
        font-size: 1.25rem;
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    padding: 2rem 3rem;

    .form-label {
      span:nth-child(2) {
        font-size: 1.25rem;
      }
    }
  }
`
