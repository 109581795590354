import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'

export const ContainerSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;


  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    width: 37.25rem;
    height: auto;
    padding: 3rem 4rem;
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._900};
    gap: 2rem;

    img {
      width: 64px;
      height: 64px;
    }

    .success-content-info {
      line-height: 1.35;
      h4 {
        margin-top: 0.5rem;
      }
    }

    .success-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;
      width: 100%;
      gap: 2rem;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: 2.3rem;
        padding: 0 1rem;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        font-weight: bold;
        line-height: 1.5;
        text-transform: uppercase;
        transition: 0.2s;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }
      }

      a:nth-child(1) {
        button {
          background-color: ${({ theme }) => theme.colors.white};
          border: 2px solid ${({ theme }) => theme.colors.black};
          color: ${({ theme }) => theme.colors.black};
          &:hover {
            background-color: ${({ theme }) => theme.colors.black};
            color: ${({ theme }) => theme.colors.white};
          }
        }
      }

      a:nth-child(2) {
        button {
          background-color: ${({ theme }) => theme.colors.blue._500};
          border: none;
          color: ${({ theme }) => theme.colors.white};
          &:hover {
            background-color: ${({ theme }) => theme.colors.blue._600};
          }
        }
      }
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    .success-content {
      width: auto;
      height: auto;
      padding: 2rem 2rem;
    }
  }
`
