import { createContext, ReactNode, useContext } from 'react'
import {
  CreateForm,
  DeleteForm,
  EditForm,
  GetAttributeList,
  GetCardList,
  GetCategoryList,
  GetForm,
  GetFormList
} from 'domain/usecases'
import {
  makeCreateForm,
  makeDeleteForm,
  makeEditForm,
  makeGetAttributeList,
  makeGetCardList,
  makeGetCategoryList,
  makeGetForm,
  makeGetFormList
} from 'main/factories/usecases'

type PropsContext = {
  getCardList: GetCardList
  getForm: GetForm
  getFormList: GetFormList
  getCategoryList: GetCategoryList
  getAttributeList: GetAttributeList
  createForm: CreateForm
  editForm: EditForm
  deleteForm: DeleteForm
}

export const AdmFormContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const AdmFormContextProvider = ({ children }: Props): any => {
  return (
    <AdmFormContext.Provider
      value={{
        getCardList: makeGetCardList(),
        getForm: makeGetForm(),
        getFormList: makeGetFormList(),
        getCategoryList: makeGetCategoryList(),
        getAttributeList: makeGetAttributeList(),
        createForm: makeCreateForm(),
        editForm: makeEditForm(),
        deleteForm: makeDeleteForm()
      }}
    >
      {children}
    </AdmFormContext.Provider>
  )
}

export const useAdmFormContext = () => {
  return useContext(AdmFormContext)
}
