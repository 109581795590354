import { useState } from 'react'
import { MultiValue, SingleValue } from 'react-select'
import { Select } from 'presentation/components'
import { GetDataProps } from 'presentation/hooks'
import { statusOptions, styles } from './utils'
import { OptionSelect } from 'presentation/components/Select/Select'
import { Search } from './Filters/Search'
import { FiltersTicketType } from 'domain/models'

import { WrapperFilters } from './styles'

type Props = {
  getTicketList: ({ limit, page, filter }: GetDataProps) => Promise<void>
  filters: Record<FiltersTicketType | 'id', string | null>
  setFilters: (value: Record<FiltersTicketType | 'id', string | null>) => void
  getTicketDetails: (ticketId: string | number, user?: null) => Promise<void>
  limit?: number
}

const FiltersTicket = ({ getTicketList, filters, setFilters, getTicketDetails, limit = 12 }: Props) => {
  const [ticketId, setTicketId] = useState('')

  const updateFilters = (filterName: FiltersTicketType | 'id', value: string) => {
    const newFilters: Record<string, string | null> = {}
    const keys = Object.keys(filters)
    keys.forEach((key) => {
      if (key === filterName) {
        newFilters[key] = value
      } else {
        newFilters[key] = null
      }
    })
    setFilters(newFilters)

    return newFilters
  }

  const handleSelect = (
    e: SingleValue<OptionSelect> | MultiValue<OptionSelect>,
    filterName: FiltersTicketType | 'id'
  ) => {
    const value = (e as SingleValue<OptionSelect>)?.value

    const newFilters = updateFilters(filterName, value)

    getTicketList({ page: 1, filter: newFilters as Record<FiltersTicketType, string>, limit })
  }

  const handleSearchTicketById = () => {
    if (ticketId) {
      updateFilters('id', ticketId)
      getTicketDetails(ticketId)
    }
  }

  return (
    <WrapperFilters>
      {/* {width > 640 && (
        <Select
          onChange={(e) => {}}
          optionValues={[]}
          styles={styles}
          noOptMessage="Assunto não encontrado"
          placeholder="POR ASSUNTO"
        />
      )} */}

      {/* <Select
        onChange={(e) => {}}
        optionValues={[]}
        styles={styles}
        noOptMessage="Tipo não encontrado"
        placeholder="POR TIPO"
      /> */}

      <Select
        onChange={(e) => {
          handleSelect(e, 'status')
        }}
        optionValues={statusOptions}
        styles={styles}
        noOptMessage="Status não encontrado"
        placeholder="POR STATUS"
        defaultValue={statusOptions.find((options) => options.value === filters?.status)}
      />

      <Search
        defaultValue={filters?.id ?? ''}
        setValue={(value) => {
          setTicketId(value)
        }}
        handleSearch={handleSearchTicketById}
      />
    </WrapperFilters>
  )
}

export default FiltersTicket
