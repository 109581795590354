import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { NotificationBox } from './styles'

type Props = {
  message: string
}

const Notification = ({ message }: Props) => {
  return (
    <NotificationBox>
      <div className="icon">
        <FontAwesomeIcon icon={faCircleExclamation} />
      </div>
      <div className="message">
        <p>{message}</p>
      </div>
    </NotificationBox>
  )
}

export default Notification
