import { HttpClientAll } from 'data/protocols'
import { FileManagerModel, IAttachments } from 'domain/models'
import { SaveFile } from 'domain/usecases'

export const setFileField = async (
  data: Record<string, string | File>,
  saveFile: SaveFile,
  saveAllPrivateFile: HttpClientAll
) => {
  const attachments: IAttachments[] = []

  for (const value of Object.values(data)) {
    // Condition for Array<File> value
    if (Array.isArray(value) && value[0] instanceof File) {
      const response = await saveAllPrivateFile.all(value)
      response.forEach((file: FileManagerModel[]) =>
        attachments.push({
          url: file[0].name,
          name: file[0].originalname,
          token: file[0].tokenFile ?? ''
        })
      )
    }

    if (!Array.isArray(value) && value instanceof File) {
      const response = await saveFile.post(value)
      response.forEach((file: FileManagerModel) => {
        attachments.push({
          url: file.name,
          name: file.originalname,
          token: file.tokenFile ?? ''
        })
      })
    }
  }

  return attachments
}
