import { ButtonHTMLAttributes } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faNewspaper } from '@fortawesome/free-regular-svg-icons'
import { BtnBell } from './styles'

interface Props extends ButtonHTMLAttributes<HTMLElement> {
  existNotification?: boolean
}

const BtnNotificationBell = (properties: Props) => {
  const { existNotification = true, ...props } = properties
  return (
    <BtnBell type='button' title="Notificação" {...props} >
      {existNotification && <span className="label" />}
      <FontAwesomeIcon icon={faNewspaper} />
    </BtnBell>
  )
}

export default BtnNotificationBell
