/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react'
import { faPenToSquare, faTrashCan } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { MoonLoader } from 'react-spinners'
import {
  AddButton,
  AddFormModal,
  DeleteFormModal,
  EditFormModal,
  EnableFormModal,
  Pagination,
  Spinner
} from 'presentation/components'
import { useGetForm, useGetFormList } from 'presentation/hooks'
import { FormI, FormModel } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'

import {
  EmptyListMessage,
  TitlePage,
  WrapperContent,
  WrapperFormList,
  WrapperLoadingModal
} from '../styles'
import { CardLabelColor } from 'presentation/components/Card/styles'
import { ModalWrapper } from 'presentation/components/Modal/styles'

export const FormsAdmin = () => {
  const { getForm, getFormList } = useAdmFormContext()
  const { form, getFormData, isLoadingForm } = useGetForm({ getForm })
  const { getFormListData, formList, isLoadingFormList, pagination } = useGetFormList({ getFormList })

  const refAddModal = useRef<HTMLDialogElement>(null)
  const refEditModal = useRef<HTMLDialogElement>(null)
  const refDeleteModal = useRef<HTMLDialogElement>(null)
  const refEnableModal = useRef<HTMLDialogElement>(null)
  const refInfoModal = useRef<HTMLDialogElement>(null)

  const [isOpenAddModal, setIsOpenAddModal] = useState(false)
  const [isOpenEditModal, setIsOpenEditModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenEnableModal, setIsOpenEnableModal] = useState(false)
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)
  const [formSelected, setFormSelected] = useState<FormModel | FormI | null>(null)

  const toggleAddModal = () => {
    if (refAddModal.current) {
      if (isOpenAddModal) {
        setIsOpenAddModal(false)
        refAddModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenAddModal(true)
      refAddModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEnableModal = () => {
    if (refEnableModal.current) {
      if (isOpenEnableModal) {
        setIsOpenEnableModal(false)
        refEnableModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEnableModal(true)
      refEnableModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleEditModal = () => {
    if (refEditModal.current) {
      if (isOpenEditModal) {
        setIsOpenEditModal(false)
        refEditModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenEditModal(true)
      refEditModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleDeleteModal = () => {
    if (refDeleteModal.current) {
      if (isOpenDeleteModal) {
        setIsOpenDeleteModal(false)
        refDeleteModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenDeleteModal(true)
      refDeleteModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleInfoModal = () => {
    if (refInfoModal.current) {
      if (isOpenInfoModal) {
        setIsOpenInfoModal(false)
        refInfoModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenInfoModal(true)
      refInfoModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    if (!isOpenAddModal && !isOpenEditModal && !isOpenDeleteModal) {
      getFormListData()
    }
  }, [])

  useEffect(() => {
    if (isLoadingForm) {
      toggleInfoModal()
      return
    }

    if (!isLoadingForm && form) {
      setFormSelected(form)
      toggleInfoModal()
      toggleEditModal()
    }
  }, [isLoadingForm])

  return (
    <>
      <WrapperContent>
        <TitlePage>Formulário</TitlePage>
        <AddButton text="Criar Formulário" onClick={toggleAddModal} />
        <WrapperFormList>
          <label>Formulários cadastrados:</label>

          {isLoadingFormList && <Spinner />}
          {!isLoadingFormList && formList.length === 0 && (
            <EmptyListMessage>
              <p>Nenhum formulário encontrado!</p>
            </EmptyListMessage>
          )}
          {!isLoadingFormList && formList.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th className="column-color"></th>
                  <th className="column-title">Título</th>
                  <th className="column-cardName">Card associado</th>
                  <th className="column-action">Ação</th>
                  <th className="column-active">Ativo</th>
                </tr>
              </thead>
              <tbody>
                {formList.map((form: FormModel) => (
                  <tr key={form.id}>
                    <td className="column-color">
                      <CardLabelColor
                        $colorLabel={form.associatedCard?.color ?? '#ababab'}
                        $height="90% !important"
                      />
                    </td>
                    <td className="column-title">{form.title}</td>
                    <td className="column-cardName">
                      {form.ancestorsCardTreeName ? (
                        form.ancestorsCardTreeName
                          .split('>')
                          .map((name: any, index: number) => <p key={index}>{name}</p>)
                      ) : (
                        <p style={{ fontSize: '0.85rem' }}>{form.associatedCard?.name}</p>
                      )}
                    </td>
                    <td className="column-action">
                      <button
                        className="action-edit"
                        onClick={async () => {
                          await getFormData(form.id.toString())
                        }}
                        title="Editar"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        className="action-remove"
                        onClick={() => {
                          setFormSelected(form)
                          toggleDeleteModal()
                        }}
                        title="Remover"
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </button>
                    </td>
                    <td className="column-active">
                      <input
                        type="checkbox"
                        checked={form.isEnable}
                        onChange={() => {
                          setFormSelected(form)
                          toggleEnableModal()
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </WrapperFormList>
        {!isLoadingFormList && formList.length > 0 && (
          <Pagination
            currentPage={pagination.page + 1}
            onNextPage={() => {
              getFormListData(pagination.page + 1)
            }}
            onPreviousPage={() => {
              getFormListData(pagination.page - 1)
            }}
            hasNextPage={pagination.next}
            hasPreviousPage={pagination.prev}
          />
        )}
      </WrapperContent>
      <ModalWrapper ref={refAddModal} $minHeight={'30rem'} $width={'80%'}>
        {isOpenAddModal && (
          <AddFormModal
            toggleModal={toggleAddModal}
            updateData={() => {
              getFormListData(0)
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEnableModal}>
        {isOpenEnableModal && formSelected && (
          <EnableFormModal
            formSelected={formSelected as FormModel}
            toggleModal={toggleEnableModal}
            updateData={() => {
              getFormListData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refEditModal} $minHeight={'30rem'} $width={'80%'}>
        {isOpenEditModal && formSelected && (
          <EditFormModal
            toggleModal={toggleEditModal}
            formSelected={formSelected as FormI}
            updateData={() => {
              getFormListData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refDeleteModal}>
        {isOpenDeleteModal && formSelected && (
          <DeleteFormModal
            toggleModal={toggleDeleteModal}
            formSelected={formSelected as FormModel}
            updateData={() => {
              getFormListData()
            }}
          />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refInfoModal}>
        {isOpenInfoModal && (
          <WrapperLoadingModal>
            <MoonLoader size={45} color={'#123abc'} />
            <p>
              Aguarde, já estamos <br /> carregando as informações
            </p>
          </WrapperLoadingModal>
        )}
      </ModalWrapper>
    </>
  )
}
