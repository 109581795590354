import {
  RemoteCreateCard,
  RemoteDeleteCard,
  RemoteEditCard,
  RemoteGetCardList,
  RemoteUpdateCardsOrder
} from 'data/usecases'
import { CreateCard, DeleteCard, EditCard, GetCardList, UpdateCardsOrder } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeBackendApiUrl, makeAxiosHttpClient } from 'main/factories/http'

export const makeGetCardList = (): GetCardList =>
  new RemoteGetCardList(makeAxiosHttpClient(), makeBackendApiUrl('/card'), getTokenAdapter())

export const makeCreateCard = (): CreateCard =>
  new RemoteCreateCard(makeAxiosHttpClient(), makeBackendApiUrl('/card'), getTokenAdapter())

export const makeEditCard = (): EditCard =>
  new RemoteEditCard(makeAxiosHttpClient(), makeBackendApiUrl('/card'), getTokenAdapter())

export const makeUpdateCardsOrder = (): UpdateCardsOrder =>
  new RemoteUpdateCardsOrder(makeAxiosHttpClient(), makeBackendApiUrl('/card/order'), getTokenAdapter())

export const makeDeleteCard = (): DeleteCard =>
  new RemoteDeleteCard(makeAxiosHttpClient(), makeBackendApiUrl('/card'), getTokenAdapter())
