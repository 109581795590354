/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ChangeEvent, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromBracket, faXmark } from '@fortawesome/free-solid-svg-icons'
import { AttributeModel } from 'domain/models'

import { ErrorMessage, FormFileField } from './styles'

type Props = {
  attribute: AttributeModel
}

const FileField = ({ attribute }: Props) => {
  const {
    control,
    setValue,
    clearErrors,
    setError,
    formState: { errors }
  } = useFormContext()
  const isRequired = attribute.required ?? false
  const [fileList, setFileList] = useState<File[]>([])
  const maxLimitFile = attribute?.maxLimitFile ?? 6

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    clearErrors(attribute.name)
    if (!e.target.files) return
    if (
      e.target.files.length > maxLimitFile ||
      e.target.files.length + fileList.length > maxLimitFile
    ) {
      setError(attribute.name, { type: 'error', message: `* Máximo ${maxLimitFile} arquivos` })
      return
    }

    if (e.target.files.length > 0 && e.target.files.length <= maxLimitFile) {
      const _fileList = []
      for (let i = 0; i < e.target.files.length; ++i) {
        const file = e.target.files[i]

        const defaultFormats = [
          'jpg',
          'doc',
          'pdf',
          'png',
          'jpeg',
          'docx',
          'xls',
          'xlsx',
          'xlsm',
          'sheet',
          'JPG',
          'DOC',
          'PDF',
          'PNG',
          'JPEG',
          'DOCX',
          'XLS',
          'XLSX',
          'XLSM',
          'SHEET',
          'zip',
          'ZIP'
        ]

        const isValidFormat = defaultFormats.includes(
          (file.name?.split('.') as unknown as any[]).pop()
        )
        if (!isValidFormat) {
          setError(attribute.name, { type: 'error', message: '* Formato inválido' })
          return
        }

        const isValidSizeFile = file.size <= 3 * 1024 * 1024 + 100 * 1024
        if (!isValidSizeFile) {
          setError(attribute.name, {
            type: 'error',
            message: '* O tamanho permitido é de até 3MB'
          })
          setValue(attribute.name, null)
          return
        }
        const isFileExist = fileList.some((item) => item.name === file.name)
        if (isFileExist) {
          setError(attribute.name, { type: 'error', message: '* Este arquivo já está anexado.' })
          return
        }
        _fileList.push(file)
      }
      if (!attribute?.isMulti) {
        setFileList(_fileList)
        setValue(attribute.name, _fileList[0])
        return
      }

      setFileList([...fileList, ..._fileList])
      setValue(attribute.name, [...fileList, ..._fileList])
    }
  }

  const fileValidation = (isRequired: boolean) => {
    const file = {
      required: {
        value: isRequired,
        message: '* Campo obrigatório'
      }
    }
    return file
  }

  const deleteFileItem = (fileName: string) => {
    const newFileList = fileList.filter((item) => item.name !== fileName)
    setFileList(newFileList)
    setValue(attribute.name, newFileList)
  }

  return (
    <Controller
      name={attribute.name}
      control={control}
      rules={fileValidation(isRequired)}
      render={() => (
        <FormFileField $isDisable={fileList.length > maxLimitFile - 1}>
          <label htmlFor={attribute.name}>{attribute.question}</label>
          <div className={attribute?.isMulti ? 'wrapper-multiple-files' : 'wrapper-one-file'}>
            <div className="form-file">
              <label className="form-file-input">
                <FontAwesomeIcon icon={faArrowUpFromBracket} />
                <span>Carregar arquivos</span>
                <input
                  type="file"
                  name={attribute.name}
                  onChange={handleUpload}
                  disabled={fileList.length > maxLimitFile - 1}
                  multiple={attribute?.isMulti}
                />
              </label>
              {attribute?.isMulti && (
                <span className="form-file-input-info">{`* Limite de ${maxLimitFile} arquivos.`}</span>
              )}
            </div>
            <div className="form-file-list">
              {fileList?.map((item: any, index) => (
                <div key={index} className="form-file-list-name">
                  <span>{item.name} </span>
                  <div
                    className="form-file-list-icon"
                    onClick={() => {
                      deleteFileItem(item.name)
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </div>
                </div>
              ))}
            </div>
          </div>

          {errors[attribute.name] && (
            <ErrorMessage>{errors[attribute.name]?.message?.toString()}</ErrorMessage>
          )}
        </FormFileField>
      )}
    />
  )
}

export default FileField
