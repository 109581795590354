import { HttpClient, HttpStatusCode } from 'data/protocols'
import { BadRequestError, InvalidCredentialsError, UnexpectedError } from 'domain/errors'
import { DeleteNews } from 'domain/usecases'

export class RemoteDeleteNews implements DeleteNews {
  constructor (
    private readonly httpClient: HttpClient<void>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async delete (id: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      headers: { Authorization: this.token },
      method: 'DELETE'
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao remover o destaque')
    }
  }
}
