import styled from 'styled-components'
import background from '../../assets/Background_GNC.png'

export const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  /* background-color: ${({ theme }) => theme.colors.gray._200}; */
  background-size: 100% 100%;
`
export const ErrorMessage = styled.span`
  width: 100% !important;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.red._500};
  font-size: 0.75rem;
  font-weight: 500;
`

export const WrapperLogin = styled.div`
  min-width: 22rem;
  width: 25%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1.25rem;
  padding: 2rem 4rem;
  gap: 1.25rem;

  @media (max-width: 1024px) {
    padding: 3rem 4rem;
  }

  .login-logo {
    width: 100%;
    margin-bottom: 1rem;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    .form-field {
      svg {
        width: 0.875rem;
        height: 0.875rem;
        margin: 0.65em 0.5em 0.5em 0.65em;
        color: ${({ theme }) => theme.colors.red._700};
        position: absolute;
      }

      input {
        width: 100%;
        height: 2.1rem;
        font: italic normal normal 14px/15px Montserrat;
        background: ${({ theme }) => theme.colors.white};
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.red._700};
        padding-left: 2rem;
        border-left: 5px solid ${({ theme }) => theme.colors.red._700};

        &::placeholder {
          font: italic normal normal 12px/15px Montserrat;
          color: ${({ theme }) => theme.colors.gray._500};
        }

        &:focus {
          outline: 1px solid ${({ theme }) => theme.colors.red._400};
        }
      }
    }
  }

  .login-error {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${({ theme }) => theme.colors.red._500};
  }
`
