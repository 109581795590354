import { ChangeEvent } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { AttributeModel, OptionModel } from 'domain/models'

import { ErrorMessage, FormInputRadio } from './styles'

type Props = {
  attribute: AttributeModel
  handleOptionSelected: (value: string) => void
}

const RadioField = ({ attribute, handleOptionSelected }: Props) => {
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext()

  const handleSelect = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target.value) {
      handleOptionSelected(e.target.value)
    }
    return e.target.value
  }

  return (
    <Controller
      name={attribute.name}
      control={control}
      rules={{ required: attribute.required }}
      render={({ field: { onChange } }) => (
        <FormInputRadio>
          <div className="wrapper_radio">
            <label htmlFor={attribute.name}>{attribute.question}</label>
            <div className="radio-list">
              {attribute.optionsSelectElement
                .sort((a, b) => (a.weight as number) - (b.weight as number))
                .map((option: OptionModel) => (
                  <div key={option.id} className="input-radio">
                    <input
                      type="radio"
                      name={`${attribute.name}-${option.id}`}
                      id={option.id.toString()}
                      value={option.value}
                      checked={option.value === getValues()[attribute.name]}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        onChange(handleSelect(e))
                      }}
                    />
                    <p>{option.value}</p>
                  </div>
                ))}
            </div>
          </div>
          {errors[attribute.name]?.type === 'required' && (
            <ErrorMessage>* Campo Obrigatório</ErrorMessage>
          )}
        </FormInputRadio>
      )}
    />
  )
}

export default RadioField
