import { RemoteCustomerGetTicket } from 'data/usecases'
import { GetTicket, CustomerGetTicket } from 'domain/usecases'
import { CustomerGetTicketAdapter } from 'main/adapters'
import { makeAxiosHttpClient, makeConnectorApiUrl } from 'main/factories/http'

export const makeRemoteCustomerGetTicket = (): CustomerGetTicket =>
  new RemoteCustomerGetTicket(makeAxiosHttpClient(), makeConnectorApiUrl('/ticket/show '))

export const makeGetTicket = (): GetTicket =>
  new CustomerGetTicketAdapter(makeRemoteCustomerGetTicket())
