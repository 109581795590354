import { HttpClient, HttpStatusCode } from 'data/protocols'
import {
  BadRequestError,
  InvalidCredentialsError,
  NotFoundError,
  UnexpectedError
} from 'domain/errors'
import { FaqPaginationModel } from 'domain/models'
import { GetFaqList, GetFaqsDto } from 'domain/usecases'

export class RemoteGetFaqList implements GetFaqList {
  constructor (
    private readonly httpClient: HttpClient<FaqPaginationModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async get (params: GetFaqsDto): Promise<FaqPaginationModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      headers: { Authorization: this.token },
      params: { limit: params.limit, page: params.page, order: params.order }
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao carregar as FAQs')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      case HttpStatusCode.notFound:
        throw new NotFoundError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao carregar as FAQs')
    }
  }
}
