import {
  RemoteCreateForm,
  RemoteDeleteForm,
  RemoteEditForm,
  RemoteGetForm,
  RemoteGetFormList
} from 'data/usecases'
import { CreateForm, DeleteForm, EditForm, GetForm, GetFormList } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeBackendApiUrl, makeAxiosHttpClient } from 'main/factories/http'

export const makeGetForm = (): GetForm =>
  new RemoteGetForm(makeAxiosHttpClient(), makeBackendApiUrl('/form'), getTokenAdapter())

export const makeGetFormList = (): GetFormList =>
  new RemoteGetFormList(makeAxiosHttpClient(), makeBackendApiUrl('/form'), getTokenAdapter())

export const makeCreateForm = (): CreateForm =>
  new RemoteCreateForm(makeAxiosHttpClient(), makeBackendApiUrl('/form'), getTokenAdapter())

export const makeEditForm = (): EditForm =>
  new RemoteEditForm(makeAxiosHttpClient(), makeBackendApiUrl('/form'), getTokenAdapter())

export const makeDeleteForm = (): DeleteForm =>
  new RemoteDeleteForm(makeAxiosHttpClient(), makeBackendApiUrl('/form'), getTokenAdapter())
