import { HttpClient, HttpStatusCode } from 'data/protocols'
import { AccessDeniedError, InvalidParamsError, UnexpectedError } from 'domain/errors'
import { CustomerSearchUserByName, CustomerSearchUserByNameResponse } from 'domain/usecases'

export class RemoteCustomerSearchUserByName implements CustomerSearchUserByName {
  constructor (
    private readonly httpClient: HttpClient<CustomerSearchUserByNameResponse>,
    private readonly url: string
  ) {}

  async get (value: string): Promise<CustomerSearchUserByNameResponse> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params: { name: value, size: 100 }
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao procurar o usuário.')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao procurar o usuário.')
    }
  }
}
