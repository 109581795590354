import { RemoteGetAttributeList } from 'data/usecases'
import { GetAttributeList } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeAxiosHttpClient, makeBackendApiUrl } from 'main/factories/http'

export const makeGetAttributeList = (): GetAttributeList =>
  new RemoteGetAttributeList(
    makeAxiosHttpClient(),
    makeBackendApiUrl('/attributes'),
    getTokenAdapter()
  )
