import { HttpClientAll } from 'data/protocols'
import { CustomerStatusColorEnum, IStatusColor } from 'domain/models'
import {
  DeletePrivateFile,
  GetFileBase64,
  GetTicket,
  UpdateTicket,
  UpdateTicketBtnFeatures,
  UpdateTicketShowField
} from 'domain/usecases'
import { statusColorAdapter } from 'main/adapters'
import { makeAxiosAllSavePrivateFiles } from 'main/factories/http'
import {
  makeCustomerUpdateTicket,
  makeCustomerUpdateTicketBtn,
  makeDeletePrivateFile,
  makeGetFileBase64,
  makeGetTicket,
  makeUpdateTicketShowField
} from 'main/factories/usecases'
import { createContext, ReactNode, useContext } from 'react'

type PropsContext = {
  getTicket: GetTicket
  statusColor: Array<IStatusColor<any>>
  updateTicketBtn: UpdateTicketBtnFeatures
  updateTicketShowField?: UpdateTicketShowField
  getFileBase64: GetFileBase64
  updateTicket: UpdateTicket
  deletePrivateFile: DeletePrivateFile
  saveAllPrivateFile: HttpClientAll
}

export const TicketDetailsContext = createContext<PropsContext>({} as PropsContext)

type Props = {
  children: ReactNode
}

export const TicketDetailsContextProvider = ({ children }: Props): any => {
  return (
    <TicketDetailsContext.Provider
      value={{
        getTicket: makeGetTicket(),
        statusColor: statusColorAdapter<any>(CustomerStatusColorEnum),
        updateTicketBtn: makeCustomerUpdateTicketBtn(),
        updateTicketShowField: makeUpdateTicketShowField(),
        getFileBase64: makeGetFileBase64(),
        updateTicket: makeCustomerUpdateTicket(),
        deletePrivateFile: makeDeletePrivateFile(),
        saveAllPrivateFile: makeAxiosAllSavePrivateFiles()
      }}
    >
      {children}
    </TicketDetailsContext.Provider>
  )
}

export const useTicketDetailsContext = () => {
  return useContext(TicketDetailsContext)
}
