import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WrapperHeader } from './styles'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import logo from '../../../assets/GNC_PLUS_BRANCA.png'
import { getCurrentAccountAdapter } from 'main/adapters'

export const Header = () => {
  const user = getCurrentAccountAdapter()
  const firstName = user?.name?.split(' ')[0] ?? ''
  return (
    <WrapperHeader>
      <div className="responsive_container">
        <div className="header-logo__info">
          <img src={logo} alt="logo" />
          <span>Dashboard</span>
        </div>
        <div className="header-info">
          <div className="header-info-icon">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          <div>
            <p>{`Olá, ${firstName}!`}</p>
            <p>Administrador</p>
          </div>
        </div>
      </div>
    </WrapperHeader>
  )
}
