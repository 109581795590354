import { HttpClientAll } from 'data/protocols'
import { FileManagerModel } from 'domain/models'

type Props = {
  files: File[]
  saveMultipleFiles: HttpClientAll
}

export const getUrlsImage = async ({
  files,
  saveMultipleFiles
}: Props): Promise<Array<{ name: string, url: string }>> => {
  const urlList: Array<{ name: string, url: string }> = []

  if (files.length > 0) {
    const response = await saveMultipleFiles.all(files)
    response.forEach((file: FileManagerModel[]) =>
      urlList.push({
        url: file[0].name,
        name: file[0].originalname
      })
    )
  }
  return urlList
}
