import { RemoteCreateFaq, RemoteDeleteFaq, RemoteEditFaq, RemoteGetFaqList } from 'data/usecases'
import { CreateFaq, DeleteFaq, EditFaq, GetFaqList } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeBackendApiUrl, makeAxiosHttpClient } from 'main/factories/http'

export const makeGetFaqList = (): GetFaqList =>
  new RemoteGetFaqList(makeAxiosHttpClient(), makeBackendApiUrl('/article'), getTokenAdapter())

export const makeCreateFaq = (): CreateFaq =>
  new RemoteCreateFaq(makeAxiosHttpClient(), makeBackendApiUrl('/article'), getTokenAdapter())

export const makeEditFaq = (): EditFaq =>
  new RemoteEditFaq(makeAxiosHttpClient(), makeBackendApiUrl('/article'), getTokenAdapter())

export const makeDeleteFaq = (): DeleteFaq =>
  new RemoteDeleteFaq(makeAxiosHttpClient(), makeBackendApiUrl('/article'), getTokenAdapter())
