import styled from 'styled-components'

export const ContainerPhoto = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.25rem;
  height: 3.25rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.blue._100};
  border-radius: 50%;

  span {
    font-size: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};
  }
  img {
    width: 80%;
    height: 80%;
    border: none;
    background-color: ${({ theme }) => theme.colors.blue._100};
    border-radius: 50%;
  }
`
