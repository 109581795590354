/* eslint-disable @typescript-eslint/space-before-function-paren */
import { HttpClient, HttpStatusCode } from 'data/protocols'
import { InvalidCredentialsError, InvalidParamsError, UnexpectedError } from 'domain/errors'
import { IUpdateTicket, CustomerAttachmentsModel, CustomerUpdateTicketModel } from 'domain/models'
import { UpdateTicket } from 'domain/usecases'

export class RemoteCustomerUpdateTicket implements UpdateTicket {
  constructor(
    private readonly httpClient: HttpClient<void>,
    private readonly url: string
  ) { }

  async update(data: IUpdateTicket): Promise<void> {
    const list: CustomerAttachmentsModel[] = []
    if (data.attachments.length > 0) {
      data.attachments.forEach((item) => {
        list.push({
          file_url: item.url,
          file_name: item.name ?? '',
          file_token: process.env.REACT_APP_TOKEN_RECIVED_FILE_MANAGER ?? ''
        })
      })
    }

    const dto: CustomerUpdateTicketModel = {
      user_login: data.userLogin,
      content: data.description,
      attachments_glpi: list,
      entities_id: data.entities_id ?? 0,
      ticket_status: data.ticketStatus ?? '',
      ticket_id: data.ticketId
    }

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: dto
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao atualizar o chamado.')
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao atualizar o chamado.')
    }
  }
}
