import { useState } from 'react'
import { ITicket } from 'domain/models'
import { getCurrentAccountAdapter } from 'main/adapters'
import { GetTicket } from 'domain/usecases'
import { useHandleError } from './useHandleError'
import { InvalidCredentialsError } from 'domain/errors'

type Props = {
  getTicket: GetTicket
}

export const useGetTicketDetails = ({ getTicket }: Props) => {
  const [ticket, setTicket] = useState<ITicket | null>(null)
  const [isLoadingTicket, setLoadingTicket] = useState(false)
  const { handleError } = useHandleError()

  const getTicketDetails = async (ticketId: string | number, user = null): Promise<void> => {
    setLoadingTicket(true)
    try {
      const userLogin = user ?? getCurrentAccountAdapter()?.login

      if (!userLogin) {
        throw new InvalidCredentialsError(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
      }

      const response = await getTicket.get({ userLogin, ticketId })
      setTicket(response)
    } catch (error: any) {
      handleError(error)
    } finally {
      setLoadingTicket(false)
    }
  }

  return {
    ticket,
    isLoadingTicket,
    getTicketDetails
  }
}
