import { useEffect, memo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetCardList } from 'presentation/hooks'
import Carousel from './CardListCarousel'
import SubCardsModal from './SubCardsModal'
import { Notification, Spinner } from 'presentation/components'
import { GetCardList } from 'domain/usecases'
import { CardModel } from 'domain/models'

import { ModalBody, ModalHeader, ModalWrapper } from 'presentation/components/Modal/styles'

type Props = {
  getCardList: GetCardList
}

function CardList ({ getCardList }: Props) {
  const { cardList, isLoadingCardList, getCardsData } = useGetCardList({ getCardList })
  const maxLengthCards = 5 * 2 // Table of 5 cards in 2 rows, to show on the home page
  const activeCards = cardList.filter((card) => card.isEnable)
  const isOneRow = activeCards.length <= maxLengthCards / 2

  const navigate = useNavigate()
  const refSubCardsModal = useRef<HTMLDialogElement>(null)
  const [isOpenSubCardsModal, setIsOpenSubCardsModal] = useState(false)
  const refNotificationModal = useRef<HTMLDialogElement>(null)
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false)

  const [cardSelected, setCardSelected] = useState<null | CardModel>(null)

  const handleCardSelected = (card: CardModel) => {
    if (card?.children?.length === 0 && card?.forms?.length === 0) {
      setCardSelected(card)
      toggleNotificationModal()
      return
    }
    if (card?.forms?.length > 0) {
      toggleNotificationModal()
      navigate(`/ticket/new/${card?.forms[0]?.id}`, { state: { twoStep: true } })
      return
    }
    if (card?.children?.length > 0) {
      setCardSelected(card)
      toggleSubCardsModal()
    }
  }

  const toggleSubCardsModal = () => {
    if (refSubCardsModal.current) {
      if (isOpenSubCardsModal) {
        setIsOpenSubCardsModal(false)
        refSubCardsModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenSubCardsModal(true)
      refSubCardsModal?.current.showModal()
      document.body.style.overflow = 'hidden'
    }
  }

  const toggleNotificationModal = () => {
    if (refNotificationModal.current) {
      if (isOpenNotificationModal) {
        setIsOpenNotificationModal(false)
        refNotificationModal?.current.close()
        document.body.style.overflow = 'unset'
        return
      }
      setIsOpenNotificationModal(true)
      refNotificationModal?.current.showModal()
      // document.body.style.overflow = 'hidden'
    }
  }

  useEffect(() => {
    getCardsData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoadingCardList && (
        <Spinner size={25} text="Carregando os cards" flexDirection="row" marginTop="2rem" />
      )}

      {!isLoadingCardList && cardList.length === 0 && (
        <p style={{ textAlign: 'center', fontWeight: 'bold', marginTop: '1.5rem' }}>
          Nenhum card encontrado!
        </p>
      )}

      {!isLoadingCardList && cardList.length > 0 && (
        <Carousel
          isOneRow={isOneRow}
          cardList={activeCards}
          handleCardSelected={handleCardSelected}
        />
      )}
      <ModalWrapper ref={refSubCardsModal} $width="35rem" $minHeight="30rem">
        {isOpenSubCardsModal && cardSelected && (
          <SubCardsModal cardSelected={cardSelected} toggleModal={toggleSubCardsModal} />
        )}
      </ModalWrapper>
      <ModalWrapper ref={refNotificationModal} $width="40rem" $minHeight="30rem">
        {isOpenNotificationModal && cardSelected && (
          <>
            <ModalHeader>
              <span>{cardSelected.name}</span>
              <button onClick={toggleNotificationModal}>X</button>
            </ModalHeader>
            <ModalBody>
              <div className="notification-message">
                <Notification message="No momento não existe nenhum formulário ou card associado a este card." />
              </div>
            </ModalBody>
          </>
        )}
      </ModalWrapper>
    </>
  )
}

export default memo(CardList)
