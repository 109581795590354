export const themes = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    gray: {
      _100: '#FAFAFA',
      _150: '#F7F6F9',
      _200: '#F4F4F4',
      _250: '#DBDBDB',
      _300: '#D2D2D2',
      _350: '#b1b1b1',
      _400: '#8A8A8A',
      _500: '#707070',
      _550: '#212121',
      _600: '#696969',
      _650: '#4F4F4F',
      _700: '#333',
      _800: '#27272a',
      _900: '#00000029'
    },
    blue: {
      _50: '#EBF9FF',
      _100: '#a0d1e6',
      _200: '#0095D7',
      _300: '#0372AD',
      _400: '#004E7A',
      _600: '#1F7AC9',
      _800: '#002356'
    },
    fuchsia: {
      _600: '#993399'
    },
    purple: {
      _300: '#4155eb'
    },
    yellow: {
      _100: '#ecc91b',
      _700: '#FFA500',
      _800: '#FF9710'
    },
    green: {
      _400: '#49bc20',
      _600: '#008000'
    },
    red: {
      _50: '#fdf5f5',
      _100: '#fee2e2',
      _400: '#C5342C',
      _450: '#CB1515',
      _500: '#cf1f3f',
      _600: '#b52e2e',
      _650: '#E62A2A',
      _700: '#FF0000',
      _800: '#86231e', // #712A27
      _900: '#7A3B3B70'
    },
    orange: {
      _50: '#fff7ed',
      _100: '#ffedd5',
      _150: '#F4A460',
      _300: '#FF9710',
      _350: '#FE8240',
      _500: '#F25D06',
      _600: '#ea580c',
      _700: '#c2410c'
    }
  }
}
