import { useParams } from 'react-router-dom'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove } from '@dnd-kit/sortable'
import SortableRow from './SortableRow'
import { CardModel } from 'domain/models'

import { TableCard } from './styles'

type Props = {
  cards: CardModel[]
  setCardSelected: (value: CardModel) => void
  toggleEditModal: () => void
  toggleDeleteModal: () => void
  toggleEnableCardModal: () => void
  toggleSortCardsModal: () => void
  setCardsToShow: (value: CardModel[]) => void
}

const CardAdminTable = (props: Props) => {
  const {
    cards,
    setCardSelected,
    toggleEditModal,
    toggleDeleteModal,
    toggleEnableCardModal,
    setCardsToShow,
    toggleSortCardsModal
  } = props
  const { cardId, cardIdL1, cardIdL2 } = useParams()

  const getUrl = (card: CardModel): string => {
    if (!cardIdL2 && cardId && cardIdL1) {
      // Link for redirect to level 4 cards
      return `/admin/card/${cardIdL1}/${cardId}/${card.id}`
    }

    if (!cardIdL1 && cardId) {
      // Link for redirect to level 3 cards
      return `/admin/card/${cardId}/${card.id}`
    }

    if (!cardId) {
      // Link for redirect to level 2 cards.
      return `/admin/card/${card.id}`
    }

    return '/admin/card'
  }

  const reorderList = (e: DragEndEvent) => {
    if (!e.over) return

    if (e.active.id !== e.over.id) {
      const oldIdx = cards.findIndex((el) => el.id === e.active.id)
      const newIdx = cards.findIndex((el) => el.id === e.over?.id)
      const _cards = arrayMove(cards, oldIdx, newIdx)
      setCardsToShow(_cards)
      setTimeout(() => {
        toggleSortCardsModal()
      }, 1500)
    }
  }

  return (
    <DndContext onDragEnd={reorderList} autoScroll={false}>
      <TableCard>
        <thead>
          <tr>
            <th className="column-icon"></th>
            <th className="column-title">Título</th>
            <th className="column-action">Ação</th>
            <th className="column-active">Ativo</th>
          </tr>
        </thead>
        <tbody>
          <SortableContext items={cards}>
            {cards.map((card) => (
              <SortableRow
                key={card.id}
                card={card}
                setCardSelected={setCardSelected}
                toggleEditModal={toggleEditModal}
                toggleDeleteModal={toggleDeleteModal}
                getUrl={getUrl}
                toggleEnableCardModal={toggleEnableCardModal}
              />
            ))}
          </SortableContext>
        </tbody>
      </TableCard>
    </DndContext>
  )
}

export default CardAdminTable
