import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { IFaq } from './FaqList'

import { ContainerAccordion } from './styles'

type Props = {
  faq: IFaq
  onOpen: (id: string | number) => void
}

const FaqItem = ({ faq, onOpen }: Props) => {
  return (
    <ContainerAccordion $isAccordionOpen={faq.isOpen}>
      <div
        className="accordion-toggle"
        onClick={() => {
          onOpen(faq.id)
        }}
      >
        <p>{faq.title}</p>
        {faq.isOpen ? <FontAwesomeIcon icon={faCaretUp} /> : <FontAwesomeIcon icon={faCaretDown} />}
      </div>
      {faq.isOpen && (
        <div className="accordion-content">
          <div className='content' dangerouslySetInnerHTML={{ __html: faq?.content }}></div>
          <div className="accordion-content-feedback">
            <p>Este artigo foi útil?</p>
            <div>
              <button onClick={() => { onOpen(faq.id) }}>Sim</button>
              <button onClick={() => { onOpen(faq.id) }}>Não</button>
            </div>
          </div>
        </div>
      )}
    </ContainerAccordion>
  )
}

export default FaqItem
