import { useNavigate } from 'react-router-dom'
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { WrapperReturnBtn } from './styles'
import { ButtonHTMLAttributes } from 'react'

interface Props extends ButtonHTMLAttributes<HTMLElement> {}

const ReturnBtn = (props: Props) => {
  const navigate = useNavigate()
  return (
    <WrapperReturnBtn onClick={() => { navigate('/') }} {...props}>
      <FontAwesomeIcon icon={faCircleChevronLeft} />
      Voltar
    </WrapperReturnBtn>
  )
}

export default ReturnBtn
