import { MoonLoader } from 'react-spinners'
import { LoadingBox } from './styles'

const ContainerLoading = () => {
  return (
    <LoadingBox>
      <MoonLoader size={80} color={'#000000'} />
      <span className="loading-text">
        Aguarde, já estamos <br /> carregando as informações
      </span>
    </LoadingBox>
  )
}

export default ContainerLoading
