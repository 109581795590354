import { SubmitHandler, useForm } from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'presentation/components'
import { useDefaultLogin, useWindowDimensions } from 'presentation/hooks'
import { Authentication, CreateSession, GetUserProfile } from 'domain/usecases'

import { Container, WrapperLogin, ErrorMessage } from './styles'
import logoGnc from '../../assets/Logo GNC para o portal.jpg'
import { Link } from 'react-router-dom'

interface IFormInput {
  username: string
  password: string
}

type Props = {
  authentication: Authentication
  session: CreateSession
  profile?: GetUserProfile
}

export function Login ({ authentication, session, profile }: Props) {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>()
  const { width } = useWindowDimensions()
  const { signIn, errorMessage, isLoading } = useDefaultLogin({ authentication, session, profile })

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput) => {
    signIn(data.username.trim().toLowerCase(), data.password)
  }

  return (
    <Container>
        <WrapperLogin>
          <img src={logoGnc} alt="logo" className="login-logo" />
          <form onSubmit={handleSubmit(onSubmit)} className="login-form">
            <div className="form-field">
              <FontAwesomeIcon icon={faUser} />
              <input
                type="text"
                placeholder="Digite seu usuário"
                {...register('username', { required: true })}
              />
              {errors.username?.type === 'required' && (
                <ErrorMessage>* Campo obrigatório</ErrorMessage>
              )}
            </div>
            <div className="form-field">
              <FontAwesomeIcon icon={faLock} />
              <input
                type="password"
                placeholder="Digite sua senha"
                {...register('password', { required: true })}
              />
              {errors.password?.type === 'required' && (
                <ErrorMessage>* Campo obrigatório</ErrorMessage>
              )}
            </div>
            <div className="login-error">{errorMessage ? <span>{errorMessage}</span> : null}</div>
            <Button
              type="submit"
              isLoading={isLoading}
              text="Entrar"
              style={{ width: '100%', height: '2.2rem', fontSize: '0.75rem' }}
            />
          </form>
        </WrapperLogin>
    </Container>
  )
}
