import { useRef, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { ModalContent } from 'presentation/components'
import InformativeModal from './InformativeModal'
import { ColorField, IconField, TextField } from 'presentation/components/Fields'
import { CardModel } from 'domain/models'
import { CreateCardProps } from 'domain/usecases'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyForm, ModalWrapper } from 'presentation/components/Modal/styles'
import { useAdmCardContext } from 'presentation/context'

interface IFormInput {
  name: string
  color: string
  icon: string
  image?: string
  isEnable: boolean
}

type Props = {
  toggleAddModal: () => void
  updateData: () => void
  cardSelected: CardModel | null
  listLength: number
}

const AddCardModal = ({ toggleAddModal, updateData, cardSelected, listLength }: Props) => {
  const methods = useForm<IFormInput>()
  const { createCard } = useAdmCardContext()
  const { handleError } = useHandleError()
  const refInfoModal = useRef<HTMLDialogElement>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isOpenInfoModal, setIsOpenInfoModal] = useState(false)

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    try {
      setIsLoading(true)
      const dto: CreateCardProps = {
        name: data.name,
        icon: data.icon,
        color: data.color,
        birthOrder: listLength,
        isEnable: true,
        parent: cardSelected ? {
          id: cardSelected?.id
        } : null,
        children: []
      }

      await createCard.post(dto)
      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleAddModal()
  }

  const toggleInfoModal = () => {
    if (refInfoModal.current) {
      if (isOpenInfoModal) {
        setIsOpenInfoModal(false)
        refInfoModal?.current.close()
        return
      }
      setIsOpenInfoModal(true)
      refInfoModal?.current.showModal()
    }
  }

  return (
    <>
      <ModalContent
        title="Novo card"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <FormProvider {...methods}>
          <ModalBodyForm>
            <TextField
              attribute={{
                id: 1,
                name: 'name',
                question: 'Nome do card',
                required: true,
                isHiddenToVip: false,
                optionsSelectElement: [],
                maxLength: 50
              }}
            />
            <IconField
              name="icon"
              isRequired={true}
              toggleInfoModal={toggleInfoModal}
              defaultValue={cardSelected?.icon as IconProp}
            />
            <ColorField name="color" isRequired={true} defaultValue={cardSelected?.color} />
          </ModalBodyForm>
        </FormProvider>
      </ModalContent>
      <ModalWrapper ref={refInfoModal}>
        {isOpenInfoModal && <InformativeModal toggleModal={toggleInfoModal} />}
      </ModalWrapper>
    </>
  )
}

export default AddCardModal
