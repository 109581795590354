import { RemoteCustomerGetTicketList, RemoteCustomerGetTicketListWithoutPagination } from 'data/usecases'
import { GetTicketList, CustomerGetTicketList, CustomerGetTicketListWithoutPagination } from 'domain/usecases'
import { CustomerGetTicketListAdapter, CustomerGetTicketListAdapterWithoutPagination } from 'main/adapters'
import { makeAxiosHttpClient, makeConnectorApiUrl } from 'main/factories/http'

export const makeRemoteCustomerGetTicketList = (): CustomerGetTicketList =>
  new RemoteCustomerGetTicketList(makeAxiosHttpClient(), makeConnectorApiUrl('/ticket/findMany'))

export const makeGetTicketList = (): GetTicketList =>
  new CustomerGetTicketListAdapter(makeRemoteCustomerGetTicketList())

// DELETE
export const makeRemoteCustomerGetTicketListWithoutPagination = (): CustomerGetTicketListWithoutPagination =>
  new RemoteCustomerGetTicketListWithoutPagination(makeAxiosHttpClient(), makeConnectorApiUrl('/ticket/findMany'))

export const makeGetTicketListWithoutPagination = (): GetTicketList =>
  new CustomerGetTicketListAdapterWithoutPagination(makeRemoteCustomerGetTicketListWithoutPagination())
