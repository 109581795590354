import styled from 'styled-components'

export const NotificationBox = styled.div`
  width: 36rem;
  height: 22rem;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.gray._800};
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.gray._300};
  background: ${({ theme }) => theme.colors.white};
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .icon {
    svg {
      width: 60px;
      height: 50px;
    }
  }
  .message {
    display: flex !important;
    p {
      margin-top: 2rem;
      font-size: 1.5rem;
    }
  }
`
