import { useMemo, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { ModalContent } from 'presentation/components'
import { EditorContentField, ImageFileField } from 'presentation/components/Fields'
import { reactQuillContainerParams, validations } from './utils'
import { INews } from 'domain/models'
import { useAdmNewsContext } from 'presentation/context'

import { ModalBodyForm } from 'presentation/components/Modal/styles'
import { useHandleError } from 'presentation/hooks'

interface IFormInput {
  image: File | string
  content: string
}

type Props = {
  toggleModal: () => void
  newsSelected: INews
  updateData: () => void
}

const EditNewsModal = ({ toggleModal, newsSelected, updateData }: Props) => {
  const defaultValues = {
    content: newsSelected?.description,
    image: newsSelected?.imageUrl
  }
  const methods = useForm<IFormInput>({ defaultValues })
  const { saveFile, deleteFile, editNews } = useAdmNewsContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)

  const modules = useMemo(
    () => ({
      toolbar: {
        container: reactQuillContainerParams
      }
    }),
    []
  )

  const checkDataChanges = (data: IFormInput): boolean => {
    const isEqualImage = data.image === newsSelected.imageUrl
    const isEqualContent =
      JSON.stringify(data.content) === JSON.stringify(newsSelected.description)

    return !(isEqualImage && isEqualContent)
  }

  const onSubmit: SubmitHandler<IFormInput> = async (data: IFormInput): Promise<void> => {
    const hasChanges = checkDataChanges(data)
    if (!hasChanges) {
      toast.warn('Sem mudanças a ser salvas!')
      toggleModal()
      return
    }
    if (!data.image) {
      methods.setError('image', { type: 'error', message: '* Campo obrigatório' })
      return
    }
    // Empty description
    if (data.content === '<p><br></p>') {
      methods.setError('content', { type: 'error', message: '* Campo obrigatório' })
      return
    }

    let urlImage: string = newsSelected?.imageUrl
    try {
      setIsLoading(true)
      if (data.image instanceof File) {
        const fileResponse = await saveFile.post(data.image)
        urlImage = fileResponse[0].name
      }
      const dto: INews = {
        ...newsSelected,
        description: data.content,
        imageUrl: urlImage,
        id: newsSelected.id,
        weight: newsSelected.weight
      }
      await editNews.put(dto)

      if (urlImage !== newsSelected.imageUrl) {
        await deleteFile.delete(newsSelected.imageUrl)
      }
      updateData()
    } catch (error) {
      await deleteFile.delete(urlImage)
      handleError(error)
    } finally {
      setIsLoading(false)
      handleCloseModal()
    }
  }

  const handleCloseModal = () => {
    methods.clearErrors()
    methods.reset()
    toggleModal()
  }

  return (
    <FormProvider {...methods}>
      <ModalContent
        title="Editar destaque"
        handleCloseModal={handleCloseModal}
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
      >
        <ModalBodyForm>
          <div className="news-image-adm">
            <ImageFileField
              attribute={{
                id: 1,
                name: 'image',
                question: '',
                optionsSelectElement: [],
                required: true,
                isHiddenToVip: false
              }}
              imageUrlData={newsSelected.imageUrlData}
            />
          </div>
          <EditorContentField
            modulesEditor={modules}
            validations={validations}
            fieldName="content"
            label="Edite o texto"
          />
        </ModalBodyForm>
      </ModalContent>
    </FormProvider>
  )
}

export default EditNewsModal
