/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  ContainerLoading,
  FormGeneric,
  Notification,
  Layout,
  FaqModal,
  ReturnBtn
} from '../../components'
import { useGetForm } from '../../hooks'
import { getCurrentAccountAdapter } from 'main/adapters'
import { useTicketFormContext } from 'presentation/context'

import { ContainerTitle } from 'presentation/components/TicketList/styles'
import { ContainerForm } from './styles'

export const TicketForm = () => {
  const { formId } = useParams()
  const { getForm } = useTicketFormContext()
  const { isLoadingForm, form, getFormData } = useGetForm({ getForm })

  const refModal = useRef<HTMLDialogElement>(null)
  const [isModalOpen, setIsOpenModal] = useState(true)
  const [title, setTitle] = useState('')
  const showForm = !isLoadingForm && !isModalOpen
  const showModal = !isLoadingForm && isModalOpen && form?.articles && form.articles.length > 0
  const user = getCurrentAccountAdapter()
  const firstName: string = user?.name?.split(' ')[0] ?? ''

  const toggleModal = () => {
    if (refModal.current) {
      if (isModalOpen) {
        setIsOpenModal(false)
        setTitle(form?.parentName as string)
        refModal?.current.close()
        return
      }
      setIsOpenModal(true)
      refModal?.current.showModal()
    }
  }

  useEffect(() => {
    if (form?.articles.length === 0 && !isLoadingForm) {
      setIsOpenModal(false)
      setTitle(form?.parentName)
      return
    }
    if (showModal && refModal.current) {
      setTitle('Artigos úteis')
      refModal?.current.showModal()
    }
  }, [isLoadingForm, form])

  useEffect(() => {
    if (formId) {
      getFormData(formId)
    }
  }, [formId])

  return (
    <Layout title={title}>
      <ContainerForm>
        <div className="wrapper-btn-form-name">
          <ReturnBtn className="btn-back" />
          {showForm &&
            form?.isEnable &&
            form.ancestorsCardTreeName?.split('>').map((el) => (
              <span key={el} className="form-name">
                {el.trim()} /
              </span>
            ))}
        </div>

        {isLoadingForm && (
          <div className="loading">
            <ContainerLoading />
          </div>
        )}
        {!isLoadingForm && !form && (
          <div className="message">
            <Notification message="No momento não existe nenhum formulário associado a este card." />
          </div>
        )}
        {showForm && form?.isEnable && (
          <div className="wrapper-form-info">
            <ContainerTitle className="container-title">{`Você quer abrir um chamado, ${firstName}?`}</ContainerTitle>
            {/* <div className="info_form">
              <div className="info_form-title">
                <span>Dicas </span>
                <span className="color">importantes!</span>
              </div>
              <p>
                Preencha os campos abaixo para registrar um chamado na Central de Solicitações de
                TI. Acompanhe seus chamados através da aba de notificações, e-mail ou no menu
                lateral.
              </p>
            </div> */}
          </div>
        )}
        {showForm && form && form?.isEnable && <FormGeneric form={form} />}
        {showForm && form && !form?.isEnable && (
          <div className="message">
            <Notification message="No momento não existe nenhum formulário associado a este card." />
          </div>
        )}
        {showModal && (
          <FaqModal
            refModal={refModal}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            faqList={form?.articles}
            title={form?.parentCard}
          />
        )}
      </ContainerForm>
    </Layout>
  )
}
