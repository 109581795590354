import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { ModalContent } from 'presentation/components'
import { FormModel } from 'domain/models'
import { useAdmFormContext } from 'presentation/context'
import { useHandleError } from 'presentation/hooks'

import { ModalBodyDelete } from 'presentation/components/Modal/styles'
import { ErrorMessage } from 'presentation/components/CardAdmin/styles'

type Props = {
  toggleModal: () => void
  updateData: () => void
  formSelected: FormModel
}

const DeleteFormModal = ({ toggleModal, formSelected, updateData }: Props) => {
  const { deleteForm } = useAdmFormContext()
  const { handleError } = useHandleError()
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const handleDeleteAdmin = async (): Promise<void> => {
    if (!isConfirm) {
      setErrorMessage('* Campo obrigatório')
      return
    }
    try {
      setIsLoading(true)
      await deleteForm.delete(formSelected?.id.toString())

      updateData()
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoading(false)
      toggleModal()
    }
  }

  return (
    <ModalContent
      title="Remover formulário"
      handleCloseModal={toggleModal}
      handleSubmit={handleDeleteAdmin}
      isLoading={isLoading}
      btnText="Confirmar"
      btnColor="#000000"
    >
      <ModalBodyDelete>
        <div className="question">
          <span className="icon">
            <FontAwesomeIcon icon={faTriangleExclamation} />
          </span>
          <p>Deseja remover este formulário?</p>
        </div>
        <p className="admName">{formSelected?.title}</p>
        <span className="warning-msg">
          <p>Ao excluir este formulário você não poderá recuperá-lo!</p>
        </span>
        <div className="confirm-action">
          <span className="confirm-msg">
            <input
              type="checkbox"
              onChange={() => {
                setIsConfirm(!isConfirm)
                setErrorMessage('')
              }}
            />
            <p>Confirmo que li e estou ciente da remoção do formulário.</p>
          </span>
          {errorMessage && <ErrorMessage $fontSize={'0.75rem'}>{errorMessage}</ErrorMessage>}
        </div>
      </ModalBodyDelete>
    </ModalContent>
  )
}

export default DeleteFormModal
