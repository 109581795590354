import styled from 'styled-components'

export const ContainerButton = styled.button<{ $color: string | null }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 10rem;
  padding: 0.5rem 1rem;
  height: 2.3rem;
  background-color: ${(props) => props.$color ?? props.theme.colors.black};
  border-radius: 0.5rem;
  border: none;
  font-size: 0.875rem;
  font-weight: bold;
  line-height: 1.5;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.white};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export const WrapperReturnBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 7rem;
  min-width: 6.875rem;
  max-width: 7.25rem;
  height: auto;
  padding: 0.35rem 0.875rem;
  border: none;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.black};
  gap: 0.65rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: 0.2s;
  cursor: pointer;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.gray._900};

  svg {
    width: 1.15rem;
    height: 1.15rem;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }

  &:disabled:hover {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.gray._300};
  }
`

export const BtnAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem 1rem;
  border: none;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-weight: bold;
  background-color: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
  gap: 0.5rem;
  cursor: pointer;
  transition-duration: 0.2s;

  &:hover {
    opacity: 0.9;
  }
  &:disabled {
    cursor: not-allowed;
  }

  p:first-child {
    padding: 0rem 0.15rem;
    border: 1px solid ${({ theme }) => theme.colors.white};
    border-radius: 0.175rem;
  }
`

export const BtnBell = styled.button`
  border: none;
  border-radius: 50%;
  padding: 0.25rem 0.35rem;
  background-color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  position: relative;

  span {
    position: absolute;
    margin-top: -0.25rem;
    margin-left: 0.75rem;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.yellow._100};
  }

  svg {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.red._400};
  }

  &:hover {
    svg {
      font-size: 1.25rem;
    }
  }
`
