export const statusOptions = [
  { label: 'Todos', value: '' },
  { label: 'Solucionado', value: 'Solucionado' },
  { label: 'Fechado', value: 'Fechado' },
  { label: 'Novo', value: 'Novo' },
  { label: 'Pendente', value: 'Pendente' },
  { label: 'Em atendimento', value: 'Processando Atribuído' }
]

export const styles = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxSizing: 'border-box',
    height: 'fit-content',
    minHeight: '2.3rem',
    width: '12rem',
    minWidth: 'fit-content',
    padding: '0 0.5rem',
    border: state.isFocused ? '1px solid #707070' : '1px solid #d2d2d2',
    boxShadow: state.isFocused ? 0 : 0,
    borderRadius: '0px',
    backgroundColor: state.isFocused ? '#f1f1f1' : '#fff',
    color: '#707070',
    fontSize: '0.875rem',
    borderColor: state.isSelected && 'red'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    minHeight: '2.3rem',
    fontWeight: 'bold'
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontWeight: 'bold',
    color: '#000000',
    fontSize: '0.75rem'
  }),

  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: 0
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: '0',
    minHeight: '2.3rem'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0'
  }),

  menu: (provided: any) => ({
    ...provided,
    color: '#707070',
    width: '12rem',
    minWidth: 'fit-content',
    zIndex: 100
  })
}
