import { AttributeModel } from 'domain/models'

export const getExtraQuestions = (
  attributes: AttributeModel[],
  previewQuestions: AttributeModel[] = []
) => {
  const questions = previewQuestions
  attributes.forEach((att: AttributeModel) => {
    if (att.optionsSelectElement?.length > 0) {
      att.optionsSelectElement.forEach((option) => {
        if (option.attributes.length > 0) {
          const sortAttributeOptions = option.attributes.sort(
            (a, b) => (a.positionOrder as number) - (b.positionOrder as number)
          )

          const result = createQuestionsArray(sortAttributeOptions, questions)

          questions.push(...result)

          getExtraQuestions(option.attributes, questions)
        }
      })
    }
  })

  return questions
}

const createQuestionsArray = (newList: AttributeModel[], list: AttributeModel[]) => {
  const questions = [] as AttributeModel[]

  newList.forEach((question) => {
    if (!list.find((el) => el.id === question.id)) {
      questions.push(question)
    }
  })

  return questions
}
