import styled from 'styled-components'

export const ContainerHome = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .wrapper-info{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin-left: 1rem;
    margin-bottom: 0.25rem;
    color: ${({ theme }) => theme.colors.black};
    h1 {
      font-size: 2.3rem;
    }

    p {
      font-size: 1.15rem;
    }

    @media (max-width: 768px) {
      h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.15rem;
    }
        }
  }
`
