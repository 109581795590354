import styled from 'styled-components'

export const ModalWrapper = styled.dialog<{ $minHeight?: string, $width?: string }>`
  padding: 1.75rem 2.75rem;
  height: fit-content;
  width: ${(props) => props.$width ?? '50%'};
  min-height: ${(props) => props.$minHeight};

  @media (max-width: 640px) {
    width: 80%;
  }
`

export const ModalHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray._200};
  margin-bottom: 1rem;
  span {
    font-size: 1.35rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray._700};
  }

  button {
    padding: 0.35rem 0.65rem;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition-duration: 0.2s;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.red._450};
    &:hover {
      font-weight: bold;
      opacity: 0.9;
    }
  }
`

export const ModalBody = styled.div<{ $minHeight?: string, $margin?: string }>`
  margin: ${(props) => props.$margin ?? '2rem 0 3rem 0'};
  width: 100%;
  min-height: ${(props) => props.$minHeight ?? 'fit-content'};

  .notification-message {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.colors.gray._400};

    div {
      border: none;
      box-shadow: none;
    }
  }
`

export const ModalBodyForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-top: 2rem;
  .modal-body-input {
    margin-top: 1rem;
    width: 100%;
  }

  label {
    font-size: 0.875rem;
    color: ${({ theme }) => theme.colors.gray._700};
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  input {
    width: 100%;
    /* margin-top: 0.5rem; */
  }

  h4 {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray._700};
  }

  .news-image-adm {
    .form-file-img {
      display: flex;
      align-self: center;
      height: 100%;
      max-height: 10rem;
      width: 40%;
    }
  }
`

export const ModalBodyDelete = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;

  .question {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      font-weight: bold;
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.gray._700};
    }

    .icon {
      padding: 0.65rem;
      background-color: ${({ theme }) => theme.colors.red._100};
      border-radius: 50%;

      svg {
        width: 1.25rem;
        height: 1.25rem;
        color: ${({ theme }) => theme.colors.red._500};
      }
    }
  }
  .admName {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.gray._700};
  }

  .text {
    text-align: center;
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.gray._700};
  }

  .warning-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    color: ${({ theme }) => theme.colors.red._600};
    font-size: 1rem !important;

    svg {
      font-size: 1.15rem;
      color: ${({ theme }) => theme.colors.yellow._700};
      margin-bottom: 0.5rem;
    }
  }

  .confirm-action {
    margin-bottom: 2rem;
    font-size: 1rem;
    .confirm-msg {
      display: flex;
      flex-direction: row;
      color: ${({ theme }) => theme.colors.gray._700};
      input {
        margin-right: 0.75rem;
      }
    }
  }
`

export const ModalBodyConfirm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;

  .message {
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
  }

  .name {
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.gray._700};
    text-transform: uppercase;
    margin-top: 1rem;
  }
`

export const ModalBodyInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.gray._700};
  line-height: 1.5;
  gap: 1rem;
  margin-top: 2rem;

  .form-inputs {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0;
    gap: 1rem;
    color: ${({ theme }) => theme.colors.red._800};

    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: ${({ theme }) => theme.colors.blue._400};

    &:hover {
      text-decoration: underline;
    }
  }

  .form-inputs {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 2rem 0;
    gap: 1rem;
    color: ${({ theme }) => theme.colors.gray._700};

    label {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
`

export const ModalBodyWarning = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.gray._700};

  .question {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    p {
      font-weight: bold;
      font-size: 1.25rem;
      color: ${({ theme }) => theme.colors.gray._700};
    }
  }

  .icon {
    padding: 0.65rem;
    background-color: ${({ theme }) => theme.colors.orange._100};
    border-radius: 50%;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      color: ${({ theme }) => theme.colors.orange._600};
    }
  }

  .name {
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.gray._700};
    text-transform: uppercase;
  }

  .warning-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 2rem;
    color: ${({ theme }) => theme.colors.red._600};
    font-size: 0.875rem !important;

    svg {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.yellow._700};
    }
  }
  .info-form-text {
    margin-top: 0.5rem;
  }

  .info-form-question {
    margin: 1rem 0 1.75rem 0;
    font-weight: bold;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.gray._700};
  }
  .info-form-btn {
    display: flex;
    gap: 2rem;
  }
`

export const ModalFooter = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  gap: 1.5rem;
`
