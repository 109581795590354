import { useState } from 'react'
import { FormI } from 'domain/models'
import { GetForm } from 'domain/usecases'
import { useHandleError } from '.'

type Props = {
  getForm: GetForm
}

export const useGetForm = ({ getForm }: Props) => {
  const [form, setForm] = useState<FormI | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { handleError } = useHandleError()

  const getFormData = async (formId: string) => {
    try {
      setIsLoading(true)
      const response = await getForm.get(formId)

      const labelName = response.ancestorsCardTreeName ? response.ancestorsCardTreeName.split('>')[0] : response.associatedCard?.name

      setForm({
        id: response.id,
        title: response.title,
        isEnable: response.isEnable,
        category: response.categories[0] ?? null,
        attributes: response.attributes,
        color: response.associatedCard?.color,
        parentName: labelName,
        articles: response.articles ?? [],
        parentCard: response.associatedCard?.name,
        ancestorsCardTreeName: response?.ancestorsCardTreeName,
        associatedCard: response?.associatedCard
      })
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return {
    isLoadingForm: isLoading,
    form,
    getFormData
  }
}
