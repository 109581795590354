import { FormI } from 'domain/models'
import { IFormInput } from '../AddFormModal'
type Props = {
  currentAttLength?: number
  formSelected?: FormI | null
}

export const getDefaultValues = ({
  currentAttLength = 200,
  formSelected = null
}: Props): IFormInput => {
  if (formSelected) {
    return {
      title: formSelected?.title ?? '',
      categories: formSelected?.category ?? '',
      attReused: formSelected?.attributes ?? [],
      associatedCard: formSelected.associatedCard,
      attributes: []
    }
  }
  return {
    title: '',
    categories: '',
    attReused: [],
    associatedCard: '',
    attributes: [
      {
        name: `question${currentAttLength + 1}`,
        question: '',
        optionsSelectElement: [],
        required: true,
        isHiddenToVip: false,
        type: 'text',
        positionOrder: 1,
        placeholder: null,
        isMulti: null,
        columnsLabel: null,
        isVisibleToDescription: true,
        maxLength: 25000,
        fileFormats: null
      }
    ]
  }
}
