import { GetStorage, SetStorage } from 'data/protocols'

export class LocalStorageAdapter implements SetStorage, GetStorage {
  set (key: string, value: string): void {
    if (value) {
      localStorage.setItem(key, value)
    } else {
      localStorage.removeItem(key)
    }
  }

  get (key: string): string {
    return localStorage.getItem(key) as string
  }
}
