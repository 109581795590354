import { useState } from 'react'
import { FaqModel } from 'domain/models'
import { GetFaqList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getFaqList: GetFaqList
}

export const useGetFaqList = ({ getFaqList }: Props) => {
  const [faqList, setFaqList] = useState<FaqModel[]>([])
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 0
  })
  const [isLoading, setIsLoading] = useState(false)
  const { handleError } = useHandleError()

  const getFaqData = async (page: number = 0) => {
    try {
      setIsLoading(true)
      const response = await getFaqList.get({ page, limit: 5, order: 'DESC' })
      setFaqList(response.data)
      setPagination({
        page,
        next: response.paginate.next,
        prev: response.paginate.prev
      })
    } catch (error: any) {
      handleError(error)
    } finally {
      setIsLoading(false)
    }
  }

  return { getFaqData, faqList, isLoading, pagination }
}
