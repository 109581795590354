import { useState } from 'react'
import { FiltersTicketType, IPartialTicket } from 'domain/models'
import { getCurrentAccountAdapter } from 'main/adapters'
import { GetTicketList } from 'domain/usecases'
import { InvalidCredentialsError } from 'domain/errors'
import { useHandleError } from './useHandleError'

type Props = {
  getTicketList: GetTicketList
}
export type GetDataProps = {
  limit?: number
  status?: string
  page: number
  filter?: Record<FiltersTicketType, string> | null
}
export const useGetTickets = ({ getTicketList }: Props) => {
  const [ticketList, setTicketList] = useState<[] | IPartialTicket[]>([])
  const [isLoadingTickets, setLoadingTickets] = useState(false)
  const [pagination, setPagination] = useState({
    next: true,
    prev: false,
    page: 1
  })
  const { handleError } = useHandleError()

  const getData = async ({ limit = 12, page, filter = null }: GetDataProps) => {
    try {
      setLoadingTickets(true)
      if (!getCurrentAccountAdapter()) {
        throw new InvalidCredentialsError(
          'Ops 😥, houve um erro com os dados do usuário, por favor fazer login novamente'
        )
      }

      const userLogin = getCurrentAccountAdapter().login

      const response = await getTicketList.get({
        userLogin,
        filter: {
          status: filter?.status ?? ''
        },
        limit,
        page
      })
      setTicketList(response.tickets)
      setPagination({
        page,
        next: response.pagination.next,
        prev: response.pagination.prev
      })
    } catch (error) {
      handleError(error)
    } finally {
      setLoadingTickets(false)
    }
  }

  return {
    ticketList,
    isLoadingTickets,
    getData,
    pagination
  }
}
