import { HttpClient, HttpStatusCode } from 'data/protocols'
import { InvalidParamsError, UnexpectedError } from 'domain/errors'
import { FileManagerModel } from 'domain/models'
import { SaveFile } from 'domain/usecases/file-manager'

export class RemoteSaveFile implements SaveFile {
  constructor (
    private readonly httpClient: HttpClient<FileManagerModel[]>,
    private readonly url: string
  ) {}

  async post (file: File): Promise<FileManagerModel[]> {
    const formData = new FormData()
    formData.append('files', file)

    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'POST',
      headers: {
        Authorization: process.env.REACT_APP_TOKEN_FILE_MANAGER,
        'Content-Type': 'multipart/form-data'
      },
      body: formData
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body && httpResponse.body?.length > 0) {
          return httpResponse.body
        } else {
          throw new UnexpectedError(
            'Ops 😥, ocorreu um erro ao salvar os arquivos.'
          )
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError('Formato de arquivo inválido no File manager')
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao salvar os arquivos.')
    }
  }
}
