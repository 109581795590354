import { TicketDetailsContextProvider } from 'presentation/context'
import { TicketDetails } from 'presentation/pages'

const makeTicketDetails = () => {
  return (
    <TicketDetailsContextProvider>
      <TicketDetails />
    </TicketDetailsContextProvider>
  )
}

export default makeTicketDetails
