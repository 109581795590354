import { HttpClient, HttpStatusCode } from 'data/protocols'
import { BadRequestError, InvalidCredentialsError, UnexpectedError } from 'domain/errors'
import { CardModel } from 'domain/models'
import { CreateCard, CreateCardProps } from 'domain/usecases'

export class RemoteCreateCard implements CreateCard {
  constructor (
    private readonly httpClient: HttpClient<CardModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async post (dto: CreateCardProps): Promise<CardModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      headers: { Authorization: this.token },
      method: 'post',
      body: dto
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao adicionar um novo card')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao adicionar um novo card')
    }
  }
}
