import styled from 'styled-components'

export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .loading,
  .message {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wrapper-btn-form-name {
    display: flex;
    align-items: center;

    .btn-back {
      margin-right: 2rem;
    }

    .form-name {
      margin-right: 0.5rem;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
  .wrapper-form-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: fit-content;
    gap: 1rem;

    .container-title {
      align-self: flex-start;
      margin-top: 1rem;
      font-size: 1.75rem;
      color: ${({ theme }) => theme.colors.black};
    }

    /* .info_form {
      width: 60%;
      height: fit-content;
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem;
      background-color: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.gray._400};
      font-size: 1rem;
      border: none;
      border-radius: 1rem;
      box-shadow: 0px 1px 4px ${({ theme }) => theme.colors.gray._800};
      gap: 1rem;
      text-align: justify;

      @media (max-width: 768px) {
        width: 100%;
      } */

      /* .info_form-title {
        display: flex;
        font-size: 1.75rem;
        font-weight: bold;
        color: ${({ theme }) => theme.colors.blue._800};
        gap: 0.5rem;

        .color {
          color: ${({ theme }) => theme.colors.blue._200} !important;
        }
      } */
    }
`
