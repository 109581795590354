import { useMemo, useState } from 'react'
import FaqItem from './FaqItem'
import { FaqModel } from 'domain/models'

import { ContainerFaq } from './styles'

type Props = {
  faqList: FaqModel[]
}

export interface IFaq extends FaqModel {
  isOpen: boolean
}

const FaqList = ({ faqList }: Props) => {
  const { list } = useMemo(() => {
    const list: IFaq[] = []
    faqList.map((faq) => list.push({ ...faq, isOpen: false }))
    return { list }
  }, [faqList])

  const [newList, setNewList] = useState<IFaq[]>(list)

  const onOpen = (id: string | number) => {
    const list: IFaq[] = []
    newList.forEach((faq) => {
      if (faq.id === id) {
        list.push({ ...faq, isOpen: !faq.isOpen })
      } else {
        list.push({ ...faq, isOpen: false })
      }
    })
    setNewList(list)
  }

  return (
    <ContainerFaq>
      {newList?.map((faq) => (
        <FaqItem
          key={faq.id}
          faq={faq}
          onOpen={onOpen}
        />
      ))}
    </ContainerFaq>
  )
}

export default FaqList
