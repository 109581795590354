import { IUserAccount } from 'domain/models'
import { makeLocalStorageAdapter } from 'main/factories/cache'

export const setCurrentAccountAdapter = (account: IUserAccount): void => {
  makeLocalStorageAdapter().set('user', JSON.stringify(account))
}

export const getCurrentAccountAdapter = (): IUserAccount => {
  return JSON.parse(makeLocalStorageAdapter().get('user'))
}
