import { AdmFormContextProvider } from 'presentation/context'
import { FormsAdmin } from 'presentation/pages'

const makeAdminForm = () => {
  return (
    <AdmFormContextProvider>
      <FormsAdmin/>
    </AdmFormContextProvider>
  )
}

export default makeAdminForm
