import { Routes, Route, BrowserRouter as Router } from 'react-router-dom'

import PrivateRoutes from 'main/routes/PrivateRoutes'
import { MakeLogin } from 'main/pages'

export const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<PrivateRoutes />} />
        <Route path="/login" element={<MakeLogin />} />
      </Routes>
    </Router>
  )
}
