import { HttpClient, HttpStatusCode } from 'data/protocols'
import { InvalidParamsError, UnexpectedError } from 'domain/errors'
import { DeletePrivateFile, DeletePrivateFileProps } from 'domain/usecases'

export class RemoteDeletePrivateFile implements DeletePrivateFile {
  constructor (
    private readonly httpClient: HttpClient<DeletePrivateFileProps[]>,
    private readonly url: string
  ) {}

  async delete (data: DeletePrivateFileProps[]): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'delete',
      headers: {
        Authorization: process.env.REACT_APP_TOKEN_RECIVED_FILE_MANAGER
      },
      body: data
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        return
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao remover os arquivos no File manager')
    }
  }
}
