import styled from 'styled-components'
import { SizeBreakpoint, breakQueriesMaxMin } from '../../styles/Breakpoints'
import { cardHeight } from 'presentation/components/Card/styles'
import { paddingX } from 'presentation/styles/commonStyles'

export const btnSize = {
  lg: '2.5rem',
  md: '2.25rem'
}

//* ****************************** */
//  Styles> CARD Carousel component
//* ****************************** */
export const ContainerCarousel = styled.div<{ $isOneRow: boolean, $btnLeftActive: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;

  height: ${(props) =>
    props.$isOneRow ? `calc(${cardHeight.default} + .75rem)` : `calc(${cardHeight.default} * 2 + 1.85rem)`};

  .btn-carousel {
    position: absolute;
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.gray._450};
    padding: 0.25rem;
    border: none;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};

    svg {
      width: 1.85rem;
      height: 1.85rem;
    }
  }
  .btn-carousel-left {
    margin-left: -1rem;
    background-color: ${(props) =>
      props.$btnLeftActive ? props.theme.colors.black : props.theme.colors.gray._50};
  }

  .btn-carousel-right {
    right: 0;
    margin-right: -1rem;
    background-color: ${(props) =>
      !props.$btnLeftActive ? props.theme.colors.black : props.theme.colors.gray._50};
  }

  .carousel-box--outer {
    width: calc(100% - ${btnSize.lg} * 2);
    height: 100%;
    overflow: hidden;
    position: absolute;
    margin: 0 ${btnSize.lg};
    margin-top: 5px;
    /* overflow-x: scroll; */
  }

  .carousel-box--inner {
    display: grid;
    grid-template-rows: ${(props) =>
      props.$isOneRow ? 'repeat(1, minmax(0, 1fr))' : 'repeat(2, minmax(0, 1fr))'};
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    align-content: center;
    gap: 1.25rem;
    height: 100%;
    /* pointer-events: none; */
  }

  /* Media queries */
  ${breakQueriesMaxMin(SizeBreakpoint.sm, SizeBreakpoint.xsm)} {
    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
    }

    .carousel-box--inner {
      padding-left: 0.75rem;
      gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.md, SizeBreakpoint.sm)} {
    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
      margin: 0 ${btnSize.md};
    }

    .carousel-box--inner {
      padding-left: 0.75rem;
      gap: 1rem;
    }
  }

  ${breakQueriesMaxMin(SizeBreakpoint.lg, SizeBreakpoint.md)} {
    .carousel-box--outer {
      width: calc(100% - ${btnSize.md} * 2);
      margin: 0 ${btnSize.md};
    }

    .carousel-box--inner {
      padding-left: 1rem;
      gap: 1.15rem;
    }
  }

  @media (min-height: 1200px) and (min-width: 1366px) {
    height: ${cardHeight.xl};
    height: ${(props) =>
      props.$isOneRow ? `calc(${cardHeight.lg} + .75rem)` : `calc(${cardHeight.lg} * 2 + 1.85rem)`};
  }
`

export const WrapperIconCard = styled.div`
  display: flex;
  align-items: center;
  gap: 0.35rem;
  height: 2.3rem;
  min-height: 2.3rem;

  svg {
    width: 0.875rem !important;
    height: 0.875rem !important;
  }
`
export const MessageEmptyCard = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 2rem;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    color: ${({ theme }) => theme.colors.black};
  }
`

export const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    boxSizing: 'border-box',
    height: 'fit-content',
    minHeight: '3rem',
    width: '100%',
    padding: '0 0.5rem',
    border: state.isFocused ? '1px solid #707070' : '1px solid #d2d2d2',
    boxShadow: state.isFocused ? 0 : 0,
    borderRadius: '0.25rem',
    backgroundColor: state.isFocused ? '#f1f1f1' : '#fff',
    color: '#707070',
    borderColor: state.isSelected && 'red'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: '0px',
    minHeight: '3rem'
  }),
  input: (provided: any) => ({
    ...provided,
    margin: '0px',
    padding: 0
  }),
  indicatorsContainer: (provided: any) => ({
    ...provided,
    padding: '0',
    minHeight: '3rem'
  }),

  indicatorSeparator: () => ({
    display: 'none'
  }),

  dropdownIndicator: (provided: any) => ({
    ...provided,
    padding: '0'
  }),

  menu: (provided: any) => ({
    ...provided,
    color: '#707070',
    width: '100%',
    maxHeight: '14rem'
  }),
  menuList: (provided: any) => ({
    ...provided,
    maxHeight: '14rem'
  }),
  option: (provided: any) => ({
    ...provided,
    border: '1px solid #e2e8f0',
    height: '100%'
  })
}

//* ****************************** */
//  Styles> NEWS component
//* ****************************** */
export const ContainerNews = styled.div`
  position: relative;
  align-self: center;
  width: 80%;
  max-width: 80rem;
  min-height: 8rem;
  max-height: 10rem;
  background: ${({ theme }) => theme.colors.white};
  border: none;
  border-radius: 1rem;
  padding-left: ${paddingX};
  padding-right: ${paddingX};
  box-shadow: 0px 1px 4px ${({ theme }) => theme.colors.gray._800};

  ${breakQueriesMaxMin(1550, 1180)}{
    max-width: 60rem;
  }
  ${breakQueriesMaxMin(1180, 1050)}{
    max-width: 50rem;
  }

  ${breakQueriesMaxMin(1050, 900)}{
    max-width: 40rem;
    width: 100%;
    padding: 0 1rem;
  }

  ${breakQueriesMaxMin(900, 768)}{
    max-width: 35rem;
    width: 100%;
    padding: 0 1rem;
  }

  ${breakQueriesMaxMin(768, 500)}{
    max-width: 25rem;
    width: 100%;
    padding: 0 1rem;
    height: fit-content !important;
    min-height: 10rem;
    max-height: 12rem;
  }


  .btn-close {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 0.25rem 0.5rem;
    border-radius: 50%;
    border: none;
    color: ${({ theme }) => theme.colors.black};
    font-size: 1rem;

    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 1px 1px ${({ theme }) => theme.colors.gray._800};
    cursor: pointer;

    &:hover {
      font-size: 1.25rem;
    }
  }

  .wrapper-news-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;

    .title {
      position: absolute;
      margin-top: 1rem;
      margin-bottom: 1rem;
      color: ${({ theme }) => theme.colors.black};
      span {
        color: ${({ theme }) => theme.colors.red._650};
      }
    }
  }
  // Class from nuka-carousel lib
  .slider-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100% !important;

    .slide {
      display: flex;
      justify-content: center;
    }

    .carousel-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100% !important;

      .news-item__img {
        width: 40%;
        height: 100%;
        max-height: 10rem;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: justify;
        width: 60%;
        font-size: 1rem;
        margin-top: 1rem;
        margin-right: 1rem;
        p {
          text-align: justify !important;
        }
        @media (max-width: 900px) {
          font-size: 0.75rem;
        }

        @media (max-width: 768px) {
          width: 100% !important;
        }
      }
    }

    .slider-control-bottomcenter {
      margin-top: auto !important;
      ul {
        top: -2px !important;
      }
      .paging-item {
        margin-left: 0.875rem;
        .paging-dot {
          width: 0.5rem;
          height: 0.5rem;
          opacity: 1;
          border: 1px solid ${({ theme }) => theme.colors.gray._400};
          border-radius: 1.25rem;
          fill: none;
          background-color: ${({ theme }) => theme.colors.gray._300};
        }
      }
    }
  }

  // Class from nuka-carousel lib - end
`
