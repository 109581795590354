import { HttpClient, HttpStatusCode } from 'data/protocols'
import { InvalidParamsError, UnexpectedError } from 'domain/errors'
import { DeletePublicFile } from 'domain/usecases'

export class RemoteDeletePublicFile implements DeletePublicFile {
  constructor (private readonly httpClient: HttpClient<string>) {}

  async delete (url: string): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url,
      method: 'delete',
      headers: {
        Authorization: process.env.REACT_APP_TOKEN_FILE_MANAGER
      }
    })

    switch (httpResponse?.statusCode) {
      case HttpStatusCode.ok:
        return
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao remover os arquivos no File manager')
    }
  }
}
