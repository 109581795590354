import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

type Props = {
  defaultValue: string
  setValue: (value: string) => void
  handleSearch: () => void
}

export const Search = ({ defaultValue, setValue, handleSearch }: Props) => {
  return (
    <div className="search_ticket">
      <input
        type="search"
        name="searchTicket"
        defaultValue={defaultValue ?? ''}
        placeholder="POR NÚMERO DO CHAMADO"
        onChange={(e) => {
          setValue(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
      />
      <button
        type="button"
        title="Procurar"
        onClick={() => {
          handleSearch()
        }}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </button>
    </div>
  )
}
