import styled, { css, keyframes } from 'styled-components'
// import SearchIcon from '../icons/search'
// import ArrowRightIcon from '../icons/arrowRight'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const IconCommonCss = css`
  width: 1.35rem;
  height: 1.35rem;
  color: ${({ theme }) => theme.colors.blue._800};
  z-index: 10;
  animation: ${fadeIn} 1s linear;
`

export const Container = styled.div`
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  border-radius: 2.5rem;
  padding: 5px;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.red._400};
  transition: all 0.5s;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .search-icon {
    ${IconCommonCss};
    border: 1px solid ${({ theme }) => theme.colors.blue._800};
    padding: 0.5rem;
    border-radius: 50%;
  }

  .arrow-icon {
    ${IconCommonCss};
    align-self: flex-end;
    cursor: pointer;
    &:hover {
      fill: #393e46;
    }
  }

  &:hover {
    width: 50%;
    -webkit-box-shadow: 5px 6px 15px 5px #00000029;
    box-shadow: 5px 6px 15px 5px #00000029;

    @media (min-width: 768px) {
      width: 80%;
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 2.5rem;
    line-height: 1.5rem;
    outline: 0;
    border: 0;
    font-size: 1.5rem;
    border-radius: 1.25rem;
    padding: 0 1.25rem;
    margin: 0;
    /* -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; */
    color: ${({ theme }) => theme.colors.gray._500};
    display: block;

    &::placeholder {
      font-size: 0.875rem;
      font-style: italic;
    }
  }
`
