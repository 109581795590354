import { ReactNode, memo, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { useLogout, useWindowDimensions } from 'presentation/hooks'
import Header from './Header'
import Footer from './Footer'

import { Container, Sidebar, WrapperContent } from './styles'
import { getCurrentAccountAdapter } from 'main/adapters'

const links = [
  { href: '/', icon: 'fa-solid fa-house', label: 'Página inicial' },
  { href: '/ticket/list', icon: 'fa-solid fa-headphones', label: 'Meus Chamados' },
  { href: '/admin', icon: 'fa-solid fa-user-tie', label: 'Administrador' }
]
type Props = {
  children: ReactNode
  title: string
}

const Layout = ({ children, title }: Props) => {
  const { width } = useWindowDimensions()
  const { logout } = useLogout()

  const getLinks = () => {
    const user = getCurrentAccountAdapter()

    if (user?.role === 'ADMIN') {
      return links
    }
    return (
      links.filter((link) => link.href !== '/admin' && link.href !== '/ticket/listOld')
    )
  }

  useEffect(() => {
    document.title = title ?? ''
  }, [title])

  return (
    <Container>
      <Header />
      <WrapperContent>
        <div className="responsive_container">
          <Sidebar>
            {getLinks().map((link) => (
              <NavLink
                key={link.href}
                end
                to={link.href}
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {width <= 768 ? (
                  <div className="link-label">
                    <FontAwesomeIcon icon={link.icon as IconProp} />
                  </div>
                ) : (
                  <div className="link-label">
                    <FontAwesomeIcon icon={link.icon as IconProp} />
                    {link.label}
                  </div>
                )}
              </NavLink>
            ))}
            <NavLink
              to="/login"
              className="link-bottom"
              onClick={() => {
                logout()
              }}
            >
              {width <= 768 ? (
                <div className="link-label">
                  <FontAwesomeIcon icon={'fa-solid fa-arrow-right-from-bracket' as IconProp} />
                </div>
              ) : (
                <div className="link-label">
                  <FontAwesomeIcon icon={'fa-solid fa-arrow-right-from-bracket' as IconProp} />
                  Sair
                </div>
              )}
            </NavLink>
          </Sidebar>
          <div className="wrapper-content">{children}</div>
        </div>
      </WrapperContent>
      <Footer />
    </Container>
  )
}

export default memo(Layout)
