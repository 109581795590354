import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from 'presentation/components'
import { CardModel } from 'domain/models'

import {
  ModalBody,
  ModalBodyForm,
  ModalFooter,
  ModalHeader
} from 'presentation/components/Modal/styles'
import { MessageEmptyCard } from './styles'
import { RenderCardComponent } from './utils/renderCardComponent'

export interface OptionsCards extends CardModel {
  hierarchy: number
  attName: string
}

type Props = {
  toggleModal: () => void
  cardSelected: CardModel
}

const SubCardsModal = ({ toggleModal, cardSelected }: Props) => {
  const methods = useForm()
  const navigate = useNavigate()
  const [optionsSelected, setOptionsSelected] = useState<OptionsCards[]>([
    {
      ...cardSelected,
      hierarchy: 1,
      attName: 'cardHierarchyLevel1'
    }
  ])
  const [msgEmpty, setMsgEmpty] = useState<string | null>(null)
  const [isShowBtn, setIsShowBtn] = useState(false)
  const [formId, setFormId] = useState<string | number | null>(null)

  const unregisterValuesByHierarchy = (hierarchy: number) => {
    const optionsToUnregister = [] as string[]
    for (const [key, value] of Object.entries(methods.getValues())) {
      if (value?.hierarchy > hierarchy) {
        optionsToUnregister.push(key)
      }
    }

    optionsToUnregister.forEach((opt) => {
      methods.unregister(opt)
    })
  }

  const cleanData = () => {
    setMsgEmpty(null)
    setIsShowBtn(false)
    setFormId(null)
  }

  const handleOptionSelected = (card: OptionsCards) => {
    cleanData()
    let newOptionsSelected: OptionsCards[] = [] as OptionsCards[]

    if (optionsSelected.length === 1) {
      newOptionsSelected = [...optionsSelected]
    }

    if (optionsSelected.length > 1) {
      // Filter card option by hierarchy
      newOptionsSelected = optionsSelected.filter((option) => option.hierarchy < card.hierarchy)
      unregisterValuesByHierarchy(card.hierarchy)
    }

    if (card?.children?.length === 0 && card?.forms?.length === 0) {
      setMsgEmpty('No momento não existe nenhum formulário ou card associado a esta página.')
      setOptionsSelected(newOptionsSelected)
      return
    }
    if (card?.forms?.length > 0) {
      setFormId(card?.forms[0]?.id)
      setIsShowBtn(true)
      setOptionsSelected(newOptionsSelected)
      return
    }
    if (card?.children?.length > 0) {
      setOptionsSelected([...newOptionsSelected, { ...card }])
    }
  }

  const onSubmit = () => {
    if (formId) {
      toggleModal()
      navigate(`/ticket/new/${formId}`, { state: { goToHome: true } })
    }
  }

  return (
    <FormProvider {...methods}>
      <ModalHeader>
        <span>{cardSelected.name}</span>
        <button onClick={toggleModal}>X</button>
      </ModalHeader>
      <ModalBody $minHeight="15rem" $margin="2rem 0 5rem 0">
        <ModalBodyForm>
          <h4>Escolha uma subseção:</h4>
          {optionsSelected &&
            optionsSelected?.length > 0 &&
            optionsSelected
              .sort((a, b) => a.hierarchy - b.hierarchy)
              .map((card) => (
                <RenderCardComponent
                  key={card.id}
                  card={card}
                  handleOptionSelected={handleOptionSelected}
                />
              ))}
          {msgEmpty && (
            <MessageEmptyCard>
              <FontAwesomeIcon icon={faCircleExclamation} />
              <p>{msgEmpty}</p>
            </MessageEmptyCard>
          )}
        </ModalBodyForm>
      </ModalBody>
      <ModalFooter>
        {!msgEmpty && isShowBtn && (
          <Button
            type="submit"
            isLoading={false}
            style={{ backgroundColor: '#000000' }}
            text="Continuar"
            onClick={methods.handleSubmit(onSubmit)}
          />
        )}
      </ModalFooter>
    </FormProvider>
  )
}

export default SubCardsModal
