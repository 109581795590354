import { useSortable } from '@dnd-kit/sortable'
import { useNavigate, useParams } from 'react-router-dom'
import { faPenToSquare, faRightLeft, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSS } from '@dnd-kit/utilities'
import { CardModel } from 'domain/models'

import { CardIcon, CardLabelColor } from 'presentation/components/Card/styles'

type Props = {
  card: CardModel
  setCardSelected: (value: CardModel) => void
  toggleEditModal: () => void
  toggleDeleteModal: () => void
  toggleEnableCardModal: () => void
  getUrl: (value: CardModel) => string
}

const SortableRow = (props: Props) => {
  const {
    card,
    setCardSelected,
    toggleEditModal,
    toggleDeleteModal,
    toggleEnableCardModal,
    getUrl
  } = props
  const { cardIdL2 } = useParams()
  const navigate = useNavigate()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: card.id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
    <tr ref={setNodeRef} style={style}>
      <td className="column-icon">
        <div className="wrapper-icon">
          <CardLabelColor $colorLabel={card.color} $height="90% !important" />
          <CardIcon $padding="0.65rem">
            <FontAwesomeIcon icon={(card.icon as IconProp) ?? 'fa-solid fa-gear'} />
          </CardIcon>
        </div>
      </td>
      <td className="column-title">
        {cardIdL2 ? (
          <span>{card.name}</span>
        ) : (
          <button
            onClick={() => {
              navigate(getUrl(card))
            }}
          >
            {card.name}
          </button>
        )}
      </td>
      <td className="column-action">
        <button
          className="action-edit"
          onClick={() => {
            setCardSelected(card)
            toggleEditModal()
          }}
          title="Editar"
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
        <button
          className="action-remove"
          onClick={() => {
            setCardSelected(card)
            toggleDeleteModal()
          }}
          title="Remover"
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
        <button className="action-move" title="Mover" {...attributes} {...listeners}>
          <FontAwesomeIcon icon={faRightLeft} rotation={90} />
        </button>
      </td>
      <td className="column-active">
        <input
          type="checkbox"
          checked={card.isEnable}
          onChange={() => {
            setCardSelected(card)
            toggleEnableCardModal()
          }}
        />
      </td>
    </tr>
  )
}

export default SortableRow
