import { AttributeModel, OptionModel } from 'domain/models'
import { IAttributeProps } from '../AdmFields/AdmFieldList'

type AttToShowProps = {
  attrsCreated: IAttributeProps[]
  attrsReused: any[]
}

export const setAttributeToShow = async ({ attrsCreated, attrsReused }: AttToShowProps) => {
  const _attrCreated = [...attrsCreated]
  const list: AttributeModel[] = [...attrsReused]

  _attrCreated.forEach((attr, index) => {
    const _options: OptionModel[] = []
    if (attr?.optionsSelectElement.length > 0) {
      attr.optionsSelectElement.forEach((opt, idx) => {
        _options.push({ ...opt, id: idx + 2000 })
      })
    }
    list.push({
      ...attr,
      id: index + 1000,
      optionsSelectElement: [..._options]
    } as AttributeModel)
  })

  return list
}
