/* eslint-disable @typescript-eslint/space-before-function-paren */
import { memo, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { useWindowDimensions } from '../../hooks'
import FollowupListModal from './FollowupListModal'
import { ITicket } from 'domain/models'
import FollowupItem from './FollowupItem'
import { getStatusColor } from 'presentation/utils'
import { useTicketDetailsContext } from 'presentation/context'

import {
  Description,
  Information,
  LabelContent,
  BtnViewMore,
  TicketStatus,
  WrapperTicketContent,
  WrapperTopContent
} from './styles'
import { WrapperSectionContent } from 'presentation/styles/commonStyles'
import { ModalWrapper } from '../Modal/styles'

type Props = {
  ticket: ITicket
}

function TicketInfo<T>({ ticket }: Props) {
  const { width } = useWindowDimensions()
  const { statusColor } = useTicketDetailsContext()

  const refFollowupModal = useRef<HTMLDialogElement>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)

  const toggleFollowupModal = () => {
    if (refFollowupModal.current) {
      if (isOpenModal) {
        setIsOpenModal(false)
        refFollowupModal?.current.close()
        return
      }
      setIsOpenModal(true)
      refFollowupModal?.current.showModal()
    }
  }

  return (
    <WrapperTicketContent>
      <WrapperTopContent>
        <div className="wrapper_ticket_info">
          <div className="category">
            <span className="category_name">Início&nbsp;</span>
            {ticket?.categories.map((category) => (
              <span key={category} className="category_name">
                ▶&nbsp;{category}&nbsp;
              </span>
            ))}
          </div>
          <LabelContent>{ticket?.title}</LabelContent>
          <Information>
            <div className="status">
              <label>Status:</label>
              <TicketStatus
                $statusColor={getStatusColor<T>(statusColor, ticket.status.toUpperCase() as T)}
              >
                {ticket.status.toUpperCase() === 'PROCESSANDO ATRIBUÍDO' ? 'Em atendimento' : ticket.status}
              </TicketStatus>
            </div>
            <div className="wrapper">
              <span className="line" />
              <div className="info">
                <label>Data de abertura:</label>
                <span>{ticket?.createdAt}</span>
              </div>
            </div>
            <div className="wrapper">
              <span className="line" />
              <div className="info">
                <label>Prazo para solução:</label>
                <span>{ticket?.dueAt}</span>
              </div>
            </div>

          </Information>
        </div>
        {width > 768 ? (
          <div className="summary_technician">
            {/* <UserPhoto urlImg={ticket.technicianPhoto} className="technician_photo" /> */}
            <div className="technician_info">
              {/* <label>Técnico</label>
              <p>{ticket.technicianName}</p> */}
              <label>Chamado</label>
              <span>N°{ticket?.id}</span>
            </div>
          </div>
        ) : (
          <div className="summary_technician">
            <div className="technician_info">
              <label>Chamado</label>
              <span>N°{ticket?.id}</span>
            </div>
          </div>
        )}
      </WrapperTopContent>
      <WrapperSectionContent>
        <LabelContent>DESCRIÇÃO DO CHAMADO</LabelContent>
        <Description>
          {ticket.description.map((description) => (
            <span key={description} className="response">
              {description} <br />
              <br />
            </span>
          ))}
        </Description>
      </WrapperSectionContent>
      <WrapperSectionContent className="bottom_info" $minHeight="fit-content">
        <LabelContent>ÚLTIMO ACOMPANHAMENTO</LabelContent>

        {ticket?.followups?.latestFollowup ? (
          <FollowupItem followup={ticket.followups?.latestFollowup} />
        ) : (
          <span className="text">Este chamado ainda não tem acompanhamento.</span>
        )}

        {ticket?.followups?.allFollowups && ticket?.followups?.allFollowups?.length > 1 && (
          <BtnViewMore>
            <button onClick={toggleFollowupModal}>
              <span>
                <FontAwesomeIcon icon={faPlus} />
              </span>
              Ver mais
            </button>
          </BtnViewMore>
        )}
      </WrapperSectionContent>
      <ModalWrapper ref={refFollowupModal} $width="70%">
        {isOpenModal && <FollowupListModal ticket={ticket} toggleModal={toggleFollowupModal} />}
      </ModalWrapper>
    </WrapperTicketContent>
  )
}

export default memo(TicketInfo)
