import { AdmNewsContextProvider } from 'presentation/context'
import { NewsAdmin } from 'presentation/pages'

const makeAdminNews = () => {
  return (
    <AdmNewsContextProvider>
      <NewsAdmin />
    </AdmNewsContextProvider>
  )
}

export default makeAdminNews
