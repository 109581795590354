import {
  RemoteCreateNewAdmin,
  RemoteDeleteAdmin,
  RemoteEditAdmin,
  RemoteGetAdminList
} from 'data/usecases'
import { CreateNewAdmin, DeleteAdmin, EditAdmin, GetAdminList } from 'domain/usecases'
import { getTokenAdapter } from 'main/adapters'
import { makeBackendApiUrl, makeAxiosHttpClient } from 'main/factories/http'

export const makeCreateNewAdmin = (): CreateNewAdmin =>
  new RemoteCreateNewAdmin(makeAxiosHttpClient(), makeBackendApiUrl('/user'), getTokenAdapter())

export const makeGetAdminList = (): GetAdminList =>
  new RemoteGetAdminList(makeAxiosHttpClient(), makeBackendApiUrl('/user'), getTokenAdapter())

export const makeEditAdmin = (): EditAdmin =>
  new RemoteEditAdmin(makeAxiosHttpClient(), makeBackendApiUrl('/user'), getTokenAdapter())

export const makeDeleteAdmin = (): DeleteAdmin =>
  new RemoteDeleteAdmin(makeAxiosHttpClient(), makeBackendApiUrl('/user'), getTokenAdapter())
