import { IStatusColor } from 'domain/models'

export const getStatusColor = <T>(list: Array<IStatusColor<T>>, status: T): string => {
  let color = '#707070'
  list.forEach((el) => {
    if (status === el.status) {
      color = el.color
    }
  })
  return color
}
