import { useEffect, useState } from 'react'
import { OptionSelect } from 'presentation/components/Select/Select'
import { AttributeModel } from 'domain/models'
import { GetAttributeList } from 'domain/usecases'
import { useHandleError } from './useHandleError'

type Props = {
  getAttributeList: GetAttributeList
}

export const useGetAttributes = ({ getAttributeList }: Props) => {
  const [isLoadingAttributes, setIsLoadingAttributes] = useState(false)
  const [attributes, setAttributes] = useState<AttributeModel[] | []>([])
  const [attributeOptions, setAttributeOptions] = useState<OptionSelect[] | []>([])
  const [attributeOptionsAll, setAttributeOptionsAll] = useState<OptionSelect[] | []>([])
  const { handleError } = useHandleError()

  const filterAttributesWithoutCategories = (list: AttributeModel[]) => {
    return list.filter((attribute) => attribute.optionsSelectElement[0]?.category === null)
  }
  const filterAttributesWithoutOptions = (list: AttributeModel[]) => {
    return list.filter((attribute) => attribute.optionsSelectElement.length === 0)
  }

  const formattedAttributesOptions = (list: AttributeModel[]) => {
    const _attributeOptions: OptionSelect[] = []
    list.forEach((att) => {
      _attributeOptions.push({
        label: att.question,
        value: { ...att }
      })
    })
    return _attributeOptions
  }

  async function getAttributeData () {
    setIsLoadingAttributes(true)
    try {
      const response = await getAttributeList.get()

      if (response.length > 0) {
        const _attributesWithoutCategory = filterAttributesWithoutCategories(response)
        const _attributesWithoutOptions = filterAttributesWithoutOptions(response)
        // GET "Esse chamado é pra vc mesmo" attribute
        const userAtt = response.filter((attribute) => attribute.type === 'user')

        const _attributeOptions = formattedAttributesOptions([
          ...userAtt,
          ..._attributesWithoutOptions,
          ..._attributesWithoutCategory
        ])

        const _attributeOptionsAll = formattedAttributesOptions([...response])

        setAttributes(response)
        setAttributeOptions(_attributeOptions)
        setAttributeOptionsAll(_attributeOptionsAll)
        return
      }
      setAttributes(response)
    } catch (error) {
      handleError(error)
    } finally {
      setIsLoadingAttributes(false)
    }
  }

  useEffect(() => {
    getAttributeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isLoadingAttributes,
    attributes,
    attributeOptions,
    attributeOptionsAll
  }
}
