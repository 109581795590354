import { OptionSelect } from 'presentation/components/Select/Select'
import { OptionModel } from 'domain/models'

export const setOptions = (list: OptionModel[]): OptionSelect[] => {
  const _optionValues: OptionSelect[] = []
  list.forEach((opt) => {
    _optionValues.push({ label: opt.value, value: opt.value })
  })
  return _optionValues
}
