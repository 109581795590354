import { HttpClient, HttpStatusCode } from 'data/protocols'
import { AccessDeniedError, InvalidParamsError, UnexpectedError } from 'domain/errors'
import { UserSessionModel } from 'domain/models'
import { CreateSession, CreateSessionDto } from 'domain/usecases'

export class RemoteCreateSession implements CreateSession {
  constructor (
    private readonly httpClient: HttpClient<UserSessionModel>,
    private readonly url: string
  ) {}

  async create (dto: CreateSessionDto): Promise<UserSessionModel> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      body: dto
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError()
        }
      case HttpStatusCode.badRequest:
        throw new InvalidParamsError()
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError()
      default:
        throw new UnexpectedError()
    }
  }
}
