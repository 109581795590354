import { HttpClient, HttpStatusCode } from 'data/protocols'
import { BadRequestError, InvalidCredentialsError, UnexpectedError } from 'domain/errors'
import { FormModel } from 'domain/models'
import { CreateFormProps, EditForm } from 'domain/usecases'

export class RemoteEditForm implements EditForm {
  constructor (
    private readonly httpClient: HttpClient<FormModel>,
    private readonly url: string,
    private readonly token: string
  ) {}

  async put (id: string | number, dto: CreateFormProps | FormModel): Promise<FormModel> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      headers: { Authorization: this.token },
      method: 'PUT',
      body: dto
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        if (httpResponse.body) {
          return httpResponse.body
        } else {
          throw new UnexpectedError('Ops 😥, ocorreu um erro ao editar o formulário')
        }
      case HttpStatusCode.badRequest:
        throw new BadRequestError()
      case HttpStatusCode.unauthorized:
        throw new InvalidCredentialsError()
      default:
        throw new UnexpectedError('Ops 😥, ocorreu um erro ao editar o formulário')
    }
  }
}
